namespace mW {

    export class sandbox {

        testData: any;
        
        init(): void {

            this.testData = {
                'Field 1': 'somedatagoes here 1',
                'Field 2': 'somedatagoes here 2',
                'Field 3': null,
                'Field 4': 'somedatagoes here 4',
                'Field 5': null
        };

            var obser = new mW.observable(this.testData);
            obser.updateWatchList([
                { Index: 1, Id: 'f1' },
                { Index: 2, Id: 'f2' }
            ]);
        }

    }

}