namespace Que.Forms.Models {

    export class ContactType {

        ContactTypeID: number;
        ContactType: string;
        Status: string;
        CreateUser: string;
        CreateTmsp: string;
        UpdateUser: string;
        UpdateTmsp: string;

        constructor(contactType?: Interfaces.IContactType) {            
            var set_prop = mW.classes.set_property_value;
            set_prop(this, 'ContantTypeID', contactType.ContactTypeID, null);            
            set_prop(this, 'ContactType', contactType.ContactType, null);
            set_prop(this, 'Status', contactType.Status, null);
            set_prop(this, 'CreateUser', contactType.CreateUser, null);
            set_prop(this, 'CreateTmsp', contactType.CreateTmsp, null);
            set_prop(this, 'UpdateUser', contactType.UpdateUser, null);
            set_prop(this, 'UpdateTmsp', contactType.UpdateTmsp, null);

        }

        get(): JQueryDeferred<Que.Forms.Models.ContactType[]> {
            var $result = $.Deferred(), contacts: Que.Forms.Models.ContactType[] = new Array<Que.Forms.Models.ContactType>();
            Que.Forms.APIs.WYSIWYG_Repo.getContactTypes().done((data) => {
                data.forEach((contactType) => {
                    contacts.push(new Que.Forms.Models.ContactType(contactType));
                    $result.resolve(contacts);
                });
            });
            return $result;
        }
    }

}