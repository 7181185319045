module mW {

    export class io {
        // {class: "io", method: "post", version: "1.0.0", status: "up for review"} 
        static post(url: string, dataObject?: any): JQueryDeferred<any> {
            var $result = $.Deferred<any>();
            var cleanedObject = JSON.stringify(dataObject);
            $.ajax({
                url: url, type: 'POST', contentType: 'application/json', dataType: 'json', data: cleanedObject
            }).done((data) => {
                $result.resolve(data);
                }).fail((xhr, status, error) => {
                    $result.resolve(xhr, status, error);
                });
            return $result;

        }
        // {class: "io", method: "reconstruct_object", version: "1.0.0", status: "up for review"} 
        // Use this if you want to rebuild a SmartGrid object, takes single flat object or array
        static reconstruct_object(object: any): any {

            var newObjectList = [], newObject = {};

            
            for (var valueIndex = 0; valueIndex < object.Values.length; valueIndex++) {

                newObject = {};

                for (var columnIndex = 0; columnIndex < object.Columns.length; columnIndex++) {                    
                    var objectValue = null

                    // here we convert these to javascript types - numbers|string|boolean
                    switch (object.Columns[columnIndex].DataType) {
                        case 5: // integer
                        case 3: // decimal
                            objectValue = +object.Values[valueIndex][columnIndex];
                            break;
                        case 2: // boolean
                            objectValue = object.Values[valueIndex][columnIndex] == 0 ? false : true;
                        default: // string and date
                            objectValue = object.Values[valueIndex][columnIndex];
                    }                    
                    // if the fieldname property exist in the columns, we use that as the literal property name in our new object, otherwise use the title (titles are allowed to have spaces in the name which typically represents the name of the property but is used as display name for the end user, thats why we check if fieldname is included because fieldname should almost never have spaces in it)
                    if (object.Columns[columnIndex].FieldName != null || object.Columns[columnIndex].FieldName != undefined) {
                        newObject[object.Columns[columnIndex].FieldName] = objectValue;
                    } else {
                        newObject[object.Columns[columnIndex].title] = objectValue;
                    }                    

                }                

                newObjectList.push(newObject);

            }

            return newObjectList;

        }

        static flatten_into_smart_grid(obj: any, smartGrid: any[]) {

            

        }

    }

}