namespace Que.Forms.Enums {

    export enum BlockType {
//        1	Label Column
//2	Full Block
//3	Word Block
//4	Beginning Word Block 
//5	New Line Block
    }

}