namespace Que.DataStructures.Models {

    export class DataStructureColumn implements Interfaces.IDataStructureColumn {
        ColumnID: number;
        DataStructureID: number;
        DataCategoryID: number;
        DataSubCategoryID: number;
        DataTypeID: number;
        DataTypeName: string;
        DefaultStringValue: string;
        DefaultIntegerValue: number;
        DefaultDateValue: string | Date;
        DefaultDecimalValue: number;
        DefaultBitValue: boolean;
        DefaultValueFormula: string;
        SortOrder: number;
        CalculationFormula: string;
        CalculationProcessOrder: number;
        FieldName: string;
        DataCategory: string;
        Description: string;
        CreateUser: string;
        CreateTmsp: string | Date;
        UpdateUser: string;
        UpdateTmsp: string | Date;

        constructor(dataStructureColumn?: Interfaces.IDataStructureColumn) {
            this.ColumnID = dataStructureColumn == undefined ? null : dataStructureColumn.ColumnID;
            this.DataStructureID = dataStructureColumn == undefined ? null : dataStructureColumn.DataStructureID;
            this.DataCategoryID = dataStructureColumn == undefined ? null : dataStructureColumn.DataCategoryID;
            this.DataSubCategoryID = dataStructureColumn == undefined ? null : dataStructureColumn.DataSubCategoryID;
            this.DataTypeID = dataStructureColumn == undefined ? null : dataStructureColumn.DataTypeID;
            this.DataTypeName = dataStructureColumn == undefined ? null : dataStructureColumn.DataTypeName;
            this.DefaultStringValue = dataStructureColumn == undefined ? null : dataStructureColumn.DefaultStringValue;
            this.DefaultIntegerValue = dataStructureColumn == undefined ? null : dataStructureColumn.DefaultIntegerValue;
            this.DefaultDateValue = dataStructureColumn == undefined ? null : dataStructureColumn.DefaultDateValue;
            this.DefaultDecimalValue = dataStructureColumn == undefined ? null : dataStructureColumn.DefaultDecimalValue;
            this.DefaultBitValue = dataStructureColumn == undefined ? null : dataStructureColumn.DefaultBitValue;
            this.DefaultValueFormula = dataStructureColumn == undefined ? null : dataStructureColumn.DefaultValueFormula;
            this.SortOrder = dataStructureColumn == undefined ? null : dataStructureColumn.SortOrder;
            this.CalculationFormula = dataStructureColumn == undefined ? null : dataStructureColumn.CalculationFormula;
            this.CalculationProcessOrder = dataStructureColumn == undefined ? null : dataStructureColumn.CalculationProcessOrder;
            this.FieldName = dataStructureColumn == undefined ? null : dataStructureColumn.FieldName;
            this.DataCategory = dataStructureColumn == undefined ? null : dataStructureColumn.DataCategory;
            this.Description = dataStructureColumn == undefined ? null : dataStructureColumn.Description;
            this.CreateUser = dataStructureColumn == undefined ? null : dataStructureColumn.CreateUser;
            this.CreateTmsp = dataStructureColumn == undefined ? null : dataStructureColumn.CreateTmsp;
            this.UpdateUser = dataStructureColumn == undefined ? null : dataStructureColumn.UpdateUser;
            this.UpdateTmsp = dataStructureColumn == undefined ? null : dataStructureColumn.UpdateTmsp;
        }

        save(): void {
            var result = $.Deferred<number>();
            throw new Error('Save not yet constructed');
        }

    }

}