namespace CRM.APIs {

    export class PropertyScheduleTypes {

        private static _routePrefix: string = '/api/UW/ScheduleTypes/';

        static getPropertyScheduleTypes(groupID: string): JQueryDeferred<Models.PropertyScheduleTypes[]> {
            var $result = $.Deferred();
            $.get(this._routePrefix + 'GetPropertyScheduleTypes?GroupID=' + groupID).done((scheduleTypes) => {
                $result.resolve(scheduleTypes);
            });
            return $result;
        }

    }

}