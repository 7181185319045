namespace Que.Forms.Models {

    export class FormStatusTrigger {
        TriggerID: number;
        FormID: number;
        StatusTrigger: string;
        StepStatus: string;
        StepIndex: number;
        StepType: number;
        StepCommand: string;
        _isSaved: boolean;

        constructor(trigger?: Que.Forms.Interfaces.IFormStatusTrigger) {
            if (!trigger) trigger = {};
            this.update(trigger);
        }

        update(trigger?: Que.Forms.Interfaces.IFormStatusTrigger): FormStatusTrigger {
            var set_prop = mW.classes.set_property_value;
            set_prop(this, 'TriggerID', trigger.TriggerID, null);
            set_prop(this, 'FormID', trigger.FormID, null);
            set_prop(this, 'StatusTrigger', trigger.StatusTrigger, null);
            set_prop(this, 'StepStatus', trigger.StepStatus, null);
            set_prop(this, 'StepIndex', trigger.StepIndex, null);
            set_prop(this, 'StepType', trigger.StepType, null);
            set_prop(this, 'StepCommand', trigger.StepCommand, null);
            // semi-private properties - not part of the real formstatustrigger class
            set_prop(this, '_isSaved', trigger._isSaved, null);
            return this;
        }

        get(): JQueryDeferred<FormStatusTrigger[]> {
            var $result = $.Deferred();            
            Que.Forms.APIs.WYSIWYG_Repo.getStatusTrigger(this.FormID).done((data) => {
                $result.resolve(data);
            });
            return $result;
        }

        save(): JQueryDeferred<any> {
            var $result = $.Deferred();
            Que.Forms.APIs.WYSIWYG_Repo.saveStatusTrigger(this).done((triggerID) => {
                this.TriggerID = triggerID;
                $result.resolve(triggerID);
            });
            return $result;
        }

        delete(): JQueryDeferred<any> {
            var $result = $.Deferred();
            Que.Forms.APIs.WYSIWYG_Repo.deleteStatusTrigger(this.TriggerID).done((data) => {
                this.StepStatus = 'deleted';
                $result.resolve(data);
            });
            return $result;
        }
    }

}