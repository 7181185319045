namespace Shared.APIs {

    export class ClientIcons {

        private static _routePrefix = '/api/Common/ClientIcons/';

        public static get(): JQueryDeferred<any> {
            var $result = $.Deferred();
            $.get(this._routePrefix + 'Get').done((data) => {
                $result.resolve(data);
            });
            return $result;
        }

    }

}