namespace Que.Forms.Models {

    export class Section {

        SectionID: number;
        FormID: number;
        Title: string;
        Tooltip: string;
        CompletionStatus: number;
        SortOrder: number;
        Status: string;
        DefaultVisibility: number;
        IsRequired: boolean;
        ValidationLevel: number;
        SectionIndex: number;
        Elements: Que.Forms.Models.Element[];
        MetaAttributes: Que.Forms.Models.MetaAttribute[];
        CreateUser: string;
        CreateTmsp: string | Date;
        UpdateUser: string;
        UpdateTmsp: string | Date;
        // dynformobject
        //DefaultVisibility: number;

        // semi-private
        _propertyHasChanged: boolean;
        _metaHasChanged: boolean;
        _sortHasChanged: boolean;
        _clone: Section;

        constructor(section?: Interfaces.ISection) {
            this.update(section);
        }

        update(section?: Interfaces.ISection): Section {           

            var set_prop = mW.classes.set_property_value;       
            set_prop(this, 'SectionID', section.SectionID, null);            
            set_prop(this, 'FormID', section.FormID, null);
            set_prop(this, 'Title', section.Title, null);
            set_prop(this, 'Tooltip', section.Tooltip, null);
            set_prop(this, 'CompletionStatus', section.CompletionStatus, null);
            set_prop(this, 'SortOrder', section.SortOrder, null);
            set_prop(this, 'Status', section.Status, null);
            set_prop(this, 'DefaultVisibility', section.DefaultVisibility, null);
            set_prop(this, 'IsRequired', section.IsRequired, null);
            set_prop(this, 'ValidationLevel', section.ValidationLevel, null);
            set_prop(this, 'SectionIndex', section.SectionIndex, null);
            set_prop(this, 'CreateUser', section.CreateUser, null);
            set_prop(this, 'CreateTmsp', section.CreateTmsp, null);
            set_prop(this, 'UpdateUser', section.UpdateUser, null);
            set_prop(this, 'UpdateTmsp', section.UpdateTmsp, null);
            // semi-private properties
            set_prop(this, '_propertyHasChanged', section._propertyHasChanged, false);
            set_prop(this, '_metaHasChanged', section._metaHasChanged, false);
            set_prop(this, '_sortHasChanged', section._sortHasChanged, false);
            set_prop(this, '_clone', section._clone, null);

            this.Elements = new Array<Que.Forms.Models.Element>();
            if (section != undefined && section.Elements != undefined) {
                section.Elements.forEach((eleO) => {
                    this.Elements.push(new Que.Forms.Models.Element(eleO));
                });
            }
            this.MetaAttributes = new Array<Que.Forms.Models.MetaAttribute>();
            if (section != undefined && section.MetaAttributes != undefined) {
                section.MetaAttributes.forEach((metaO) => {
                    this.MetaAttributes.push(new Que.Forms.Models.MetaAttribute(metaO));
                });
            }
            //this.SectionID = section == undefined ? null : section.SectionID;
            //this.FormID = section == undefined ? null : section.FormID;
            //this.Title = section == undefined ? null : section.Title;
            //this.Tooltip = section == undefined ? null : section.Tooltip;
            //this.CompletionStatus = section == undefined ? null : section.CompletionStatus;
            //this.SortOrder = section == undefined ? null : section.SortOrder;
            //this.Status = section == undefined ? null : section.Status;
            //this.CurrentVisibility = section == undefined ? null : section.CurrentVisibility;
            //this.IsRequired = section == undefined ? null : section.IsRequired;
            //this.ValidationLevel = section == undefined ? null : section.ValidationLevel;
            //this.SectionIndex = section == undefined ? null : section.SectionIndex;

            //this.CreateUser = section == undefined ? null : section.CreateUser;
            //this.CreateTmsp = section == undefined ? null : section.CreateTmsp;
            //this.UpdateUser = section == undefined ? null : section.UpdateUser;
            //this.UpdateTmsp = section == undefined ? null : section.UpdateTmsp;

            // private properties that aren't really private
            //this._propertyHasChanged = false;
            //this._metaHasChanged = false;
            //this._sortHasChanged = false;
            //this._clone = null;

            return this;

        }

        delete(): JQueryDeferred<any> {
            var $results = $.Deferred();
            Que.Forms.APIs.WYSIWYG_Repo.deleteSection(this).done((data) => {
                $results.resolve(data);
            });
            return $results;
        }

        save(): JQueryDeferred<number> {
            var result = $.Deferred<number>();
            Que.Forms.APIs.WYSIWYG_Repo.saveSection(this).done((sectionID) => {
                result.resolve(sectionID);
            });
            return result;
        }

        saveElements(): JQueryDeferred<any> {
        // important note - when making to any elements in the Que.Form.Models.Element array, this method only respects elements that are marked as "_hasChanged = true", please be sure to update the element object if you wanted to save.
            var result = $.Deferred(), modifiedElements: Que.Forms.Models.Element[] = new Array<Que.Forms.Models.Element>();

            this.Elements.forEach((element) => {
                element.FormSectionID = this.SectionID;
                if (element._propertyHasChanged || element._metaHasChanged || element._sortHasChanged) {
                    element._propertyHasChanged = false;
                    element._metaHasChanged = false;
                    element._sortHasChanged = false;
                    modifiedElements.push(element);
                }
            });

            Que.Forms.APIs.WYSIWYG_Repo.saveElements(modifiedElements).done((data) => {
                result.resolve(data);
            });
            return result;
        }

        deleteElement(elementID: number): JQueryDeferred<any> {
            var $result = $.Deferred();
            this._get_element(elementID).delete().done(() => {
                this._splice_element(elementID).done(() => {
                    $result.resolve(true);
                });
            });
            return $result;
        }

        private _splice_element(elementID: number): JQueryDeferred<any> {
            var $result = $.Deferred();
            this.Elements.forEach((element, i, eleArry) => {
                if (element.ElementID == elementID) {
                    eleArry.splice(i, 1);
                    $result.resolve(true);
                }
            });
            return $result;
        }

        private _get_element(elementID: number): Que.Forms.Models.Element {
            var element: Que.Forms.Models.Element = null;
            for (var i = 0; i < this.Elements.length; i++) {
                if (this.Elements[i].ElementID == elementID) {
                    element = this.Elements[i];
                }
            }
            return element;
        }

        //getElement(elementID: number): PL.Que.Models.Design.Element {
        //    var element: PL.Que.Models.Design.Element;
        //    this.Elements.forEach((eO, eI) => {
        //        if (eO.ElementID == elementID) {
        //            element = eO;
        //        }
        //    });
        //    return element;
        //}

    }

}