namespace CRM.Models {

    export class Location {

        LocationID: number;        
        ClientID: number;
        ClientNumber: string;
        Status: string;
        ContactID: number;
        DBA: string;
        Description: string;
        LocationDBA: string;
        Address1: string;
        Address2: string;
        City: string;
        State: string;
        Zip: string;
        Zip4: string;
        PhoneAC: string;
        Phone: string;
        PhoneExt: string;
        FaxAC: string;
        Fax: string;
        LocationNumber: number;
        StoreNumber: string;
        FEIN: string;
        StartDate: string|Date;
        TerminationDate: string|Date;
        Directions: string;
        Comments: string;
        RegionCode: string;
        Latitude: number;
        Longitude: number;
        ClaimEmail: string;
        ScheduleID: number;
        LocationString: string;
        CreateTmsp: string|Date;
        CreateUser: string;
        UpdateTmsp: string|Date;
        UpdateUser: string;

        constructor(location?: CRM.Interfaces.ILocation) {
            this.LocationID = location == undefined ? null : location.LocationID;
            this.ClientID = location == undefined ? null : location.ClientID;
            this.ClientNumber = location == undefined ? null : location.ClientNumber;
            this.Status = location == undefined ? null : location.Status;
            this.ContactID = location == undefined ? null : location.ContactID;
            this.DBA = location == undefined ? null : location.DBA;
            this.Description = location == undefined ? null : location.Description;
            this.LocationDBA = location == undefined ? null : location.LocationDBA;
            this.Address1 = location == undefined ? null : location.Address1;
            this.Address2 = location == undefined ? null : location.Address2;
            this.City = location == undefined ? null : location.City;
            this.State = location == undefined ? null : location.State;
            this.Zip = location == undefined ? null : location.Zip;
            this.Zip4 = location == undefined ? null : location.Zip4;
            //this.PhoneAC = location == undefined ? null : location.PhoneAC;
            this.Phone = location == undefined ? null : location.Phone;
            this.PhoneExt = location == undefined ? null : location.PhoneExt;
            //this.FaxAC = location == undefined ? null : location.FaxAC;
            this.Fax = location == undefined ? null : location.Fax;
            this.LocationNumber = location == undefined ? null : location.LocationNumber;
            this.StoreNumber = location == undefined ? null : location.StoreNumber;
            this.FEIN = location == undefined ? null : location.FEIN;
            this.StartDate = location == undefined ? null : location.StartDate;
            this.TerminationDate = location == undefined ? null : location.TerminationDate;
            this.Directions = location == undefined ? null : location.Directions;
            this.Comments = location == undefined ? null : location.Comments;
            this.RegionCode = location == undefined ? null : location.RegionCode;
            this.Latitude = location == undefined ? null : location.Latitude;
            this.Longitude = location == undefined ? null : location.Longitude;
            this.ClaimEmail = location == undefined ? null : location.ClaimEmail;
            this.ScheduleID = location == undefined ? null : location.ScheduleID;
            this.LocationString = location == undefined ? null : location.LocationString;
            this.CreateTmsp = location == undefined ? null : location.CreateTmsp;
            this.CreateUser = location == undefined ? null : location.CreateUser;
            this.UpdateTmsp = location == undefined ? null : location.UpdateTmsp;
            this.UpdateUser = location == undefined ? null : location.UpdateUser;
        }

        get(): JQueryDeferred<Location> {
            var $result = $.Deferred();
            CRM.APIs.Location.getLocation(this.LocationID).done((location) => {
                mW.classes.propagatePropertyValues(location, this);
                $result.resolve(location);
            });
            return $result;
        }

        isLocationNumberValid(): JQueryDeferred<boolean> {
            var $result = $.Deferred();
            CRM.APIs.Location.isLocationNumberValid(this.LocationNumber, this.ClientID).done((isValid) => {
                $result.resolve(isValid);
            });
            return $result;
        }

        save(): JQueryDeferred<number> {
            var $result = $.Deferred();
            CRM.APIs.Location.saveLocation(this).done((locationID) => {
                this.LocationID = locationID;
                $result.resolve(locationID);
            });
            return $result;
        }

    }

}