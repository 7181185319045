namespace Shared.APIs {

    export class States {

        private static _routePrefix: string = '/api/Shared/States/';

        static getStates(): JQueryDeferred<Models.States> {
            var $result = $.Deferred();
            $.get(this._routePrefix + 'GetStates').done((states) => {
                $result.resolve(states);
            });
            return $result;
        }

    }

}