module CRM.APIs {

    export class Clients {

        static _routePrefix: string = '/api/CRM/Clients/';

        public static getClientInfo(clientID: number): JQueryDeferred<CRM.Models.Client> {
            var result = $.Deferred();
            $.get(this._routePrefix + 'GetClient?ClientID=' + clientID).done(function (data) {
                result.resolve(data);
            });
            return result;
        }

        public static getAdditionalClientInfo(clientID: number, addTypeID: number): JQueryDeferred<CRM.Models.ClientAddInfo> {
            var result = $.Deferred();
            $.get(this._routePrefix + 'GetClientAddInfo?ClientID=' + clientID + '&AddTypeID=' + addTypeID).done(function (data) {
                result.resolve(data);
            });
            return result;
        }

    }

}