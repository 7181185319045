namespace CRM.Models {

    export class KO_Location {

        // Location support properties
        _phonePrefix: KnockoutObservable<string>;
        _phoneSuffix: KnockoutObservable<string>;
        _faxPrefix: KnockoutObservable<string>;
        _faxSuffix: KnockoutObservable<string>;
        _hasLocationID: KnockoutObservable<boolean>;

        // Location properties
        LocationID: KnockoutObservable<number>;
        ClientID: KnockoutObservable<number>;
        ClientNumber: KnockoutObservable<string>;
        Status: KnockoutObservable<string>;
        ContactID: KnockoutObservable<number>;
        DBA: KnockoutObservable<string>;
        Description: KnockoutObservable<string>;
        LocationDBA: KnockoutObservable<string>;
        Address1: KnockoutObservable<string>;
        Address2: KnockoutObservable<string>;
        City: KnockoutObservable<string>;
        State: KnockoutObservable<string>;
        Zip: KnockoutObservable<string>;
        Zip4: KnockoutObservable<string>;
       // PhoneAC: KnockoutObservable<string>;
        Phone: KnockoutObservable<string>;
        PhoneExt: KnockoutObservable<string>;
       // FaxAC: KnockoutObservable<string>;
        Fax: KnockoutObservable<string>;
        LocationNumber: KnockoutObservable<number>;
        StoreNumber: KnockoutObservable<string>;
        FEIN: KnockoutObservable<string>;
        StartDate: KnockoutObservable<string|Date>;
        TerminationDate: KnockoutObservable<string|Date>;
        Directions: KnockoutObservable<string>;
        Comments: KnockoutObservable<string>;
        RegionCode: KnockoutObservable<string>;
        Latitude: KnockoutObservable<number>;
        Longitude: KnockoutObservable<number>;
        ClaimEmail: KnockoutObservable<string>;
        ScheduleID: KnockoutObservable<number>;
        LocationString: KnockoutObservable<string>;
        CreateTmsp: KnockoutObservable<string|Date>;
        CreateUser: KnockoutObservable<string>;
        UpdateTmsp: KnockoutObservable<string|Date>;
        UpdateUser: KnockoutObservable<string>;        

        write(location?: CRM.Interfaces.ILocation) {
                    
            for (var loc in location) {         
                if (this[loc] != undefined && this[loc].name == 'c') {
                    if (location[loc] != null && location[loc] != '0001-01-01T00:00:00') {
                        this[loc](location[loc]);
                    }                    
                } else {
                    this[loc] = ko.observable((location[loc] == undefined ? null : location[loc]));                
                }                
            }            

        }

        clear(): JQueryDeferred<any> {
            var $result = $.Deferred();
            for (var ko in this) {
                if (ko != 'convertToKOObject' && ko != 'clear' && ko != 'get' && ko != 'isLocationNumberValid' && ko != 'save' && ko != 'write') {
                    if ((<any>this[ko]).name != 'e') {
                        (<any>this[ko])(null);
                    }
                    
                }
            }
            $result.resolve(true);
            return $result;
        }

        get(): JQueryDeferred<any> {
            var $result = $.Deferred();
            CRM.APIs.Location.getLocation(this.LocationID()).done((location) => {                
                this.write(<any>location);                              
                $result.resolve(location);
            });
            return $result;
        }

        isLocationNumberValid(): JQueryDeferred<boolean> {
            var $result = $.Deferred();
            CRM.APIs.Location.isLocationNumberValid(this.LocationNumber(), this.ClientID()).done((isValid) => {
                $result.resolve(isValid);
            });
            return $result;
        }

        save(): JQueryDeferred<number> {
            var $result = $.Deferred(), location = ko.mapping.toJS(this);            
            CRM.APIs.Location.saveLocation(location).done((locationID) => {   
                this.LocationID(locationID);                                             
                $result.resolve(locationID);
            });
            return $result;
        }

    }

}