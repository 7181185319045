namespace mW {

    export class helpers {

        static create_temp_identifier_key_by_array(arry: any[], idPropNameInObject: string): number {
            let id = 0;
            if (arry != null && arry.length > 0) {
                // first we the last identity value in the array.
                id = arry.length != 0 ? (arry[arry.length - 1][idPropNameInObject]) : -1;
                // than, we add one to that number and turn it into a negative number
                id = id < 0 ? -Math.abs(Math.abs(id) + 1) : -Math.abs(id + 1);
            }
            return id;
        }

    }

}