module Integrations.Google {
    declare var YT: any; // youtube player_api global var
    export class YouTube {
        constructor() {
            // hardcode for now?
            this._APIKey = 'AIzaSyAjJ9dazXzK5Vh9KHeXZPSgTkYxfLAbDvE';
            this._ClientID = '1020899191955-hfnhccfup77c0bnapc4pv3n3ts44ocd8.apps.googleusercontent.com';
            this._Scope = 'https://www.googleapis.com/auth/youtube';

            //this.InitPlayerAPI();
        }

        /*  include to load google api js library - include after handler definition?
            <script src="https://apis.google.com/js/client.js?onload=YTOnLoadCallback"></script>
        */

        private _APIKey: string;
        private _ClientID: string;
        private _Scope: string;
        private _UserPlaylistID: string;
        private _UserAccessToken: string;

        public UserAccountName: string;
        public VideosContainerID: string;
        public UploadModal: GUI.Windows.Popup;
        public Uploader: any; //qq fineuploader type?
        public Metadata: any; // type later?

        public InitClientLibrary = (): JQueryDeferred<any> => {
            let df = $.Deferred();
            gapi.client.setApiKey(this._APIKey);
            this.LoadApiClientInterface().done(() => {
                df.resolve();
            }).fail(() => {
                df.reject();
            });
            return df;
        };
        /* OAuth2 Module */
        public HandleClientLoad = () => {
            gapi.client.setApiKey(this._APIKey);
            gapi.auth.init(() => {
                //window.setTimeout(this.CheckAuth, 1); // not sure why we need to timeout
            });
        };

        public HandleAuthResult = (authResult: any): JQueryDeferred<any> => {
            let df = $.Deferred();
            console.log(authResult);
            if (authResult && authResult.error) {
                console.log("OAuth2 error: " + authResult.error + ' - ' + authResult.error_subtype);
                df.reject();
            }
            else {
                console.log('OAuth2 success');
                this._UserAccessToken = authResult.access_token;
                //this.UserAccountName = somereuslt;
                this.LoadApiClientInterface()
                    .done(() => {
                        df.resolve();
                    });
            }
            return df;
        };

        public CheckAuth = (): JQueryDeferred<any> => {
            let df = $.Deferred();
            gapi.auth.authorize({
                client_id: this._ClientID,
                scope: this._Scope,
                immediate: false
            }, (authResult) => {
                this.HandleAuthResult(authResult)
                    .done(() => {
                        df.resolve();
                    })
                    .fail(() => {
                        df.reject();
                    });
            });
            return df;
        };

        public LoadApiClientInterface = () => {
            let df = $.Deferred();
            gapi.client.load('youtube', 'v3', () => {
                df.resolve();
                //this.HandleApiLoad();
            });
            return df;
        };

        // maintain flexible functionality
        public HandleApiLoad = () => {
            console.log('requesting playlist id...');
            this.RequestUserUploadsPlaylistId();
        };

        // get playlist id for user's channel
        public RequestUserUploadsPlaylistId = () => {
            let request = (<any>gapi.client).youtube.channels.list({
                mine: true,
                part: 'contentDetails'
            });
            request.execute((response) => {
                let channelResources: Array<GoogleApiYouTubeChannelResource> = response.result.items;
                this._UserPlaylistID = channelResources[0].contentDetails.relatedPlaylists.uploads;
                console.log('playlistID = ' + this._UserPlaylistID);
                this.RequestVideoPlaylist(this._UserPlaylistID, null);
            });
            
        };

        public RequestVideoPlaylist = (playlistID: string, pageToken: any) => {
            let request = (<any>gapi.client).youtube.playlistItems.list({
                playlistId: this._UserPlaylistID,
                part: 'snippet', // not sure
                maxResults: 10
            });

            request.execute((response: any) => {
                // dynamically show pagination
                let nextPageToken = response.result.nextPageToken;
                let nextVis = nextPageToken ? 'visibile' : 'hidden';
                //$('#next-button').css('visibility', nextVis);
                let prevPageToken = response.result.prevPageToken;
                let prevVis = prevPageToken ? 'visibile' : 'hidden';
                //$('#prev-button').css('visibility', nextVis);

                let playlistItems = response.result.items;
                if (playlistItems) {
                    $.each(playlistItems, (i, item) => {
                        //this.DisplayVideoByVideoID(item.snippet);
                    });
                } else {
                    console.log("No videos in playlist to display...");
                }
            });
        };

        /* Video Player Module */
        public DisplayVideoByVideoID = (item: any, containerID: string) => {
            this.VideosContainerID = containerID;
            // create a model object?
            let title = item.title;
            let videoID = item.resourceId.videoId;
            //$('#' + this.VideosContainerID).append('<p>' + title + ' - ' + videoID + '</p>');
            var plyr = '\
            <iframe id="ytplayer" type= "text/html" width= "640" height= "390"\
            src = "http://www.youtube.com/embed/' + videoID + '?autoplay=0&origin=http://example.com"\
            frameborder = "0" />';
            $('#' + this.VideosContainerID).append(plyr);
        };

        public DisplayPlaylistByUsername = (username: string, containerID: string) => {
            this.VideosContainerID = containerID;
            // check if username is valid yt username
            // to-do

            let plyr = `
            <iframe id="ytplayer" type= "text/html" width= "640" height= "390"
            src = "https://www.youtube.com/embed?listType=user_uploads&list=${username}"
            frameborder = "0" />`;
            $('#' + this.VideosContainerID).append(plyr);
        };

        public DisplayPlaylistByPlaylistID = (playlistID: string, containerID: string) => {
            this.VideosContainerID = containerID;
            // check if username is valid yt username
            // to-do

            let plyr = `
            <iframe id="ytplayer" type= "text/html" width= "640" height= "390"
            src = "https://www.youtube.com/embed?listType=playlist&list=${playlistID}"
            frameborder = "0" />`;
            $('#' + this.VideosContainerID).append(plyr);
        };

        public DisplayPlaylistByChannelID = (channelID: string, containerID: string) => {
            let request = (<any>gapi.client).youtube.channels.list({
                id: channelID,
                part: 'contentDetails'
            });
            request.execute((response) => {
                let channelResources: Array<GoogleApiYouTubeChannelResource> = response.result.items;
                let playlistID = channelResources[0].contentDetails.relatedPlaylists.uploads;
                this.DisplayPlaylistByPlaylistID(playlistID, containerID);
            });
        };
        /* Player API Module */
        public InitPlayerAPI = () => {
            // async load player_api
            if (typeof YT === 'undefined') {
                let script = document.createElement('script'),
                    head = document.getElementsByTagName('head')[0];
                script.src = 'http://www.youtube.com/player_api';
                head.appendChild(script);
            }
        };

        /* Upload Video Module */
        public CreateUploadModal = () => {
            this.UploadModal = new GUI.Windows.Popup();
            this.UploadModal.WindowID = "youtube-upload";
            this.UploadModal.Title = "YouTube Video Upload";
            this.UploadModal.Width = 600;
            this.UploadModal.Height = 450;
            //this.Modal.HTMLContent = Handlebars.compile($('#claimant-template').html())(null);
            this.UploadModal.HTMLContent = CHSITemplates.Google.UploadVideoModal({ Account: this.UserAccountName });
            this.UploadModal.showInline();
            
            $('.modal-signin').on('click', () => {
                this.CheckAuth()
                    .done(() => {
                        // probably also want to check to make sure they logged in with expected account
                        $('#auth-container, .modal-signin').fadeOut('fast', () => {
                            $('#upload-container').fadeIn('fast', () => {
                                this.CreateUploader();
                            });
                        });
                    })
                    .fail(() => {
                    });
            });
            $('.modal-cancel').on('click', () => {
                GUI.Windows.Popup.closeThisInline('#modal_youtube-upload');
            });
        };

        public CreateUploader = () => {
            // encodeURIComponent(JSON.stringify(Object.keys(this.Metadata).join(',')))
            this.Uploader = new qq.FineUploader({
                element: document.getElementById('upload-temp-container'),
                template: 'qq-template-gallery',
                request: {
                    endpoint: 'https://www.googleapis.com/upload/youtube/v3/videos?part=snippet',
                    //params: this.Metadata,
                    customHeaders: {
                        'Authorization': 'Bearer ' + this._UserAccessToken,
                    },
                    //paramsInBody: false
                },
                debug: true,
                multiple: false,
                autoUpload: true,
                callbacks: {
                    //onSubmitted: (id, fileName) => {
                    //    // create popup to get metadata from user input
                    //    this.CreateDetailsPopup()
                    //        .done(() => {
                    //            var params = {
                    //                metadata: JSON.stringify(this.Metadata)
                    //            }
                    //            this.Uploader.setParams(params, id);
                    //            this.Uploader.uploadStoredFiles();
                    //        })
                    //        .fail(() => {
                    //            this.Uploader.cancel(id);
                    //        });
                    //},
                    onComplete: (id, fileName, response) => {
                        if (response.id && response.id.length > 0) {
                            // update video to set metadata
                            this.Metadata = {
                                id: response.id,
                                snippet: {
                                    title: fileName,
                                    description: "Uploaded by CHSI Connections.",
                                    categoryId: response.snippet.categoryId
                                }
                                //status: {
                                //    privacyStatus: 'public',
                                //}
                            };
                            $.ajax({
                                type: 'PUT',
                                url: 'https://www.googleapis.com/youtube/v3/videos?part=snippet',
                                headers: {
                                    'Authorization': 'Bearer ' + this._UserAccessToken,
                                },
                                dataType: 'text',
                                contentType: 'application/json',
                                data: JSON.stringify(this.Metadata),
                                success: function (response) {
                                }
                            });
                            this.Uploader.clearStoredFiles();
                        }
                        else {
                            alert("Upload Error: " + response.error.message + ".\r\r If this problem persists, please contact our support team at: support@chsitech.com");
                        }
                    }
                }
            });
        }
    }
}