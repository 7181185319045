namespace CRM.ViewModels {

    export class user_Location_Edit_Demographics extends CRM.Base.user_Location_Edit_Base {

        DemoGrid: GUI.Tables.DTables = new GUI.Tables.DTables;

        init(baseData: CRM.Base.Location_Base_Data): void {
            this.BaseData = baseData;
            this.DemoGrid.data = baseData.Demographics;
            this.setup();
            this.convertToListOrInput();
            this.bindGrid();
            this.events(); 
        }

        setup(): void {
            if (this.DemoGrid.data != undefined && this.DemoGrid.data.Columns.length != 0) {
                $('#demoScheduleOnly').attr('checked', 'checked');
            }
        }

        events(): void {

            $('#demoScheduleOnly').on('change', () => {
                this.updateDemographicsView(true);
            });

            $('#demoScheduleAll').on('change', () => {
                this.updateDemographicsView(false);
            });

            $('#demoDataTableContainer').on('change', '.demoSelect', (e) => {
                var addTypeID = $(e.target).data('addtypeid'), value = $(e.target).val();
                this.update(addTypeID, value);
            });

            $('#demoDataTableContainer').on('blur', '.demoInput', (e) => {
                var addTypeID = $(e.target).data('addtypeid'), value = $(e.target).val();
                this.update(addTypeID, value);
            });

        }

        update(addTypeID: string, value: string): void {
            this.DemoGrid.data.Values.forEach((valO) => {
                if (valO[0] == addTypeID) {
                    (<any>valO)[3] = value;
                }
            });
        }

        updateDemographicsView(isScheduleID: boolean): void {
            var scheduleID = null;

            if (this.BaseData != undefined) {

                if (isScheduleID == true) {
                    scheduleID = this.BaseData.Location.ScheduleID();
                }

                new CRM.Models.ClientAddType({ GroupID: this.BaseData.Client.GroupID }).getLocationAddTypes(this.BaseData.Location.LocationID(), scheduleID).done((addTypes) => {
                    this.DemoGrid.data = addTypes;
                    this.convertToListOrInput();
                    this.DemoGrid.refreshTable();
                });

            }
        }

        save(): void {            
            $('button').prop('disabled', true);            
            var clientAddTypes = mW.io.reconstruct_object(this.DemoGrid.data);
            CRM.Models.ClientAddType.saveAll(clientAddTypes).done(() => {
                $('button').prop('disabled', false);                
            });
        }

        convertToListOrInput(): void {
            if (this.DemoGrid.data) {
                var Column = new GUI.Smart.SmartColumnObject();
                Column.title = 'Value',
                Column.data = null;
                Column.orderable = false;
                Column.class = 'details-control';                
                Column.render = function (data, type, row) {
                    if (data[9] == 'dropdown') {
                        var splitValue = data[6].split(','), html = [];
                        html.push('<select class="demoSelect" data-addtypeid="' + data[0] + '">');
                        html.push('<option value=""></option>');
                        splitValue.forEach((o) => {
                            if (o == data[3]) {
                                html.push('<option value="' + o + '" selected>' + o + '</option>');
                            } else {
                                html.push('<option value="' + o + '">' + o + '</option>');
                            }                            
                        });
                        html.push('</select>');
                        return html.join('');
                    } else {
                        return '<input class="demoInput" data-addtypeid="' + data[0] + '" type="text" value="' + data[3] + '" />';
                    }
                }

                this.DemoGrid.data.Columns.push(Column);
            }
        }

        bindGrid(): void {
            this.DemoGrid.dtObject.paging = false;
            this.DemoGrid.columnSearch = false;
            this.DemoGrid.containerName = 'demoDataTableContainer';
            this.DemoGrid.tableName = 'demoDataTableGrid';
            this.DemoGrid.buildTable();
        } 
    }

}