namespace Que.Forms.Models {

    export class Form {

        FormID: number;
        ResultSetID: number;
        Title: string;
        DueDate: string | Date;
        Status: string;
        ParentDataStructure: any;
        DateOfStatus: string | Date;
        FormDescription: string;
        ActiveDate: string | Date;
        InactiveDate: string | Date;
        DataStructureID: number;
        DesignTemplateID: number;
        DataStructureName: string;
        StructureTypeName: string;
        FieldsRequired: boolean;
        isPortalEditable: boolean;
        isConnectionsEditable: boolean;
        CreateTsmp: string | Date;
        CreateUser: string;
        UpdateUser: string;
        UpdateTmsp: string | Date;
        isUsed: boolean;
        HelpIDs: any;
        isInternal: boolean;
        ClientID: number;
        FormClientID: any;
        ReportFormName: string;
        ReportID: number;
        PreviousResultSetID: number;
        Elements: any[];
        ElementCalculations: any[];
        ResultSetValues: any[];
        ActionBehaviors: any[];
        Sections: Que.Forms.Models.Section[];
        MetaAttributes: Que.Forms.Models.MetaAttribute[];

        //semi-private
        _metaHasChanged: boolean;

        constructor(form?: Interfaces.IForms) {
            this.update(form);
        }

        update(form?: Interfaces.IForms): Form {
            if (form == (null || undefined)) form = {};
            var set_prop = mW.classes.set_property_value;
            set_prop(this, 'FormID', form.FormID, null);
            set_prop(this, 'ResultSetID', form.ResultSetID, null);
            set_prop(this, 'Title', form.Title, null);
            set_prop(this, 'DueDate', form.DueDate, null);
            set_prop(this, 'Status', form.Status, null);
            set_prop(this, 'ParentDataStructure', form.ParentDataStructure, null);
            set_prop(this, 'DateOfStatus', form.DateOfStatus, null);
            set_prop(this, 'FormDescription', form.FormDescription, null);
            set_prop(this, 'ActiveDate', form.ActiveDate, null);
            set_prop(this, 'InactiveDate', form.InactiveDate, null);
            set_prop(this, 'DataStructureID', form.DataStructureID, null);
            set_prop(this, 'DesignTemplateID', form.DesignTemplateID, null);
            set_prop(this, 'DataStructureName', form.DataStructureName, null);
            set_prop(this, 'StructureTypeName', form.StructureTypeName, null);
            set_prop(this, 'FieldsRequired', form.FieldsRequired, null);
            set_prop(this, 'isPortalEditable', form.isPortalEditable, null);
            set_prop(this, 'isConnectionsEditable', form.isConnectionsEditable, null);
            set_prop(this, 'CreateTsmp', form.CreateTsmp, null);
            set_prop(this, 'CreateUser', form.CreateUser, null);
            set_prop(this, 'UpdateUser', form.UpdateUser, null);
            set_prop(this, 'UpdateTmsp', form.UpdateTmsp, null);
            set_prop(this, 'isUsed', form.isUsed, null);
            set_prop(this, 'HelpIDs', form.HelpIDs, null);
            set_prop(this, 'isInternal', form.isInternal, null);
            set_prop(this, 'ClientID', form.ClientID, null);
            set_prop(this, 'FormClientID', form.FormClientID, null);
            set_prop(this, 'ReportFormName', form.ReportFormName, null);
            set_prop(this, 'ReportID', form.ReportID, null);
            set_prop(this, 'PreviousResultSetID', form.PreviousResultSetID, null);
            set_prop(this, 'Sections', form.Sections, null);
            set_prop(this, 'Elements', form.Elements, null);
            set_prop(this, 'ElementCalculations', form.ElementCalculations, null);
            set_prop(this, 'ResultSetValues', form.ResultSetValues, null);
            set_prop(this, 'ActionBehaviors', form.ActionBehaviors, null);

            if (this.Sections == (undefined || null)) {
                this.Sections = new Array<Que.Forms.Models.Section>();
            }
            
            if (form != undefined && form.Sections != undefined) {
                form.Sections.forEach((sections) => {
                    this.Sections.push(sections);
                });
            }

            if (this.MetaAttributes == (undefined || null)) {
                this.MetaAttributes = new Array<Que.Forms.Models.MetaAttribute>();
            }
            
            if (form != undefined && form.MetaAttributes != undefined) {
                form.MetaAttributes.forEach((metaO) => {
                    this.MetaAttributes.push(new Que.Forms.Models.MetaAttribute(metaO));
                });
            }
            
            set_prop(this, '_metaHasChanged', form._metaHasChanged, false);
  
            return this;
        }

        get(): JQueryDeferred<Form> {
            var $results = $.Deferred();
            Que.Forms.APIs.FormRepo.getForm(this.FormID).done((form) => {
                $results.resolve(form);
            });
            return $results;
        }

        save(): JQueryDeferred<number> {
            var $results = $.Deferred();
            Que.Forms.APIs.WYSIWYG_Repo.saveForm(this).done((formID) => {
                this.FormID = formID;
                this._metaHasChanged = false;
                $results.resolve(formID);
            });
            return $results;
        }

        delete(): JQueryDeferred<any> {
            var $result = $.Deferred();
            this.get().done((form) => {
                form.Status = 'Deleted';
                new Form(form).save().done(() => {
                    $result.resolve(true);
                }).fail((e) => {
                    $result.reject(e);
                });
            })
            return $result;
        }

    }

}