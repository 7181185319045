namespace CRM.Models {

    export class PropertyScheduleTypes {

        ScheduleID: number;
        Description: string;
        GroupID: string;
        NurseTriage: string;
        CreateUser: string;
        CreateTrmsp: string | Date;
        UpdateUser: string;
        UpdateTmsp: string | Date;

        constructor(scheduleTypes?: Interfaces.IPropertyScheduleTypes) {
            this.ScheduleID = scheduleTypes == undefined ? null : scheduleTypes.ScheduleID;
            this.Description = scheduleTypes == undefined ? null : scheduleTypes.Description;
            this.GroupID = scheduleTypes == undefined ? null : scheduleTypes.GroupID;
            this.NurseTriage = scheduleTypes == undefined ? null : scheduleTypes.NurseTriage;
            this.CreateUser = scheduleTypes == undefined ? null : scheduleTypes.CreateUser;
            this.CreateTrmsp = scheduleTypes == undefined ? null : scheduleTypes.CreateTrmsp;
            this.UpdateUser = scheduleTypes == undefined ? null : scheduleTypes.UpdateUser;
            this.UpdateTmsp = scheduleTypes == undefined ? null : scheduleTypes.UpdateTmsp;
        }

        get(): JQueryDeferred<PropertyScheduleTypes[]> {
            var $result = $.Deferred();
            CRM.APIs.PropertyScheduleTypes.getPropertyScheduleTypes(this.GroupID).done((scheduleTypes) => {
                $result.resolve(scheduleTypes);
            });
            return $result;
        }

    }

}