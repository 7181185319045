namespace mW {

    export class object_types {

    // supports child object property updating. Ex: propertyName = 'ParentObject.ChildObject.ChildProperty1'; obj = ParentObject: { ChildObject: { ChildProperty1 : 'value1', ChildProperty2: 'value2', ChildProperty3: 'value3' }, ParentProperty1: 'value1', ParentProperty2: 'value2' }

        static update_property(obj: any, propertyName: string, value: any) {

            var objCopy = obj, props = propertyName.split('.');

            var update_deep_property = (obj: any, props: string[], value: any) => {

                for (var i = 0; i < props.length; i++) {

                    if (props[props.length - 1] == props[i]) {
                        objCopy[props[i]] = value;
                    } else {
                        objCopy = objCopy[props[i]];
                        continue;
                    }

                }

            }

            if (props.length > 1) {
                update_deep_property(obj, props, value);
            } else {
                obj[props[0]] = value;
            }
            

        }

    }

}