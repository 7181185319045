namespace IO.Models {

    export class EmailTemplate {

        TemplateID: number;
        TemplateName: string;
        TemplateBody: string;
        Status: string;
        CreateUser: string;
        CreateTmsp: string;
        UpdateUser: string;
        UpdateTmsp: string;

        constructor(template?: IO.Interfaces.IEmailTemplate) {

            var set_prop = mW.classes.set_property_value;
            set_prop(this, 'TemplateID', template.TemplateID, null);
            set_prop(this, 'TemplateName', template.TemplateName, null);
            set_prop(this, 'TemplateBody', template.TemplateBody, null);
            set_prop(this, 'Status', template.Status, null);
            set_prop(this, 'CreateUser', template.CreateUser, null);
            set_prop(this, 'CreateTmsp', template.CreateTmsp, null);
            set_prop(this, 'UpdateUser', template.UpdateUser, null);
            set_prop(this, 'UpdateTmsp', template.UpdateTmsp, null);

        }

        get(): JQueryDeferred<IO.Models.EmailTemplate[]> {
            var $result = $.Deferred(), templates = new Array<IO.Models.EmailTemplate>();
            IO.APIs.EmailRepo.getEmailTemplates(this.TemplateID).done((_templates) => {
                _templates.forEach((template) => {
                    templates.push(new IO.Models.EmailTemplate(template));                    
                });
                $result.resolve(templates);
            });
            return $result;
        }

    }

}