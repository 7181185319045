namespace CRM.APIs {

    export class Location {

        private static _routePrefix: string = '/api/CRM/Location/';

        static getLocation(locationID: number): JQueryDeferred<CRM.Models.Location> {
            var $results = $.Deferred();
            $.get(this._routePrefix + 'GetLocation?LocationID=' + locationID).done((location) => {
                $results.resolve(location);
            });
            return $results;
        }

        static isLocationNumberValid(locationNumber: number, clientID: number): JQueryDeferred<boolean> {
            var $result = $.Deferred();
            $.get(this._routePrefix + 'IsLocationNumberValid?LocationNumber=' + locationNumber + '&ClientID=' + clientID).done((isValid) => {
                $result.resolve(isValid);
            })
            return $result;
        }

        static saveLocation(location: Models.Location): JQueryDeferred<number> {
            var $result = $.Deferred();
            mW.io.post(this._routePrefix + 'SaveLocation', location).done((locationID) => {
                $result.resolve(locationID);
            });
            return $result;
        }

    }

}