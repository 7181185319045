namespace CRM.ViewModels {

    export class edit_Grid_Contact_Types {

        flat_contacttypes: any;
        current_contacttype: CRM.Models.ContactTypes;
        contacttype_grid: GUI.Tables.DTables = new GUI.Tables.DTables;

        init() {            
            this.get_contacttypes();
        }

        get_contacttypes() {
            CRM.Models.ContactTypes.get_contact_types_flat(false).done((contacttypes) => {
                this.flat_contacttypes = contacttypes;
                this.contacttype_grid.data = contacttypes;
                this.bindGrid();
                this.events();                
            });
        }

        events() {

            var $contactTypeDataTableContainer = $('#contactTypeDataTableContainer');

            $('#contacttype_add').on('click', (e) => {                
                this.open_modal(null);
            });            

            $contactTypeDataTableContainer.on('click', '.contacttype_edit',(e) => {
                var d = this.contacttype_grid.$grid.row($(e.target).closest('tr')).data(), contactTypeID = d[0];
                this.open_modal(contactTypeID);
            });

            $contactTypeDataTableContainer.on('click', '.contacttype_delete', (e) => {
                var d = this.contacttype_grid.$grid.row($(e.target).closest('tr')).data(), contactTypeID = d[0];
                this.get_contact_type_by_id(contactTypeID);
                this.delete();
            });

        }

        modal_events() {            
            $('#contacttype_save').on('click', (e) => {                   
                this.save();           
            });
        }

        prepare_contact_types_modal(): JQueryDeferred<any> {
            var $result = $.Deferred();
            $result.resolve(mW.handlebars.html(CHSITemplates.AdminTools_ContactTypes_Edit, this.current_contacttype));
            return $result;
        }

        open_modal(contactTypeID: number, clickEvent?: any): void {
            this.get_contact_type_by_id(contactTypeID);
            this.prepare_contact_types_modal().done((html) => { 
                var showPopUP = new GUI.Windows.Popup();
                showPopUP.WindowID = 'contacttypeseditor';
                showPopUP.Title = 'Contact Type Editor';
                showPopUP.Width = 135;
                showPopUP.Height = 110;
                showPopUP.CloseFunction = () => this.refresh_grid();                
                showPopUP.HTMLContent = html;
                showPopUP.isModal = false;
                showPopUP.showInline();                
                this.modal_events();
            });

        }

        get_contact_type_by_id(contactTypeID: number) {
            var contactTypes: CRM.Models.ContactTypes[] = mW.io.reconstruct_object(this.contacttype_grid.data);
            this.current_contacttype = null;      
            contactTypes.forEach((_contactType, _index) => {
                if (_contactType.ContactTypeID == contactTypeID) {
                    this.current_contacttype = new CRM.Models.ContactTypes(_contactType);
                }
            });                        
        }

        save(): void {            

            if (this.current_contacttype == null || this.current_contacttype == undefined) {
                this.current_contacttype = new CRM.Models.ContactTypes({});
            }

            var save = () => {

                let c = true;

                if (this.current_contacttype.Status == 'inactive') {

                    c = confirm('By setting this contact type to inactive, contacts that currently use it will continue having this contact type but no new or prior contacts that do not have the contact type will able to use it.');

                }

                if (c) {
                    this.current_contacttype.save().done((id) => {
                        this.current_contacttype.ContactTypeID = id;
                        this.update_flat_contacttypes();
                        this.refresh_grid();
                        $('#loading_notification').html('Saved');
                        $('#loading_notification').delay(1000).fadeOut('fast', () => {
                            $('#loading_notification').html('').show();
                            GUI.Windows.Popup.closeThisInline('#modal_contacttypeseditor');
                            $('#modal_contacttypeseditor').remove();
                        });

                    });
                }

            };  

            this.current_contacttype.ContactTypeName = $('#contacttype_name').val();
            this.current_contacttype.Status = $('#contacttype_status').val();

            if ($('#contacttype_edit_container').data('originalcontacttypename') != this.current_contacttype.ContactTypeName) {
                CRM.Models.ContactTypes.get_in_use_contact_type_count(this.current_contacttype.ContactTypeID).done((count) => {
                    if (count > 0) {
                        let c = confirm('This contact is being used by ' + count + ' other contacts. If you modify the name of this contact type all associated contact types will be affected. Click OK to continue or cancel to abort this change.');
                        if (c) {
                            save();
                        }                        
                    }
                });
            } else {

                save();

            }

                      

        }

        delete(): void {
            CRM.Models.ContactTypes.get_in_use_contact_type_count(this.current_contacttype.ContactTypeID).done((count) => {
                if (count > 0) {
                // don't change status to deleted
                    alert('You cannot delete ' + this.current_contacttype.ContactTypeName + ' because it is in use by other contacts. (' + count + ' contacts use ' + this.current_contacttype.ContactTypeName + '.)');
                } else {                    
                // change status to deleted
                    var c = confirm('Are you sure you want to delete this contact type?');

                    if (c) {
                        this.current_contacttype.delete().done((done) => {
                            this.remove_from_flat_contacttypes(this.current_contacttype.ContactTypeID);
                            this.current_contacttype = new CRM.Models.ContactTypes();
                            this.refresh_grid();
                        });          
                    }
                    
                }
            });
        }

        private remove_from_flat_contacttypes(contactTypeID: number) {
            var contactTypes: any[] = this.flat_contacttypes.Values;

            for (var i = 0; i < contactTypes.length; i++) {
                if (contactTypeID == contactTypes[i][0]) {
                    contactTypes.splice(i, 1);
                    break;
                }
            }

        }

        private update_flat_contacttypes() {
            var isNew = true, contactTypes: any[] = this.flat_contacttypes.Values;

            for (var i = 0; i < contactTypes.length; i++) {
                if (contactTypes[i][0] == this.current_contacttype.ContactTypeID) {                    
                    contactTypes[i][1] = this.current_contacttype.ContactTypeName;
                    contactTypes[i][2] = this.current_contacttype.Status;
                    isNew = false;
                    break;
                }
            }
            
            if (isNew) {
                contactTypes.push([this.current_contacttype.ContactTypeID, this.current_contacttype.ContactTypeName, this.current_contacttype.Status, 'new', 'date_will_be_replaced', 'new', 'date_will_be_replaced']);
            }
            
        }

        refresh_grid() {
            this.contacttype_grid.refreshTable(this.flat_contacttypes);
        }

        add_edit_column(): void {

            var _self = this;
            var cdt: GUI.Tables.DTables = new GUI.Tables.DTables;

            if (this.contacttype_grid.data) {
                var Column = new GUI.Smart.SmartColumnObject();
                //title: '',
                Column.data = null;
                Column.orderable = false;
                Column.class = 'details-control';
                Column.defaultContent = '[<a href="#" class="contacttype_edit minilink">Edit</a>]';
                Column.render = function (data, type, row) {
                    return "<a class='contacttype_edit minilink' title='ContactTypeID " + data[0] + "'>[Edit]</a>";
                }

                this.contacttype_grid.data.Columns.push(Column);
            }

        }

        add_delete_column(): void {

            var _self = this;
            var cdt: GUI.Tables.DTables = new GUI.Tables.DTables;

            if (this.contacttype_grid.data) {
                var Column = new GUI.Smart.SmartColumnObject();
                //title: '',
                Column.data = null;
                Column.orderable = false;
                Column.class = 'details-control';
                Column.defaultContent = '[<a href="#" class="contacttype_edit minilink">Delete</a>]';
                Column.render = function (data, type, row) {
                    return "<a class='contacttype_delete minilink' title='ContactTypeID " + data[0] + "'>[Delete]</a>";
                }

                this.contacttype_grid.data.Columns.push(Column);
            }

        }

        bindGrid(): JQueryDeferred<any> {
            var $result = $.Deferred();            
            this.contacttype_grid.dtObject.displayLength = 25;
            this.contacttype_grid.containerName = 'contactTypeDataTableContainer';
            this.contacttype_grid.tableName = 'contactTypeDataTableGrid';
            this.add_edit_column();            
            this.add_delete_column();
            this.contacttype_grid.buildTable();
            return $result.resolve(true);
        }         

    }

}