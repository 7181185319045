namespace Que.ResultSets.APIs {

    export class SchemaRepo {

        private static _routePrefix = '/api/Que/Schema/';

        static getAllSchemaStatuses(schemaID: number, isActive?: boolean): JQueryDeferred<any> {
            var $result = $.Deferred();
            $.get(this._routePrefix + 'GetAllSchemaStatuses?SchemaTypeID=' + schemaID + '&isActive=' + isActive).done((data) => {
                $result.resolve(data);
            });
            return $result;
        }

    }

}