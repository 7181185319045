namespace Que.Forms.Models {

    export class FormNotification {

        NotificationID: number;
        FormID: number;
        NewStatusTrigger: string;
        ActiveDate: string | Date;
        InActiveDate: string | Date;
        IncludeReport: boolean;
        AddUserAsRecipient: boolean;
        NotificationContactType: string;
        NotificationContactTypeCC: string;
        NotificationCC: string;
        NotificationBCC: string;
        EmailTemplateID: number;
        Body: string;
        Subject: string;
        CreateUser: string;
        CreateTmsp: string | Date;
        UpdateUser: string;
        UpdateTmsp: string | Date;
        NotificationName: string;

        constructor(notifcation?: Que.Forms.Interfaces.IFormNotification) {                                        
            this.update(notifcation);
        }                                

        update(notifcation: Que.Forms.Interfaces.IFormNotification): FormNotification {
            var set_prop = mW.classes.set_property_value;
            set_prop(this, 'NotificationID', notifcation.NotificationID, null);
            set_prop(this, 'FormID', notifcation.FormID, null);
            set_prop(this, 'NewStatusTrigger', notifcation.NewStatusTrigger, null);
            set_prop(this, 'ActiveDate', notifcation.ActiveDate, null);
            set_prop(this, 'InActiveDate', notifcation.InActiveDate, null);
            set_prop(this, 'IncludeReport', notifcation.IncludeReport, null);
            set_prop(this, 'AddUserAsRecipient', notifcation.AddUserAsRecipient, null);
            set_prop(this, 'NotificationContactType', notifcation.NotificationContactType, null);
            set_prop(this, 'NotificationContactTypeCC', notifcation.NotificationContactTypeCC, null);
            set_prop(this, 'NotificationCC', notifcation.NotificationCC, null);
            set_prop(this, 'NotificationBCC', notifcation.NotificationBCC, null);
            set_prop(this, 'EmailTemplateID', notifcation.EmailTemplateID, null);
            set_prop(this, 'Body', notifcation.Body, null);
            set_prop(this, 'Subject', notifcation.Subject, null);
            set_prop(this, 'CreateUser', notifcation.CreateUser, null);
            set_prop(this, 'CreateTmsp', notifcation.CreateTmsp, null);
            set_prop(this, 'UpdateUser', notifcation.UpdateUser, null);
            set_prop(this, 'UpdateTmsp', notifcation.UpdateTmsp, null);
            set_prop(this, 'NotificationName', notifcation.NotificationName, null);
            return this;
        }

        get(): JQueryDeferred<FormNotification[]> {
            var $result = $.Deferred();
            Que.Forms.APIs.WYSIWYG_Repo.getNotifications(this.NotificationID).done((data) => {
                this.update(this);
                $result.resolve(data);
            });
            return $result;
        }

        save(): JQueryDeferred<any> {
            var $result = $.Deferred();
            Que.Forms.APIs.WYSIWYG_Repo.saveNotification(this).done((_notificationID) => {
                this.NotificationID = _notificationID;
                $result.resolve(_notificationID);
            });
            return $result;
        }

        delete(): JQueryDeferred<any> {
            var $result = $.Deferred();
            Que.Forms.APIs.WYSIWYG_Repo.deleteNotification(this.NotificationID).done(() => {
                $result.resolve(true);
            });
            return $result;
        }

    }

}