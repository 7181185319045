namespace mW.enums {

    export enum DataType {

        STRING = 1,
        DATETIME = 2,
        DECIMAL = 3,
        BIT = 4,
        INTEGER = 5

    }

    export class enums_helpers {

        static convert_enum_to_array(enums: any): any[] {            
            var obj = Object.keys(enums).map(k => enums[k]), enumArry: any[] = [];                        
            if (enums != null) {                
            // each arry length should be identical after filter, if not your enum is jacked or not the type of enum this method expects
                let stringArry = obj.filter(v => typeof v === "string") as string[];
                let numArry = obj.filter(v => typeof v === "number") as number[];

                stringArry.forEach((s) => {
                    enumArry.push({ EnumName: s, EnumValue: null });
                });

                numArry.forEach((n, index) => {
                    enumArry.forEach((_enum, _EnumIndex, _self) => {
                        if (_EnumIndex == index) {
                            _enum['EnumValue'] = n;
                        }
                    });
                });
                                
            } else {
                console.warn('mW.enums.enums_helper.convert_enum_to_array - you did not provide an enum to convert.');
            }
           return enumArry;
        }

    }

}