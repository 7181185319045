namespace Shared.Models {

    export class ClientIcons {

        public static get(): JQueryDeferred<any> {
            var $result = $.Deferred();
            Shared.APIs.ClientIcons.get().done((icons) => {
                $result.resolve(icons);
            });
            return $result;
        }

    }

}