namespace CRM.ViewModels {

    // Admin Tool

    export class edit_Grid_Client_Types {

        client_type_grid: GUI.Tables.DTables = new GUI.Tables.DTables;
        client_icons: string[];
        client_icons_as_html_select_options: string;

        init() {
            this.get().done(() => {       
                this.get_icons().done(() => {                    
                    this.bind_grid();
                    this.events();                                
                });                         
            });                        
        }

        events() {

            var $clientTypeDataTableContainer = $('#clientTypeDataTableContainer');
            
            $clientTypeDataTableContainer.on('keyup', '.client_type_input', (e) => {
                var $parent = $(e.target).parent().parent().parent();
                var val = $(e.target).val();
                var clientTypeName = $parent.find('.client_type_input').data('originalclienttypename');
                this.update(clientTypeName, 'ClientTypeName', val, false, $parent);
            });

            $clientTypeDataTableContainer.on('change', '.client_type_checkbox', (e) => {
                var $parent = $(e.target).parent().parent().parent();
                var clientTypeName = $parent.find('.client_type_input').data('originalclienttypename'),
                    accessType = $(e.target).data('accesstype');
                this.update(clientTypeName, accessType, $(e.target).is(':checked'), true, $parent);
            });            
            
            $clientTypeDataTableContainer.on('change', '.select_clienttype_icon', (e) => {

                var selectedIcon = $(e.target).val();
                $(e.target).parent().find('.icon_preview_container').html(this.icon_preview(selectedIcon));
                var $parent = $(e.target).parent().parent().parent();
                var clientTypeName = $parent.find('.client_type_input').data('originalclienttypename');
                this.update(clientTypeName, 'IconImage', $(e.target).val(), false, $parent);

            });

            $clientTypeDataTableContainer.on('click', '.save_clienttype', (e) => {
                var originalClientTypeName = $(e.target).data('originalclienttypename');
                var $parent = $(e.target).parent().parent().parent();
                $(e.target).text('Saving...');
                this.save(originalClientTypeName).done((originalClientTypeName) => {
                    $(e.target).text(' Save ');
                    //if (propName == 'ClientTypeName') {
                    
                    $parent.closest('.client_type_input').data('originalclienttypename', originalClientTypeName);
                    $parent.closest('.save_clienttype').data('originalclienttypename', originalClientTypeName);
                    $parent.closest('.delete_clienttype').data('originalclienttypename', originalClientTypeName);
                    //}
                });
            });

            $clientTypeDataTableContainer.on('click', '.delete_clienttype', (e) => {
                var clientTypeName = $(e.target).data('originalclienttypename');
                $(e.target).text('Deleting...');
                this.delete(clientTypeName);
            });

        }

        get(): JQueryDeferred<any> {
            var $result = $.Deferred();
            CRM.Models.ClientTypes.get().done((clientTypes) => {
                this.client_type_grid.data = clientTypes;
                $result.resolve(true);
            });
            return $result;
        }

        get_icons() {
            var $result = $.Deferred();
            Shared.Models.ClientIcons.get().done((icons) => {
                this.client_icons = icons;
                this.client_icons.unshift('');
                $result.resolve(true);
            });
            return $result;
        }

        save(originalClientTypeName: string): JQueryDeferred<any> {
            var $result = $.Deferred();
            var _clientTypes: CRM.Models.ClientTypes[] = mW.io.reconstruct_object(this.client_type_grid.data);

            _clientTypes.forEach((_clientType, index) => {
                if (_clientType.OriginalClientTypeName == originalClientTypeName) {
                    var _c = new CRM.Models.ClientTypes(_clientType);
                    _c.save().done(() => {
                        _c.OriginalClientTypeName = _c.ClientTypeName;
                        this.flatten(_c, originalClientTypeName);                               
                        $result.resolve(_c.ClientTypeName);
                    });
                }
            });

            return $result;
        }

        delete(clientTypeName: string): JQueryDeferred<any> {
            var $result = $.Deferred();
            new CRM.Models.ClientTypes({ OriginalClientTypeName: clientTypeName }).delete().done(() => {
                //$(e.target).text(' Delete ');
                this.client_type_grid.data.Values.forEach((_clientType, index) => {

                    if (_clientType[0] == clientTypeName) {

                        this.client_type_grid.data.Values.splice(index, 1);
                        this.client_type_grid.refreshTable();

                    }

                });
            });
            return $result;
        }

        update(originalClientTypeName: string, propName: string, value: any, isCheckbox: boolean, $parent: JQuery) {
            var _clientTypes: CRM.Models.ClientTypes[] = mW.io.reconstruct_object(this.client_type_grid.data);

            _clientTypes.forEach((_clientType, index) => {

                if (_clientType.OriginalClientTypeName == originalClientTypeName) {
                    var _c = new CRM.Models.ClientTypes(_clientType);
                    if (isCheckbox) {
                        _c[propName] = value == true ? 'Y' : 'N';
                    } else {
                        _c[propName] = value;

                    }
                    
                    this.flatten(_c);

                }

            });            

            this.detect_changes($parent);

        }

        flatten(clientType: CRM.Models.ClientTypes, previousOriginalClientTypeName?: string) {

            this.client_type_grid.data.Values.forEach((_flatClientType, _index) => {
				var flatClientType: string[] = <any>_flatClientType;
                if (previousOriginalClientTypeName != null) {                
                    if (flatClientType[5] == previousOriginalClientTypeName) {
                        flatClientType[0] = clientType.ClientTypeName;
                        flatClientType[1] = clientType.MemberAccess;
                        flatClientType[2] = clientType.BrokerAccess;
                        flatClientType[3] = clientType.PartnerAccess;
                        flatClientType[4] = clientType.IconImage;
                        flatClientType[5] = clientType.OriginalClientTypeName;
                    }
                } else {
                    if (clientType.OriginalClientTypeName == _flatClientType[5]) {
                        flatClientType[0] = clientType.ClientTypeName;
                        flatClientType[1] = clientType.MemberAccess;
                        flatClientType[2] = clientType.BrokerAccess;
                        flatClientType[3] = clientType.PartnerAccess;
                        flatClientType[4] = clientType.IconImage;                        
                    }
                }
                

            });                        
        }

        build_icon_select_output(savedIcon: string): string {
            var html = [];            
            var isSelected = '';
            html.push('<select data-clienttypeicon="' + savedIcon + '" class="select_clienttype_icon">');
            this.client_icons.forEach((icon, index) => {
                if (icon.toLowerCase() == savedIcon.toLowerCase()) {
                    isSelected = ' selected';
                } else {
                    isSelected = '';
                }
                html.push('<option value="' + icon.toLowerCase() + '" ' + isSelected + '>' + icon.toLowerCase() + '</option>');
            });            
            html.push('</select>');
            return html.join('');
        }

        icon_preview(savedIcon) {
            var HTML = [];            
            if (savedIcon) {
                HTML.push('<img class="clienttype_icon_preview" src = "https://' + window.location.host + '/images/ClientIcons/' + savedIcon + '" />');
            } else {
                HTML.push('<div class="empty_icon_preview_placeholder"></div>');
            }            
            return HTML.join('');
        }        

        clienttypename_textbox() {
            this.client_type_grid.dtObject.colDefCollection.push({
                'targets': 0,
                'searchable': false,
                'orderable': false,
                'className': 'dt-body-center',
                'render': (data, type, full, meta) => {
                    return '<div class="input_container"><input type="text" class="client_type_input" data-originalclienttypename="' + full[5] + '" value="'
                        + $('<div/>').text(data).html() + '"' + this.required_client_types(data) + '></div>';
                }
            });
        }

        member_checkbox() {
            this.add_checkbox(1, 'MemberAccess');
        }

        broker_checkbox() {
            this.add_checkbox(2, 'BrokerAccess');
        }

        producer_checkbox() {
            this.add_checkbox(3, 'PartnerAccess');
        }

        iconimage_select() {
            this.client_type_grid.dtObject.colDefCollection.push({
                'targets': 4,
                'searchable': false,
                'orderable': false,
                'className': 'dt-body-center',
                'render': (data, type, full, meta) => {
                    return '<div class="select_container"><div class="icon_preview_container">' + this.icon_preview(data) + '</div>' + this.build_icon_select_output(data) + '</div>';
                }
            });
        }

        edit_button() {
            var _self = this;
            var cdt: GUI.Tables.DTables = new GUI.Tables.DTables;

            if (this.client_type_grid.data) {
                var Column = new GUI.Smart.SmartColumnObject();
                Column.data = null;
                Column.orderable = false;
                Column.class = 'details-control';
                Column.defaultContent = '<button type="button" class="save_clienttype"> Save </button> <button type="button" class="delete_clienttype"> Delete </button><div class="loading-icon"></div>';
                Column.render = (data, type, row) => {
                    return '<button type="button" class="save_clienttype" data-originalclienttypename="' + data[5] + '"> Save </button> <button type="button" class="delete_clienttype" data-originalclienttypename="' + data[5] + '"' +   this.required_client_types(data[0]) + '> Delete </button><div class="loading-icon"></div>';
                }
                this.client_type_grid.data.Columns.push(Column);
            }
        }

        add_checkbox(target: number, accessType: string) {
            
            this.client_type_grid.dtObject.colDefCollection.push({
                'targets': target,
                'searchable': false,
                'orderable': false,
                'className': 'dt-body-center',
                'render': (data, type, full, meta) => {                                                            
                    return '<div class="checkbox_container"><input type="checkbox" class="client_type_checkbox" data-originalclienttypename="' + full[5] + '" data-accesstype="' + accessType + '" value="'
                        + $('<div/>').text(data).html() + '"' + this.check_checkbox(data) + '><div class="loading-icon"></div></div>';
                }
            });

        }

        check_checkbox(val: string): string {
            var isChecked = '';
            if (val.toLocaleLowerCase() == 'y') {
                isChecked = 'checked';
            }
            return isChecked;
        }

        required_client_types(clientTypeName: string): string {
            var isDisabled = '',
            // this list of client types are to not be allowed to have their names changed without a greater discussion.
            requiredClientTypes = ['Board', 'Client', 'Management', 'Prospect', 'Vendor'].forEach((_clientTypeName, index) => {

                if (_clientTypeName == clientTypeName) {

                    isDisabled = 'disabled title="This Client Type Name field cannot be edited."';

                }

            });

            return isDisabled;

        }

        detect_changes($parent: JQuery) {            
            $parent.find('.save_clienttype').text('Unsaved');
        }

        bind_grid(): JQueryDeferred<any> {
            var $result = $.Deferred();
            this.client_type_grid.dtObject.displayLength = 25;
            this.client_type_grid.containerName = 'clientTypeDataTableContainer';
            this.client_type_grid.tableName = 'clientTypeDataTableGrid';
            this.client_type_grid.dtObject.colDefCollection = [];
            this.clienttypename_textbox();
            this.member_checkbox();
            this.broker_checkbox();
            this.producer_checkbox();
            this.iconimage_select();
            this.edit_button();            
            this.client_type_grid.buildTable();
            return $result.resolve(true);
        }

    }

}