
namespace Que.Forms.ViewModels {

    export class edit_Questionnaire_Notification_Editor {

        ReferenceNotification: Que.Forms.Models.FormNotification;
        CurrentNotification: Que.Forms.Models.FormNotification;
        Notifications: Que.Forms.Models.FormNotification[];
        Schemas: Que.ResultSets.Models.Schema[];
        Forms: Que.Forms.Models.Form[];
        Contacts: Que.Forms.Models.ContactType[];
        EmailTemplates: IO.Models.EmailTemplate[];
        hasChanged: boolean;
        parentRefresh: () => void;

        constructor() {
            this.ReferenceNotification = new Que.Forms.Models.FormNotification({});
            this.CurrentNotification = new Que.Forms.Models.FormNotification({});
            this.Notifications = new Array<Que.Forms.Models.FormNotification>();
            this.Contacts = new Array<Que.Forms.Models.ContactType>();
            this.Schemas = new Array<Que.ResultSets.Models.Schema>();
            this.Forms = new Array<Que.Forms.Models.Form>();
            this.EmailTemplates = new Array<IO.Models.EmailTemplate>();
            this.hasChanged = false;
        }

        init(notifications: Que.Forms.Models.FormNotification[], forms: Que.Forms.Models.Form[], parentRefresh: () => void): void {
            this.parentRefresh = parentRefresh;     
            this.prepare_data(notifications, forms).done(() => {
                this.setup();
                this.events();                        
            });            
        }

        prepare_data(...data: any[]): JQueryDeferred<any> {

            var $result = $.Deferred();
            new IO.Models.EmailTemplate({ TemplateID: null }).get().done((templates) => {
                this.EmailTemplates = templates;
                new Que.Forms.Models.ContactType({ ContactTypeID: null }).get().done((contacts) => {
                    this.Contacts = contacts;
                    // not sure how we set the schemaid so for now, we're just setting it to 2 :\
                    new Que.ResultSets.Models.Schema({ SchemaID: 2 }).get(true).done((schemas) => {
                        schemas = mW.io.reconstruct_object(schemas);
                        schemas.forEach((schema) => {
                            // we need to remove all from this list                
                            if (schema.Status != 'All') {
                                this.Schemas.push(new Que.ResultSets.Models.Schema(schema));
                            }                            
                        });

                        data[0] = mW.io.reconstruct_object(data[0]);
                        data[0].forEach((notification) => {
                            this.Notifications.push(new Que.Forms.Models.FormNotification(notification));
                        });

                        data[1] = mW.io.reconstruct_object(data[1]);
                        data[1].forEach((form) => {
                            this.Forms.push(new Que.Forms.Models.Form(form));
                        });

                        $result.resolve(true);

                    });
                });            
            });            

            return $result;
        }

        prepare_inline_container(notification: Que.Forms.Models.FormNotification): JQueryDeferred<any> {
            var $result = $.Deferred();
            this.handlebars_helpers();            
            this.CurrentNotification.update(notification);
                $result.resolve(mW.handlebars.html(CHSITemplates.AdminTools_Questionnaire_Notification_Editor, { Notification: this.CurrentNotification }));                            
            return $result;
        }

        setup(): void {
            this.bind_ckeditor().done(() => {
                $(".date").datepicker({ showOn: "both", buttonImage: "/images/icons/cal.gif", buttonImageOnly: true });
                mW.ui.build_select('#notification_copy', this.Notifications, 'NotificationID', 'NotificationName', 'Not Notifications Found', this.CurrentNotification.NotificationID);
                mW.ui.build_select('#notification_form', this.Forms, 'FormID', 'Title', 'No Forms Found', this.CurrentNotification.FormID);
                mW.ui.build_select('#notification_contact_type', this.Contacts, 'ContactType', 'ContactType', 'No Contacts Found', this.CurrentNotification.NotificationContactType);
                mW.ui.build_select('#notification_contact_type_cc', this.Contacts, 'ContactType', 'ContactType', 'No Contacts Found', this.CurrentNotification.NotificationContactTypeCC);                
                mW.ui.build_select('#notification_status_trigger', this.Schemas, 'Status', 'Status', 'No Status Found', this.CurrentNotification.NewStatusTrigger);
                mW.ui.build_select('#notification_email_template', this.EmailTemplates, 'TemplateID', 'TemplateName', 'No Templates Found', this.CurrentNotification.EmailTemplateID);
                this.show_editor();                                    
            });            
            
        }

        show_editor(): JQueryDeferred<any> {
            var $result = $.Deferred();            
            $('#loading_notification_editor').fadeOut('fast', () => {
                $('#notification_editor').fadeIn('fast', () => {                
                    $result.resolve(true);
                });
            });            
            return $result;
        }

        events(): void {

            $('#notification_name').on('keyup', (e) => {
                var value = $(e.target).val();
                this.update('NotificationName', value);
            });

            $('#notification_recipient').on('change', (e) => {
                var value = $(e.target).is(':checked');
                this.update('AddUserAsRecipient', value);
            });

            $('#notification_form').on('change', (e) => {
                var value = $(e.target).val();
                this.update('FormID', value);
            });

            $('#notification_email_template').on('change', (e) => {
                var value = $(e.target).val();
                this.update('EmailTemplateID', value);
            });

            $('#notification_status_trigger').on('change', (e) => {
                var value = $(e.target).val();
                this.update('NewStatusTrigger', value);
            });

            $('#notification_active_date').on('change', (e) => {
                var value = $(e.target).val();
                this.update('ActiveDate', value);
            });

            $('#notification_inactive_date').on('change', (e) => {
                var value = $(e.target).val();
                this.update('InActiveDate', value);
            });

            $('#notification_include_report').on('change', (e) => {
                var value = $(e.target).is(':checked');
                this.update('IncludeReport', value);
            });

            $('#notification_contact_type').on('change', (e) => {
                var value = $(e.target).val();
                this.update('NotificationContactType', value);
            });

            $('#notification_contact_type_cc').on('change', (e) => {
                var value = $(e.target).val();
                this.update('NotificationContactTypeCC', value);
            });

            $('#notification_cc').on('keyup', (e) => {
                var value = $(e.target).val();
                this.update('NotificationCC', value);
            });

            $('#notification_bcc').on('keyup', (e) => {
                var value = $(e.target).val();
                this.update('NotificationBCC', value);
            });

            $('#notification_subject').on('keyup', (e) => {
                var value = $(e.target).val();
                this.update('Subject', value);
            });

            $('#notification_copy').on('change', (e) => {           
                if ($(e.target).val() != '-1') {
                    $('#copy_from_notification').prop('disabled', false);
                } else {
                    $('#copy_from_notification').prop('disabled', true);
                }                
            });

            $('#copy_from_notification').on('click', (e) => {
                var editor = CKEDITOR.instances['html_editor'];
                var notification = this.get($('#notification_copy').val());
                    this.update('Body', notification.Body);
                    editor.setData(this.CurrentNotification.Body);
                
            });

            $('#notification_save').on('click', (e) => {
                this.save();
            });

            $('#notification_cancel').on('click', (e) => {                
                this.close();
            });
        }      

        close(): void {
            if (this.hasChanged || this.has_body_changed()) {
                var c = confirm('Any unsaved work will be lost if closed. Click cancel to continue to work.');
                if (c) {
                    GUI.Windows.Popup.closeThisInline('#modal_questionnairenotificationeditor');
                    $('#modal_questionnairenotificationeditor').remove();
                    this.parentRefresh();
                }
            } else {
                if (this.validate()) {
                    GUI.Windows.Popup.closeThisInline('#modal_questionnairenotificationeditor');
                    $('#modal_questionnairenotificationeditor').remove();
                    this.parentRefresh();
                } else {
                    var c = confirm('Any unsaved work will be lost if closed. Click cancel to continue to work.');
                    if (c) {
                        GUI.Windows.Popup.closeThisInline('#modal_questionnairenotificationeditor');
                        $('#modal_questionnairenotificationeditor').remove();
                        this.parentRefresh();
                    }
                }
            }            
        }

        get_body_content(): string {
            var editor = CKEDITOR.instances['html_editor'], bodyContent = editor.getData();
            return bodyContent;
        }

        validate(): boolean {
            let isValid = false, fieldsToValidate: any[] = [];

            $('#notification_message').text('');

            $('.required_validation').each((i, e) => {
                let value = $(e).val(), isValid = false;
                isValid = (value != null && value != undefined && value != '' && value != '-1') ? true : false;    
                isValid == false ? $(e).addClass('validation-error') : $(e).removeClass('validation-error');                                
                fieldsToValidate.push(isValid);
            });      

            var editorValue = this.get_body_content(), isEditorValueValid = (editorValue != null && editorValue != undefined && editorValue != '') ? true : false

            if (!isEditorValueValid) {
                $('#notification_message').show();
                $('#notification_message').text(' ' + $('#html_editor').data('errormessage')).css('color', 'red');
                $('#cke_html_editor').addClass('validation-error');
            } else {
                $('#cke_html_editor').removeClass('validation-error');
            }

            fieldsToValidate.push(isEditorValueValid);

            isValid = fieldsToValidate.every(validField => validField == true);

            if (!isValid) {           
                $('#notification_message').show();     
                $('#notification_message').text($('#notification_message').text() + ' Please correct any fields marked in red.').css('color', 'red');
            }

            $('#notification_message').delay(5000).fadeOut('fast');

            return isValid;
        }

        bind_ckeditor(): JQueryDeferred<any> {
            var $result = $.Deferred();
            CKEDITOR.replace('html_editor', { height: "230" });
            CKEDITOR.instances['html_editor'].setData(this.ReferenceNotification.Body);            
            $result.resolve(true);
            return $result;
        }        

        update(propName: string, value: any): void {            
            mW.object_types.update_property(this.CurrentNotification, propName, value);
            this.hasChanged = true;
        }        

        get(notificationID: number): Que.Forms.Models.FormNotification {
            var notificationRef: Que.Forms.Models.FormNotification;
            this.Notifications.forEach((notification) => {
                if (notification.NotificationID == notificationID) {
                    notificationRef = notification;
                }
            });            
            return notificationRef;
        }

        save(): void {            
            $('#notification_save, #notification_cancel').prop('disabled', true);
            $('#notification_message').show('');
            $('#notification_message').text('Saving...').css('color', 'green');
            if (this.validate()) {                               
                this.CurrentNotification.update({ Body: this.get_body_content() }); 
                this.CurrentNotification.save().done(() => {
                    this.hasChanged = false;                    
                    $('#notification_message').text('Saved.').css('color', 'green');
                }).fail((err) => {
                    console.error(err);
                    $('#notification_message').text('Something went wrong. View log.').css('color', 'red');
                }).always(() => {
                    $('#notification_message').delay(5000).fadeOut('fast', () => {
                        $('#notification_message').text('').css('color', 'black');
                    });
                    $('#notification_save, #notification_cancel').prop('disabled', false);
                });
            } else {
                $('#notification_save, #notification_cancel').prop('disabled', false);
            }
        }

        has_body_changed(): boolean {
            var hasChanged = false;
            if (this.CurrentNotification.Body != this.get_body_content()) {
                hasChanged = true;
            }
            return hasChanged;
        }

        handlebars_helpers(): void {

            Handlebars.registerHelper('MarkAsChecked', function (boolValue) {
                var isChecked = null;
                if (boolValue == 'True') {
                    boolValue = true;
                } else {
                    boolValue = false;
                }
                if (boolValue) {
                    isChecked = 'checked="checked"';
                } else {
                    isChecked = '';
                }
                return isChecked;
            });                             

        }

    }

}