namespace Que.Forms.APIs {

    export class FormRepo {

        private static _routePrefix = '/api/Que/Form/';

        static getForm(formID: number): JQueryDeferred<any> {
            var $result = $.Deferred();
            if (formID == null) {
                formID = 0;
            }
            $.get(this._routePrefix + 'GetForms?FormID=' + formID).done((form) => {
                if (form.Values.length == 1) {
                    form = mW.io.reconstruct_object(form)[0];
                }
                $result.resolve(form);
            });
            return $result;
        }

    }

}