namespace mW {    

    export class observable {

        private _observableWatchList: _observable[];
        private _originalObservableObject: any;
        private _originalObservableOrder: IObservableRequirements[];
        private _isArray: boolean;
        private _hasOrder: boolean;
        private _listenLoop: any;
        private _parent: HTMLElement = document.getElementById('observable_container');

        constructor(observableOrder: IObservableRequirements[]);
        constructor(observableObject: any, observableOrder?: IObservableRequirements[]) {

            if (document.getElementById('observable_container') != null) {
                this._originalObservableObject = observableObject;
                this._originalObservableOrder = observableOrder;

                this._observableWatchList = new Array<_observable>();

                this.configureObservable();

                this.bind();

                this.listenLoop();
            } else {
                alert('Add a parent container div with the id of "observable_container".');
            }
            

        }

        watch(obreq: IObservableRequirements): observable {

            this._observableWatchList.push(new _observable(obreq));

            obreq = null;

            return this;

        }

        updateWatchList(observableOrder: IObservableRequirements[]): observable {

            observableOrder.forEach((observable, index) => {

                this._observableWatchList.forEach((_observable, _index) => {

                    if (observable.Index != null) {
                        if (observable.Index == _observable.Index) {
                            _observable.Id = observable.Id == null ? _observable.Id : observable.Id;
                            _observable.Class = observable.Class == null ? _observable.Class : observable.Class;
                            _observable.Value = observable.Value == null ? _observable.Value : observable.Value;
                            _observable.Index = observable.Index == null ? _observable.Index : observable.Index;
                            _observable.PropertyName = observable.PropertyName == null ? _observable.PropertyName : observable.PropertyName;
                            _observable.configureObservable();
                            
                        }
                    } else {
                        console.error('Cannot find index when attempting to update observable.');
                    }
                    
                });

            });

            this.bind();

            return this;
        }

        get(propertyName: string): _observable;
        get(index: number): _observable;
        get(pointer: string | number): _observable {

            var _observable: _observable,
                _pointer: string|number,
                list = this._observableWatchList,
                isPropertyName: boolean = false;

            if (typeof pointer === 'string') {
                isPropertyName = true;
            }

            for (var i = 0; i < list.length; i++) {

                _pointer = isPropertyName == true ? list[i].PropertyName : list[i].Index;
                
                if (_pointer == pointer) {
                    _observable = list[i];
                    break;
                }

            }

            return _observable;
        }

        private configureObservable(): void {

            this._isArray = Object.prototype.toString.call(this._originalObservableObject) === '[object Array]' ? true : false;
            this._hasOrder = this._originalObservableOrder != undefined ? true : false;

            if (!this._isArray) {
                if (this._originalObservableObject == undefined) {
                    alert('No observable object found.');
                } else {
                    this.processObservableObject();
                }
            } else {
                throw new Error('Array support is not yet implemented.');
            }

            if (this._hasOrder) {

                this.updateObservableObject();

            }

        }

        private bind(): void {
        // update this later to support classes
            this._observableWatchList.forEach((observable) => {
                if (observable.$id != null) {
                    observable.$id.value = observable.Value;
                }
            });
        }

        private processObservableObject(): void {

            var index = 0;

            for (var propertyName in this._originalObservableObject) {

                this._observableWatchList.push(
                    new _observable(
                        {
                            Index: index,
                            PropertyName: propertyName,
                            Value: this._originalObservableObject[propertyName],
                            Id: null,
                            Class: null

                        }
                    )
                );

                index++;

            }

        }

        private updateObservableObject(): void {

            var order = this._originalObservableOrder;

            this._observableWatchList.forEach((observable, observableIndex) => {

                order.forEach((order, orderIndex) => {

                    if (observableIndex == order.Index) {

                        observable.Index = order.Index;
                        observable.Id = order.Id;
                        observable.Class = order.Class;
                        observable.attachListeners();
                        
                    }

                });

            });

        }

        private listenLoop(): void {

            var timer = 100;

            this._listenLoop = setInterval(() => {

            // number of external loops outside the listenLoop - 1 - we track this number for performance reasons, update if more loops outside listenLoop is added. (modify if you see an oppertunity for performance enhancement

                this._observableWatchList.forEach((observable, oberservableIndex) => {

                    // do some logic to check if the cached value has changed

                    // reasons why it could change:

                    /****
                        * data refresh from web API (two-way-bind MUST stay connected without breaking the contract of the UI and the business logic)
                        * programmatically updated value outside the two-way-bind
                        ****/

                        // first we compare the originalValue to value. if different, change has occured. since originalValue is private, and is only set at the start of the observable process, it cannot be changed other than passing the observable constructor a new object and or by breaking the two-way-binding contract

                    observable.compareHistory();

                });

            }, timer);

        }

        markAsUnchangedAll(): void {
            this._observableWatchList.forEach((observable) => { observable.markAsUnchanged(); });
        }

        clearAllObservableValues(): void {
            this._observableWatchList.forEach((observable) => { observable.clearObservableValue(); });
        }

        startListenLoop(): void {
            // you don't need to start the listenLoop, we start it in the constructor. this method is conjunction with stopListenLoop
            this.listenLoop();
        }

        stopListenLoop(): void {
            clearInterval(this._listenLoop);
        }

        delegateEvents(): void {

            document.getElementById('').addEventListener('keyup', function (e) {
                
            });

        }

    }

    export interface IObservableRequirements {

        Id?: string;
        Class?: string;
        Index?: number;
        Value?: any;
        PropertyName?: string;

    }

    class _observable {

        OId: number;
        Id: string;
        Class: string;
        Index: number;
        Value: any;
        PropertyName: string;
        $id: HTMLInputElement;
        $class: NodeListOf<Element>;        
        
        private _isId: boolean;
        private _isClass: boolean;
        private _hasChanged: boolean;
        private _hasValue: boolean;
        private _originalValue: any;
        private _typeOfElement: string;
        private _$OClass: NodeListOf<Element>;

        constructor(obreq: IObservableRequirements) {

            this.Id = obreq.Id;
            this.Class = obreq.Class;
            this.Index = obreq.Index;
            this.Value = obreq.Value;
            this.PropertyName = obreq.PropertyName;
            this._originalValue = obreq.Value;

            this.configureObservable();

        }

        attachListeners(): void {

            if (this._isId) {
            // we need to add support for onchange (selects) aswell, and consider blur/focus
                this.$id.addEventListener('keyup', this.updateObjectWithElementValue);
            } else {
                throw new Error('Event listeners for class support not yet implemented.');
            }

        }

        configureObservable(): void {

            this._isId = this.Id != undefined ? true : false;
            this._isClass = this.Class != undefined ? true : false; 
            this._hasValue = (this.Value != null && this.Value != undefined) ? true : false;

            this.$id = this._isId == true ? (<HTMLInputElement>document.getElementById(this.Id)) : null;

            this.$class = this._isClass == true ? document.getElementsByClassName(this.Class) : null;

            if (this.PropertyName != null && this.PropertyName != '') {
                this._$OClass = document.getElementsByClassName(this.PropertyName.toLowerCase().replace(' ', '_'));
                for (var i = 0; i < this._$OClass.length; i++) {
                    this._$OClass.item(i).addEventListener('keyup', this.updateObjectWithElementValue);
                }
            } else {
                this._$OClass = null;
            }

        }

        private updateElementWithObjectValue(): void {
            
        }

        private updateObjectWithElementValue(): void {
        
            if (this._isId) {
                this.$id.value = this.Value;
                this._hasChanged = true;
            } else {
                throw new Error('Update element value class support not yet implemented.');
            }

        }

        compareHistory(): boolean {

            this._hasChanged = this.Value != this._originalValue ? true : false;

            return this._hasChanged;

        }

        markAsUnchanged(): void {
            this._hasChanged = false;
        }

        clearObservableValue(): void {
            this.Value = null;
        }

        updateOtherOutlets(): void {

        }
    }

    class _observableSmartElement {
        // knows if elements are input, select, div, span etc...
        private _observable: _observable;

        constructor(observable: _observable) {
            this._observable = observable;
        }

        getElementType(): void {

            var tag = this._observable.$id.tagName.toLowerCase();

            switch (tag) {

                case 'input':
                    break;
                case 'div':
                    break;
                case 'span':
                    break;
                case 'textarea':
                    break;
                case 'select':
                    break;
                default:


            }

        }
    }

}