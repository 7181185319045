namespace mW {

    export class dtable {

        private _$table: JQuery;
        private _containerName: string;

        $grid: DataTables.DataTable;
        $container: JQuery;
        settings: DataTables.Settings;

        constructor(containerName: string, data: PL.Common.Models.SmartGrid) {

            if (data.Columns == null && data.Values == null) {
                alert('please send a proper object to dtable');
            } else {
                this.settings.columns = data.Columns;
                this.settings.data = data.Values
            }
            
            this._containerName = containerName;
            this.$container = $('#' + containerName);
            this.$container.html('<table class="dtable" id="' + this._containerName + '_datatable"><tfoot></tfoot></table>');
            this._$table = $('#' + this._containerName + '_datatable');
            
            return this;
        }

        buildTable(): dtable {
            this.$grid = this._$table.DataTable(this.settings);
            return this;
        }

        refresh(): void {

        }
    }

}