namespace Que.Forms.Models {

    export class Element {

        ElementID: number;
        ParentID: number;
        DataStructureColumnID: number;
        FormSectionID: number;
        ElementType: number;
        TemplateRegionIndex: number;
        Status: string;
        Label: string;
        LabelTemplate: string;
        RequiresReview: boolean;
        BlockType: number;
        Visibility: number;
        SortIndex: number;
        IsRequired: boolean;
        AllowNA: boolean;
        AllowHTML: boolean;
        PrevAllowNA: boolean;
        DataStructure: Que.DataStructures.Models.DataStructureColumn;
        Tag: string;
        Data: any;
        ControlTypeName: string;        
        MetaAttributes: Que.Forms.Models.MetaAttribute[];
        Help: Que.Forms.Models.ElementHelpDetails;
        CreateUser: string;
        CreateTmsp: string | Date;
        UpdateUser: string;
        UpdateTmsp: string | Date;
        // dynformobject
        DefaultVisibilty: number;

        // semi-private
        _propertyHasChanged: boolean;
        _metaHasChanged: boolean;
        _sortHasChanged: boolean;
        _clone: Element;

        constructor(element?: Interfaces.IElement) {
            if (element == (null || undefined)) element = {};
            this.ElementID = element == undefined ? null : element.ElementID;
            this.ParentID = element == undefined ? null : element.ParentID;
            this.DataStructureColumnID = element == undefined ? null : element.DataStructureColumnID;
            this.FormSectionID = element == undefined ? null : element.FormSectionID;
            this.ElementType = element == undefined ? null : element.ElementType;
            this.TemplateRegionIndex = element == undefined ? null : element.TemplateRegionIndex;
            this.Status = element == undefined ? null : element.Status;
            this.Label = element == undefined ? null : element.Label;
            this.LabelTemplate = element == undefined ? null : element.LabelTemplate;
            this.RequiresReview = element == undefined ? null : element.RequiresReview;
            this.BlockType = element == undefined ? null : element.BlockType;
            this.Visibility = element == undefined ? null : element.Visibility;
            this.SortIndex = element == undefined ? null : element.SortIndex;
            this.IsRequired = element == undefined ? null : element.IsRequired;
            this.AllowNA = element == undefined ? null : element.AllowNA;
            this.AllowHTML = element == undefined ? null : element.AllowHTML;
            this.PrevAllowNA = element == undefined ? null : element.PrevAllowNA;
            if (element != undefined && element.DataStructure != undefined) {
                this.DataStructure = new Que.DataStructures.Models.DataStructureColumn(element.DataStructure);
            } else {
                this.DataStructure = new Que.DataStructures.Models.DataStructureColumn;
            }
            this.Tag = element == undefined ? null : element.Tag;
            this.Data = element == undefined ? null : element.Data;
            this.ControlTypeName = element == undefined ? null : element.ControlTypeName;            
            this.MetaAttributes = new Array<Que.Forms.Models.MetaAttribute>();
            if (element != undefined && element.MetaAttributes != undefined) {
                element.MetaAttributes.forEach((metaO) => {
                    this.MetaAttributes.push(new Que.Forms.Models.MetaAttribute(metaO));
                });
            }
            if (element != (null || undefined) && element.Help != (null || undefined)) {
                this.Help = new Que.Forms.Models.ElementHelpDetails(element.Help);
            } else {
                this.Help = new Que.Forms.Models.ElementHelpDetails;
            }
            
            this.CreateUser = element == undefined ? null : element.CreateUser;
            this.CreateTmsp = element == undefined ? null : element.CreateTmsp;
            this.UpdateUser = element == undefined ? null : element.UpdateUser;
            this.UpdateTmsp = element == undefined ? null : element.UpdateTmsp;

            this._propertyHasChanged = false;
            this._metaHasChanged = false;
            this._sortHasChanged = false;
            this._clone = null;
        }

        save(): JQueryDeferred<number> {
            var $result = $.Deferred();
            Que.Forms.APIs.WYSIWYG_Repo.saveElement(this).done((elementID) => {
                $result.resolve(elementID);
            });            
            return $result;
        }

        get(): JQueryDeferred<Element> {
            var $result = $.Deferred();
            //mW.classes.propagatePropertyValues(element, this);
            return $result;
        }

        delete(): JQueryDeferred<number> {
            var $result = $.Deferred();
            this.Status = 'Deleted';
            if (this.ElementID > 0) {
                Que.Forms.APIs.WYSIWYG_Repo.saveElement(this).done((elementID) => {
                    $result.resolve(elementID);
                });
            } else {
                $result.resolve(true);
            }
            return $result;
        }

    }

}