namespace BI.Models {

    export class PBI_Widget_Element {

    }

    export class PBI_Widget_HTML  {

    }

    export class PBI {


    }

}