namespace CRM.Base {

    export class Location_Base_Data {
        Location: Models.KO_Location;
        Client: Models.Client;
        PropertyScheduleTypes: Models.PropertyScheduleTypes[];
        States: Shared.Models.States[];
        Demographics: GUI.Smart.SmartObject;
        Contacts: CRM.Models.Contact[];

        constructor(locationID?: number, clientID?: number) {
            this.Location = new Models.KO_Location;
            this.Location.write({ LocationID: locationID, ClientID: clientID, LocationNumber: null, StartDate: null, TerminationDate: null, /*PhoneAC: null, FaxAC: null,*/ Phone: null, Fax: null, PhoneExt: null, LocationDBA: null, DBA: null, Description: null, ScheduleID: null, Status: null, Address1: null, Address2: null, City: null, State: null, Zip4: null, Zip: null, Directions: null, Comments: null, CreateUser: null, UpdateUser: null, CreateTmsp: null, UpdateTmsp: null });
            this.Client = new Models.Client({ ClientID: clientID });
            this.PropertyScheduleTypes = new Array<Models.PropertyScheduleTypes>();
            this.States = new Array<Shared.Models.States>();
        }

        getData(): JQueryDeferred<any> {
            var $result = $.Deferred();
            if (this.Location.LocationID() == null) {
                $("#locationTabs").tabs({ disabled: [1] });
                this.getAdditionalInformation().done(() => {
                    $result.resolve(true);
                });
            } else {
                this.Location.get().done(() => {
                    this.getAdditionalInformation().done(() => {
                        $result.resolve(true);
                    });
                });
            }
            return $result;
        }

        private getAdditionalInformation(): JQueryDeferred<any> {
            var $result = $.Deferred();
            this.Client.get().done(() => {
                new CRM.Models.PropertyScheduleTypes({ GroupID: this.Client.GroupID }).get().done((scheduleTypes) => {
                    scheduleTypes.forEach((schO) => {
                        this.PropertyScheduleTypes.push(new CRM.Models.PropertyScheduleTypes(schO));
                    });
                    new Shared.Models.States().getStates().done((states) => {
                        this.States = states;

                        if (this.Location.LocationID() != null) {
                            new CRM.Models.ClientAddType({ GroupID: this.Client.GroupID }).getLocationAddTypes(this.Location.LocationID(), this.Location.ScheduleID()).done((addTypes) => {
                                this.Demographics = addTypes;
                                CRM.Models.Contact.get(this.Client.ClientID).done((_clientContacts) => {
                                    this.Contacts = _clientContacts;
                                    this.Contacts.unshift(new CRM.Models.Contact({ ClientName: '', ContactID: null }));
                                    $result.resolve(true);
                                });                                
                            });
                        } else {
                            $result.resolve(true);
                        }
                    });
                });
            });
            return $result;
        }
    }

    export class user_Location_Edit_Base {

        LocationEdit: ViewModels.user_Location_Edit;
        DemographicsEdit: ViewModels.user_Location_Edit_Demographics;
        BaseData: Location_Base_Data;
        BaseEvents: any;
        LocationConfig: any;

        initBase(locationID: number, clientID: number): void {
            mW.knockout.ko_custom_binders();
            this.baseSetup();
            this.BaseData = new Location_Base_Data(locationID, clientID);
            //this.prepareData();
            this.BaseData.getData().done(() => {
                this.bindButtons().done(() => {
                    this.prepareEvents();
                    this.LocationEdit = new ViewModels.user_Location_Edit;
                    this.DemographicsEdit = new ViewModels.user_Location_Edit_Demographics;
                    this.LocationEdit.init(this.BaseData, this.DemographicsEdit);
                    this.DemographicsEdit.init(this.BaseData);
                });
            });
        }

        baseSetup(): void {
            $('#locationTabs').tabs();
            $('#locationContainer').valid();
        }

        bindButtons(): JQueryDeferred<any> {
            this.prepareEvents();
            var $result = $.Deferred();
            ko.applyBindings(this.BaseEvents, document.getElementById('buttomContainer'));
            $result.resolve(true);
            return $result;
        }

        prepareData(): void {
            this.LocationConfig = {};
            if (this.BaseData.Location.LocationID() == null) {
                this.LocationConfig.hasLocationID = ko.observable(false);
            } else {
                this.LocationConfig.hasLocationID = ko.observable(true);
            }
        }

        prepareEvents(): void {
            this.BaseEvents = {
                hasLocationID: function () {
                    if (this.BaseData.Location.LocationID() == null) {
                        return ko.observable(null);
                    } else {
                        return ko.observable(true);
                    }
                },
                createLocation: (data, event) => {
                    if (this.BaseData.Location.LocationNumber.isValid()) {
                        $('button').prop('disabled', true);
                        $('#locationMessage').text('Saving current location...');
                        this.BaseData.Location.save().done(() => {
                            $('#locationMessage').text('Creating new location...');
                            this.BaseData.Location.clear().done(() => {
                                this.BaseData.Location.ClientID(this.BaseData.Client.ClientID);
                                this.BaseData.Location.ClientNumber(this.BaseData.Client.ClientNumber);
                                this.BaseData.Location.ScheduleID($('#locationScheduleType').val());
                                this.BaseData.Location.Status('Active');
                                this.BaseData.Location.StartDate(null);
                                this.BaseData.Location.TerminationDate(null);
                                $("#locationTabs").tabs({ disabled: [1] });
                                $('#locationMessage').text('New location is ready to be edited.').delay(5000).fadeOut('fast', function () {
                                    $('#locationMessage').text('').show();
                                });
                                $('button').prop('disabled', false);
                            });
                        });
                    }
                },
                moveLocation: (data, event) => {
                    this.LocationEdit.moveLocation();
                },
                editHistory: (data, event) => {
                    this.LocationEdit.viewEditHistory();
                },
                saveLocation: (data, event) => {
                    if (this.BaseData.Location.LocationNumber.isValid()) {
                        this.LocationEdit.saveLocation().done(() => {
                            this.LocationEdit.foundLocations();
                        });
                    }
                },
                saveDemographics: (data, event) => {
                    this.DemographicsEdit.save();
                },
                cancel: function (data, event) {
                    closeThis(false);
                }
            }

            $('#locationTabs').tabs({
                show: (event, ui) => {
                    this.toggleButtons();
                }
            });
        }

        toggleButtons(): void {

            var tabName = $('.ui-state-active').find('a').text();
            switch (tabName.toLowerCase()) {
                case 'location':
                    $('#saveDemo').hide();
                    $('#saveLoc, #addLoc, #moveLoc, #editLoc').show();
                    break;
                case 'demographics':
                    $('#saveLoc, #addLoc, #moveLoc, #editLoc').hide();
                    $('#saveDemo').show();
                    break;
            }

        }
    }

}