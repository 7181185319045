// see - www.masterdata.se/r/string_format_for_javascript/ - for usage. and blog.stevex.net/string-formatting-in-csharp/ - - for supported formats.
var string_formatter = (<any>String).__Format;

namespace Que.Forms.ViewModels {

    export class edit_Questionnaire_Form_Elements {   

        Editor: Que.Forms.Models.Questionnaire_Admintool_Base_Model;        
        Elements: Models.Element[];                
        GridForms: any[];

        init(editor: Que.Forms.Models.Questionnaire_Admintool_Base_Model): JQueryDeferred<any> {
            var $result = $.Deferred();            
            this.Editor = editor;
            this.pre_setup();
            this.GridForms = [];
            this.get_grid_forms().done(() => {
                this.bind().done(() => {
                    this.bind_information_guide().done(() => {
                        this.post_setup();
                        $result.resolve(true);
                    });                    
                });                
            });
            
            return $result;
        }

        get_grid_forms(): JQueryDeferred<any> {
            var $result = $.Deferred();
            if (this.Editor.Data.Form.DataStructureID != null) {
                if (this.GridForms.length == 0) {
                    this.Editor.Data.getForms(this.Editor.Data.Form.DataStructureID, 2).done((gridForms) => {
                        this.GridForms = mW.io.reconstruct_object(gridForms);
                        $result.resolve(true);
                    });
                } else {
                    $result.resolve(true);
                }          
            } else {
                $result.resolve(true);
            }
            return $result;
        }

        pre_setup(): void {            
            if (this.Editor.Data!= (undefined || null)) {
                this.Elements = this.Editor.Data.Sections.length > 0 ? this.Editor.Data.Sections[0].Elements : [];                
            } else {                
                this.Elements = [];
            }
            
            this.handlebars_helpers();
        }

        bind = (): JQueryDeferred<any> => {
            var $result = $.Deferred(), elements = 
                mW.handlebars.bind(CHSITemplates.AdminTools_Questionnaire_Editor_Elements, { Elements: this.Elements }, $('#elementsGridContainer')).done(() => {
                    this.post_bind_configuration();                    
                    $result.resolve(true);
            });
            return $result;
        }

        post_bind_configuration(): void {

            if (this.Elements.length > 0) {
                this.bind_selects();
            }                

            restart = true;
            sorttable.init();

            edit_Questionnaire_Form_Editor.bindSortable();

        }

        bind_information_guide(): JQueryDeferred<any> {
            var $result = $.Deferred();            
            $('#information').html(mW.handlebars.html(CHSITemplates.AdminTools_Questionnaire_Editor_Information_Guide, null));
            $result.resolve(true);
            return $result;
        }

        post_setup(): void {                        
            // finish setting up elements
            if (this.Editor.isGrid) {
                this.toggle_section_filter(true);            
            }             
            this.update_section_filter();
            this.events();            
        }

        events(): void {
            var $parentButtons = $('#buttonContainer'), $elementContainer: JQuery = $('#elements');

            $('#sectionsFilter').on('change', (e) => {
                
                if ($(e.target).val() == '-1') {
                    this.Elements = [];
                } else {
                    this.Elements = this.get_section().Elements;
                }
                
                this.bind().done(() => {
                    this.Elements.forEach((ele) => {
                        this.toggle_element_change_notifications(ele);
                    });
                });
                
            });

            $('#minimizeAll').on('click', (e) => {                
                this.minimize_section_elements();
            });

            $parentButtons.on('click', '.elementAdd', () => {
                this.add();
            });            

            $parentButtons.on('click', '.elementSave', () => {
                this.save();
            });

            $elementContainer.on('focus', '.elementLabel', (e) => {
                if ($(e.target).val() == 'Enter Element Name') {
                    $(e.target).val('');
                }
            });

            $elementContainer.on('keyup', '.elementLabel, .htmlLabel', (e) => {
                var elementID = $(e.target).closest('.element').data('elementid');
                //this.validate();
                this.update(elementID, 'Label', $(e.target).val());
            });

            $elementContainer.on('change', '.elementType', (e) => {
                var elementID = $(e.target).closest('.element').data('elementid'), elementType = $(e.target).val();
                this.element_type_change(elementID, elementType);
            });

            $elementContainer.on('change', '.elementDefaultVisibility', (e) => {
                var elementID = $(e.target).closest('.element').data('elementid'), value = $(e.target).val();
                this.update(elementID, 'Visibility', value);
            });

            $elementContainer.on('change', '.elementTemplateRegion', (e) => {
                var elementID = $(e.target).closest('.element').data('elementid'), value = $(e.target).val();
                this.update(elementID, 'TemplateRegionIndex', value);
            });

            $elementContainer.on('change', '.metaAttribute', (e) => {
                var elementID = $(e.target).closest('.element').data('elementid'), metaTypeID = $(e.target).data('metatype'), value = $(e.target).val(), tagName = $(e.target).attr('type');
                if (tagName == 'checkbox') {
                    value = $(e.target).is(':checked');
                }
                this.update_meta(metaTypeID, value, elementID);
            });           

            $elementContainer.on('change', '.elementDataCategories', (e) => {
                var $parent = $(e.target).closest('.element'), categoryID = $(e.target).val(), $column = $parent.find('.elementDataColumns');
                this.update_datastructure_columns(categoryID, $column);
            });

            $elementContainer.on('change', '.elementDataColumns', (e) => {
                var elementID = $(e.target).closest('.element').data('elementid'), $parent = $(e.target).closest('.element');
                this.update(elementID, 'DataStructureColumnID', $(e.target).val());
                this.update_element_datastructure(elementID, $parent.find('.elementDataCategories').val(), $parent.find('.elementDataColumns').val());
            });

            $elementContainer.on('click', '.elementEdit', (e) => {
                var $parent = $(e.target).closest('.element'), elementID = $(e.target).closest('.element').data('elementid'), element: Models.Element = this.get_element(elementID);                
                this.toggle_detail(element, $parent);
            });

            $elementContainer.on('click', '.elementDelete', (e) => {
                var elementID = $(e.target).closest('.element').data('elementid'), $parent = $(e.target).closest('.element');
                this.delete(elementID, $parent);
            });

            $elementContainer.on('click', '.elementUndo', (e) => {
                var elementID = $(e.target).closest('.element').data('elementid');
                this.undo(elementID);
            });

            $elementContainer.on('keyup', '.elementFormat', (e) => {
                var elementID = $(e.target).closest('.element').data('elementid'), $parent = $(e.target).closest('.element'), $value = $parent.find('.elementFormatValue'), $formatPreview = $parent.find('.element_format_preview');
                this.format_input($(e.target).val(), +$value.val(), $formatPreview);
                //this.update(elementID, 'Format', $(e.target).val());
                this.update_meta(2, $(e.target).val(), elementID);
            });

            $elementContainer.on('keyup', '.elementFormatValue', (e) => {
                var elementID = $(e.target).closest('.element').data('elementid'), $parent = $(e.target).closest('.element'), $format = $parent.find('.elementFormat'), $formatPreview = $parent.find('.element_format_preview');
                this.format_input($format.val(), +$(e.target).val(), $formatPreview);
                //this.update(elementID, 'Format', null);
            });

            $elementContainer.on('click', '.editHTML', (e) => {
                var elementID = +$(e.target).closest('.element').data('elementid');
                this.Editor.Element_Sub_Editors.toggle_editor_mode(Que.Forms.Enums.Element_Sub_Editor_Status.SHOW_SUB_EDITOR, '#html_editor', elementID);           
            });

            $elementContainer.on('click', '.editFormat', (e) => {
                var elementID = +$(e.target).closest('.element').data('elementid');
                this.Editor.Element_Sub_Editors.toggle_editor_mode(Que.Forms.Enums.Element_Sub_Editor_Status.SHOW_SUB_EDITOR, '#format_editor', elementID);
            });

            $elementContainer.on('click', '.editToolTip', (e) => {
                var elementID = +$(e.target).closest('.element').data('elementid');
                this.Editor.Element_Sub_Editors.toggle_editor_mode(Que.Forms.Enums.Element_Sub_Editor_Status.SHOW_SUB_EDITOR, '#tooltip_editor', elementID);
            });

            $elementContainer.on('click', '.editCalculate', (e) => {
                var elementID = +$(e.target).closest('.element').data('elementid');                
                this.Editor.Element_Sub_Editors.toggle_editor_mode(Que.Forms.Enums.Element_Sub_Editor_Status.SHOW_SUB_EDITOR, '#calculate_editor', elementID);
            });

            $elementContainer.on('sortstart', '.sortableQuestionnaire tbody', (event, ui) => {
                ui.item.css('box-shadow', '0 0 10px #000000');
                ui.item.css('background-color', 'white');
            });

            $elementContainer.on('sortstop', '.sortableQuestionnaire tbody', (event, ui) => {
                ui.item.css('box-shadow', '');
                this.sort_order();                
            });

            $elementContainer.on('click', '.information_icon', (e) => {
                var guideName = $(e.target).attr('name');
                this.toggle_information_guide(guideName);
            });

            $('#hide_information_guide').on('click', (e) => {
                this.toggle_information_guide(null);
            });

            $('#editor_container').on('click', '.information_icon', (e) => {
                var guideName = $(e.target).attr('name');
                this.toggle_information_guide(guideName);
            });

            $('#editCancel').on('click', (e) => {                
                if ($(e.target).hasClass('elementCancel')) {
                    if (this.validate_elements()) {
                        GUI.Windows.Popup.closeThisInline('#modal_questionnaireformeditor');
                        $('#modal_questionnaireformeditor').remove();
                    } else {
                        var c = confirm("By closing you will lose all unsaved work.");
                        if (c) {
                            GUI.Windows.Popup.closeThisInline('#modal_questionnaireformeditor');
                            $('#modal_questionnaireformeditor').remove();
                        }
                    }
                }
            });

            $('#back_to_editor').on('click', (e) => {                
                this.Editor.Element_Sub_Editors.toggle_editor_mode(Que.Forms.Enums.Element_Sub_Editor_Status.CANCEL_RETURN, null, null);
            });

            $('#update_and_return').on('click', (e) => {                
                this.Editor.Element_Sub_Editors.toggle_editor_mode(Que.Forms.Enums.Element_Sub_Editor_Status.SAVE_RETURN, null, null);
            });
                
        }

        add(): void {
            this.get_grid_forms().always(() => {

                var elementID = this.Elements.length != 0 ? (this.Elements[this.Elements.length - 1].ElementID) : -1,
                    sortIndex = this.Elements.length == 0 ? 1 : (this.Elements[this.Elements.length - 1].SortIndex + 1);

                var element: Models.Element = new Models.Element({
                    ElementID: elementID < 0 ? -Math.abs(Math.abs(elementID) + 1) : -Math.abs(elementID + 1),
                    FormSectionID: $('#sectionsFilter').val(),
                    Status: 'Active',
                    IsRequired: false,
                    ElementType: 3,
                    Visibility: 1,                    
                    Label: 'Enter Element Name',
                    DataStructureColumnID: null,
                    SortIndex: sortIndex,
                    AllowHTML: true,
                    BlockType: 4,
                    _metaHasChanged: true
                });

                this.Elements = this.get_section().Elements;
                this.Elements.push(element);
                this.bind().done(() => {
                    var $element = $('.element').eq($('.element').length - 1);
                    this.toggle_detail(element, $element);
                    this.update_element_datastructure(element.ElementID, $element.find('.elementDataCategories').val(), $element.find('.elementDataColumns').val());
                    if (this.Editor.isGrid) {
                        this.Editor.Preview_View.bind();
                        //this.refreshPreview();
                    }
                    this.bind_detail_to_expanded_elements();
                    this.Elements.forEach((ele) => {
                        this.toggle_element_change_notifications(ele);
                    });
                    edit_Questionnaire_Form_Editor.notifcation('New Element Added.', true);
                    edit_Questionnaire_Form_Editor.toggleTabs(this.Editor.Data);
                });
                this.toggle_section_filter(true);      
                edit_Questionnaire_Form_Editor.toggle_disable_tabs([0, 1]);
            });           
                          
        }

        save(): void {

            if (this.validate_elements()) {
                this.disable_element_buttons(true);
                edit_Questionnaire_Form_Editor.notifcation('Saving Elements...');
                this.get_section().saveElements().done((ids) => {
                    if (this.Editor.isGrid) {
                        this.toggle_section_filter(false);
                        edit_Questionnaire_Form_Editor.toggle_disable_tabs([]);
                    } else {
                        // i don't remember why we have this logic
                        this.toggle_section_filter(false);
                        edit_Questionnaire_Form_Editor.toggle_disable_tabs([]);
                    }
                    edit_Questionnaire_Form_Editor.notifcation('Updating Elements...');
                    this.update_element_with_elementids(ids);
                    this.Elements = this.get_section().Elements;
                    this.Editor.Section_View.bind().done(() => {
                        this.Editor.Data.Sections.forEach((sec) => { this.Editor.Section_View.toggle_section_change_notifications(sec); });
                    });
                    this.Editor.Element_View.bind().done(() => {
                        this.Editor.Preview_View.bind().done(() => {
                            this.Elements.forEach((element) => {
                                if ((<any>element)._isExpanded) {
                                    this.bind_saved_detail_data(element, this.$get_element(element.ElementID));
                                }
                            });

                            this.disable_element_buttons(false);
                            $('.elementUndo').prop('disabled', true);
                            edit_Questionnaire_Form_Editor.notifcation('Elements Updated.', true);

                        });
                    });
                });
            } else {
                $('.elementSave').prop('disabled', false);
            }

        }       

        delete(elementID: number, $this: JQuery): void {
            var c = confirm('Are you want to delete this Element?');
            if (c) {
                this.disable_element_buttons(true);
                edit_Questionnaire_Form_Editor.notifcation('Deleting Element...');
                this.get_section(+$('#sectionsFilter').val()).deleteElement(elementID).done(() => {
                    this.disable_element_buttons(false);
                    edit_Questionnaire_Form_Editor.notifcation('Updating Elements...');
                    this.Editor.Section_View.bind().done(() => {
                        this.Editor.Element_View.bind().done(() => {
                            this.Editor.Preview_View.bind().done(() => {
                                this.Elements = this.get_section().Elements;

                                if (this.Editor.isGrid) {
                                    this.Editor.Preview_View.bind();
                                }

                                edit_Questionnaire_Form_Editor.notifcation('Elements Updated.', true);
                                // toggleTabs only handles sections/elements that are new NOT if something has changed - Review
                                edit_Questionnaire_Form_Editor.toggleTabs(this.Editor.Data);
                                if (this.Elements.some(ele => ele._metaHasChanged == true)) {
                                    edit_Questionnaire_Form_Editor.toggle_disable_tabs([0, 1]);
                                    this.toggle_section_filter(true);
                                } else {
                                    edit_Questionnaire_Form_Editor.toggle_disable_tabs([]);
                                    this.toggle_section_filter(false);
                                }
                            });
                        });
                    });
                }).fail(() => {
                    this.disable_element_buttons(false);
                    edit_Questionnaire_Form_Editor.notifcation('An error occurred when deleting.', true);    
                    });
            } else {
                edit_Questionnaire_Form_Editor.notifcation('Element Deletion Cancelled.', true);
            }
        }

        undo(elementID: number): void {

            var element = this.get_element(elementID);

            for (var cloneProp in element._clone) {
                if (cloneProp != 'SortIndex' && cloneProp != '_sortHasChanged' && cloneProp != '_clone') {
                    if (cloneProp == 'MetaAttributes') {
                        element.MetaAttributes = new Array<Que.Forms.Models.MetaAttribute>();
                        element._clone.MetaAttributes.forEach((meta) => {
                            element.MetaAttributes.push($.extend({}, meta));
                        });
                    } else {
                        element[cloneProp] = element._clone[cloneProp];
                    }
                }
            }            

            this.bind().done(() => {
                this.Elements.forEach((element) => {
                    if ((<any>element)._isExpanded) {
                        this.bind_saved_detail_data(element, this.$get_element(element.ElementID));
                    }
                    this.toggle_element_change_notifications(element);
                });
            });
            

            if (this.Elements.some(ele => ele._metaHasChanged == true) || this.Elements.some(ele => ele._propertyHasChanged == true)) {
                edit_Questionnaire_Form_Editor.toggle_disable_tabs([0, 1]);
                this.toggle_section_filter(true);
            } else {
                edit_Questionnaire_Form_Editor.toggle_disable_tabs([]);
                this.toggle_section_filter(false);
            }

            edit_Questionnaire_Form_Editor.notifcation('Element changes have been reverted.', true);
        }

        update(elementID: number, propertyName: string, value: any): void {
            var element = this.get_element(elementID), $element = this.$get_element(elementID);
            this.clone_element(element);            
            mW.object_types.update_property(element, propertyName, value);           
            element._propertyHasChanged = true;
            $element.find('.elementUndo').prop('disabled', false);
            this.toggle_section_filter(true);
            this.toggle_element_change_notifications(element);
            edit_Questionnaire_Form_Editor.toggle_disable_tabs([0, 1]);

            if (this.Editor.isGrid) {
                this.Editor.Preview_View.bind();
            }

            edit_Questionnaire_Form_Editor.notifcation('Element configuration updated.', true);
        }

        disable_element_buttons(isDisabled: boolean): void {
            $('.elementDelete, .editHTML, .editToolTip, .editCalculate, .elementSave, .elementAdd, .elementCancel').prop('disabled', isDisabled);
        }

        get_section(sectionID?: number): Models.Section {
            var section: Models.Section = null;
            if (!this.Editor.isGrid) {
                if (!sectionID) {
                    sectionID = $('#sectionsFilter').val();
                }

                for (var i = 0; i < this.Editor.Data.Sections.length; i++) {
                    if (this.Editor.Data.Sections[i].SectionID == sectionID) {
                        section = this.Editor.Data.Sections[i];
                        break;
                    }
                }
            } else {
                section = this.Editor.Data.Sections[0];
            }        
            return section;
        }

        get_element(elementID: number): Models.Element {
            var element: Models.Element = null;
            for (var i = 0; i < this.Elements.length; i++) {
                if (this.Elements[i].ElementID == elementID) {
                    element = this.Elements[i];
                    break;
                }
            }
            return element;
        }

        $get_element(elementID: number): JQuery {
            var $element: JQuery = null;
            $('.element').each((i, e) => {
                if (elementID == +$(e).data('elementid')) {
                    $element = $(e);
                    return false;
                }
            });
            return $element;
        }        

        clone_element(element: Que.Forms.Models.Element): void {
            if (element._clone == null) {
                element._clone = $.extend({}, element);                
                element._clone.MetaAttributes = new Array<Que.Forms.Models.MetaAttribute>();                
                element.MetaAttributes.forEach((meta) => {
                    element._clone.MetaAttributes.push($.extend({}, meta));
                });
                    
                
            }
        }

        update_meta(metaTypeID: number, metaValue: any, elementID: number): void {

        // convert the incoming value to whatever the actual metavalue expects - doing this helps us future proof new metatypes as they may require different values on saving
            switch (metaTypeID) {                
                case 16:         
                case 19:       
                case 20:
                case 28:
                    metaValue = metaValue == true ? '1' : '0';
                    break;                                    
                default:
                    break;
            }

            if (!isNaN(metaTypeID)) {

                var element: Models.Element = this.get_element(elementID), isNewMeta: boolean = true, $element = this.$get_element(elementID);

                this.clone_element(element);

                for (var i = 0; i < element.MetaAttributes.length; i++) {

                    if (element.MetaAttributes[i].MetaType == metaTypeID) {                    

                        element.MetaAttributes[i].MetaValue = metaValue;

                        element._metaHasChanged = true;                                          

                        this.toggle_section_filter(true);

                        edit_Questionnaire_Form_Editor.toggle_disable_tabs([0, 1]);

                        isNewMeta = false;

                        break;

                    }

                }                

                if (isNewMeta) {
                    element.MetaAttributes.push(new Que.Forms.Models.MetaAttribute({ ParentID: elementID, ParentSecondID: null, ParentThirdID: null, ObjectTypeID: 14, Description: null, MetaType: metaTypeID, MetaIndex: null, MetaValue: metaValue, Status: 'Active' }));
                    element._metaHasChanged = true;
                    edit_Questionnaire_Form_Editor.toggle_disable_tabs([0, 1]);
                }

                $element.find('.elementUndo').prop('disabled', false);

                this.toggle_element_change_notifications(element);

            }

            edit_Questionnaire_Form_Editor.notifcation('Element configuration updated.', true);
        }

        update_element_with_elementids(idList: any): void {
            for (var id in idList) {
                var elementIDBeforeSave: number = +id, elementIDAfterSave: number = +idList[id], element = this.get_element(elementIDBeforeSave); 
                element.ElementID = elementIDAfterSave;
                element._clone.ElementID = elementIDAfterSave;
            }
        }

        bind_detail_to_expanded_elements(): void {
            this.Elements.forEach((element) => {
                if ((<any>element)._isExpanded) {
                    this.bind_saved_detail_data(element, this.$get_element(element.ElementID));
                }
            });
        }        

        validate_elements(): boolean {
            var isElementsValid: boolean = true, $elements = $('.element');

            $elements.each((i, e) => {
                let elementID = $(e).data('elementid'), validatedFields: boolean[] = [], isValid: boolean = true, element = this.get_element(elementID);

                if (element.ElementType != 1) { // 1 - HTML, has the least validation of all element types                  
                    validatedFields.push(markError(element.Label, $(e).find('.elementLabel')));  
                    validatedFields.push(markError(element.DataStructureColumnID, $(e).find('.elementDataColumns')));
                }                

                isValid = validatedFields.every(isValid => isValid == true);

                if (!isValid) {                
                    isElementsValid = false;
                    this.toggle_detail(element, $(e), true);
                }

            });

            function markError(prop: any, $this: JQuery): boolean {
                let isValid: boolean = true;
                if (prop == null || String(prop).trim() == '' || String(prop).trim() == 'Enter Element Name' || prop == '-1') {
                    $this.addClass('validation-error');
                    isValid = false;
                } else {
                    $this.removeClass('validation-error');
                }
                return isValid;
            }            

            if (!isElementsValid) {
                edit_Questionnaire_Form_Editor.notifcation('Please update the incomplete fields highlighted in red.', true);
            }

            return isElementsValid;
        }

        sort_order(): void {
            $('.element').each((i, e) => {

                var elementID = $(e).data('elementid'), element = this.get_element(elementID);

                this.clone_element(element);
                if (element.SortIndex != i) {
                    element.SortIndex = i;
                    element._sortHasChanged = true;
                }                
                this.toggle_element_change_notifications(element);
                

            });

            this.Elements.sort((a, b) => {
                if (a.SortIndex < b.SortIndex) {
                    return -1;
                }
                if (a.SortIndex > b.SortIndex) {
                    return 1;
                }
                return 0;
            });

            // we rebind the section tab so that the user knows which section has had child element changes
            this.Editor.Section_View.bind().done(() => {
                this.Editor.Data.Sections.forEach((sec) => { this.Editor.Section_View.toggle_section_change_notifications(sec); });
            });
            //this.rebindAll();


            if (this.Editor.isGrid) {
                this.Editor.Preview_View.bind();
                //this.refreshPreview();
            }

            edit_Questionnaire_Form_Editor.notifcation('Elements Sort Order Updated.', true);
            restart = true;
            sorttable.init();
        }

        minimize_section_elements(): void {                    
            $('.element').each((i, e) => {
                var elementID = $(e).data('elementid'), element = this.get_element(elementID);
                if ((<any>element)._isExpanded == true) {
                    $(e).find('.elementMasterDetail').slideUp('fast');
                    (<any>element)._isExpanded = false;
                }
            });            
        }

        toggle_section_filter(disable: boolean): void {

            if (disable) {
                $('#sectionsFilter').prop('disabled', true);
                $('#sectionsFilter').attr('title', 'There are unsaved Elements. Please save them before attempting to select a new Section.');
            } else {
                $('#sectionsFilter').prop('disabled', false);
                $('#sectionsFilter').attr('title', '');
            }

        }

        toggle_detail(element: Models.Element, $parent: JQuery, forceExpand?: boolean): void {
            var $masterDetail = $parent.find('.elementMasterDetail');

            if (forceExpand) {
                (<any>element)._isExpanded = false;
            }

            if ((<any>element)._isExpanded) {
                $masterDetail.slideUp('fast', () => {
                    (<any>element)._isExpanded = false;
                });
            } else {
                $masterDetail.slideDown('fast', () => {
                    (<any>element)._isExpanded = true;
                });
            }            

            this.bind_saved_detail_data(element, $parent);

        }

        toggle_element_change_notifications(element: Que.Forms.Models.Element): void {

            this.clone_element(element);

            var $element = this.$get_element(element.ElementID), $exclamationIcon = $element.find('.has_changed_exclamation_container'), titleTip = '', numOfChanges = 0, isNewElement = (element.ElementID < 0);

            [element._propertyHasChanged, element._metaHasChanged, element._sortHasChanged, isNewElement].forEach((changedValue) => { if (changedValue) { numOfChanges++; } });

            titleTip = titleTip + '- element has not been saved. \n';

            if (isNewElement) {
                titleTip = titleTip + '- element was recently added.';
                if (numOfChanges > 1) {
                    titleTip = titleTip + '\n';
                }
            }

            if (element._propertyHasChanged) {
                titleTip = titleTip + '- element has had one or more property values changed.';
                if (numOfChanges > 1) {
                    titleTip = titleTip + '\n';
                }
            }

            if (element._metaHasChanged) {
                titleTip = titleTip + '- element has had one or more meta attibute values changed.';
                if (numOfChanges > 1) {
                    titleTip = titleTip + '\n';
                }
            }

            if (element._sortHasChanged) {
                titleTip = titleTip + '- element has had it\'s sort order changed. (cannot be undone)';
            }
                
            if (numOfChanges > 0) {
                $exclamationIcon.show()
                $exclamationIcon.find('.ui-icon-alert').attr('title', titleTip);
            } else {
                $exclamationIcon.hide();
                $exclamationIcon.find('.ui-icon-alert').attr('title', titleTip);
            }

        }

        toggle_information_guide(guideName: string): void {
            var $information = $('#information_guide_container');
            switch (guideName) {
                case 'element':
                    $('#element_information_guide').show();
                    $information.slideDown('fast');
                    break;
                case 'html':
                    $('#html_information_guide').show();
                    $information.slideDown('fast');
                    break;
                case 'format':
                    $('#format_information_guide').show();
                    $information.slideDown('fast');
                    break;
                default:
                // hide guide
                    $information.slideUp('fast', () => {
                        $('.information_guide_display').hide();                
                    });                    
            }
        }

        format_input(format: string, valueToFormat: number, $preview: JQuery): void {
            var formatResult = string_formatter(format, valueToFormat);                                   
            $preview.text(formatResult);
        }

        update_section_filter(): void {        
            var sectionID = null;
            if (this.Editor.Data.Sections.length > 0) {
                sectionID = this.Editor.Data.Sections[0].SectionID;
            }
            mW.ui.build_select('#sectionsFilter', this.Editor.Data.Sections, 'SectionID', 'Title', 'No Sections Found', sectionID);
        }

        element_type_change(elementID: number, elementType: number): void {
            this.update(elementID, 'ElementType', elementType);
            switch (+elementType) {
                case Que.Forms.Enums.ElementType.HTML:
                case Que.Forms.Enums.ElementType.PLAIN_TEXT:
                case Que.Forms.Enums.ElementType.GRID:
                    this.update(elementID, 'BlockType', 2);
                    break;
                case Que.Forms.Enums.ElementType.TEXT_BOX:
                case Que.Forms.Enums.ElementType.RADIO:
                case Que.Forms.Enums.ElementType.CHECKBOX_LIST:
                case Que.Forms.Enums.ElementType.UPLOAD_CONTROL:
                case Que.Forms.Enums.ElementType.SELECT_LIST:
                case Que.Forms.Enums.ElementType.VERTICAL_RADIO_LIST:
                case Que.Forms.Enums.ElementType.VERTICAL_CHECKBOX_LIST:
                case Que.Forms.Enums.ElementType.CHECKBOX:
                    this.update(elementID, 'BlockType', 4);
                    break;
            }
            this.bind().done(() => {
                this.Elements.forEach((ele) => {
                    this.toggle_element_change_notifications(ele);
                });
            });
            edit_Questionnaire_Form_Editor.notifcation('Element Type Changed.', true);
        }

        bind_saved_detail_data(element: Models.Element, $parent: JQuery): void {
                    
            $parent.find('.metaAttribute').each((i, e) => {

                element.MetaAttributes.forEach((attribute) => {
                    var metaTypeID = $(e).data('metatype'), tagName: string = $(e).prop('tagName');
                    if (metaTypeID == attribute.MetaType) {
                        switch (tagName.toLowerCase()) {
                            case 'select':
                                $(e).val(attribute.MetaValue);
                                break;
                            case 'input':
                                var inputType: string = $(e).attr('type');
                                switch (inputType.toLowerCase()) {
                                    case 'checkbox':
                                        var isChecked = attribute.MetaValue == '1' ? true : false;
                                        $(e).prop('checked', isChecked);
                                        break;
                                    case 'radio':
                                        console.warn('Updating radio button by metavalue is not yet implemented.');
                                        break;
                                    case 'text':
                                        console.warn('Updating text button by metavalue is not yet implemented.');
                                        break;
                                }
                                break;
                        }
                    }
                });

            });

            this.select_datastructures(element, $parent);

        }

        select_datastructures(element: Models.Element, $parent: JQuery): void {
            var columns = this.Editor.Data._DataStructureColunns, column: DataStructures.Models.DataStructureColumn = null;
            for (var i = 0; i < columns.length; i++) {
                if (element.DataStructureColumnID == columns[i].ColumnID) {
                    column = columns[i];
                    break;
                }
            }                

            if (column != null) {
                $parent.find('.elementDataCategories').val(String(column.DataCategoryID));
                this.update_datastructure_columns(column.DataCategoryID, $parent.find('.elementDataColumns'));
                $parent.find('.elementDataColumns').val(String(column.ColumnID));
            }            

        }

        update_element_datastructure(elementID: number, categoryID: number, columnID: number): void {
            var element = this.get_element(elementID);
            this.Editor.Data.DataStructureCategories.forEach((cat) => {
                if (cat.CategoryID == categoryID) {
                    cat.Columns.forEach((col) => {
                        if (col.ColumnID == columnID) {
                            element.DataStructure.ColumnID = columnID;
                            element.DataStructure.DataCategoryID = categoryID;
                            element.DataStructure.DataTypeID = col.DataTypeID;         
                            element._metaHasChanged = true;                   
                        }
                    });
                }
            });
            if (this.Editor.isGrid) {
                this.Editor.Preview_View.bind();
            }
        }

        update_datastructure_columns(categoryID: number, $this: JQuery): void {
            var category = this.Editor.Data.DataStructureCategories;
            $this.empty();
            for (var i = 0; i < category.length; i++) {
                if (category[i].CategoryID == categoryID) {
                    if (category[i].Columns.length > 0) {
                        $($this).append($('<option>', { value: '-1', text: 'Select Value' }));
                        category[i].Columns.forEach((column) => {
                            $($this).append($('<option>', {
                                value: column.ColumnID,
                                text: column.FieldName
                            })).data('currentdscatid', category[i].CategoryID);
                        });
                    } else {                        
                        $($this).append($('<option>', {
                            value: null,
                            text: 'No Columns Found'
                        }));
                    }
                }            
            }                                                                
        }

        bind_selects = () => {                            
            if (this.Editor.Data != (undefined || null)) {
                var buildSelect = (list: any[], classID, valueName, textName, listName) => {
                    var $class = $('.' + classID);
                    if (list.length > 0) {
                        $class.append($('<option>', { value: '-1', text: 'Select Value' }));
                        list.forEach((listItem) => {
                            $class.append($('<option>', {
                                value: listItem[valueName],
                                text: listItem[textName]
                            }));
                        });
                    } else {
                        $class.append($('<option>', { value: null, text: 'No Data Found' }).attr('title', 'See console for error.'));       
                        console.error('Could not load dropdown list. List is missing data for "' + listName + '". Review Data Structure "' + $('#formDataStructure option:selected').text() +  '" as it may be configured incorrectly.');
                    }
                };
                //buildSelect(mW.enums.enums_helpers.convert_enum_to_array(Que.Instances.Enums.Visibility), 'elementDefaultVisibility', 'EnumValue', 'EnumName', 'Visibility');
                mW.ui.build_select('.elementDefaultVisibility', mW.enums.enums_helpers.convert_enum_to_array(Instances.Enums.Visibility), 'EnumValue', 'EnumName', 'No Data Found', null);
                mW.ui.build_select('.elementTemplateRegion', mW.enums.enums_helpers.convert_enum_to_array(Que.Forms.Enums.TemplateRegion), 'EnumValue', 'EnumName', 'No Data Found', null);
                buildSelect(this.GridForms, 'elementGridSources', 'StructureID', 'FormDescription', 'Grid Forms');
                buildSelect(this.Editor.Data.ValueList, 'elementListSources', 'ValueListID', 'ListName', 'Value List');
                //buildSelect(this.WYSIWYG.ContentTypes, 'elementContentTypes', 'ContentTypeID', 'CssClass', 'Content Types'); - removed at the request of Urias. Content type should be baked into datastructurecolumns but there might be legacy code that says elements have content types, so we're just gonna comment out content type logic until we're 100% sure that's the case.
                buildSelect(this.Editor.Data.DataStructureCategories, 'elementDataCategories', 'CategoryID', 'CategoryTitle', 'Data Structure Categories');

                var dataStructureCategories = this.Editor.Data.DataStructureCategories.length > 0 ? this.Editor.Data.DataStructureCategories[0].Columns : [];

                if (dataStructureCategories.length > 0) {
                    $('.elementDataCategories').val(String(dataStructureCategories[0].ColumnID));
                }

                buildSelect(dataStructureCategories, 'elementDataColumns', 'ColumnID', 'FieldName', 'Data Structure Columns');

                this.Elements.forEach((ele) => {
                    var $ele = this.$get_element(ele.ElementID);
                    $ele.find('.elementDefaultVisibility').val(String(ele.Visibility));
                    $ele.find('.elementTemplateRegion').val(String(ele.TemplateRegionIndex));
                });
   
            }
        }

        handlebars_helpers(): void {

            var _self = this;

            Handlebars.registerHelper('ifFormHide', () => {
                var isHidden;
                // put into class="{{ifGridHide}}"
                if (!this.Editor.isGrid) {
                    isHidden = 'displayNone ';
                }
                return isHidden;
            });

            Handlebars.registerHelper('ifGridHide', () => {
                var isHidden;
                // put into class="{{ifGridHide}}"
                if (this.Editor.isGrid) {
                    isHidden = 'displayNone ';
                }
                return isHidden;
            });

            Handlebars.registerHelper('hideIfElementTypes', function (hideIfElementTypes) {
                var elementType: number = this.ElementType, hideClass = null;

                hideIfElementTypes = eval(hideIfElementTypes);

                hideIfElementTypes.forEach((type) => {

                    if (type == elementType) {
                        hideClass = 'classHide';
                    }

                });

                return hideClass;
            });

            Handlebars.registerHelper('showIfElementTypeEquals', function (elementType, options) {
                var returnClass = null;
                if (this.ElementType != elementType) {
                    returnClass = 'classHide';
                }

                return returnClass;
            });

            Handlebars.registerHelper('readOnlyIfElementTypeEquals', function (elementType, options) {
                var readOnly = null;
                if (this.ElementType == elementType) {
                    readOnly = 'readonly disabled';
                }
                return readOnly;
            });

            Handlebars.registerHelper('readOnlyIfNotElementType', function (elementTypes, options) {
                var elementTypesArry: any[] = [];
                elementTypesArry = eval(elementTypes);
                var readOnly = null;

                var hasValue = elementTypesArry.some((ele => ele === +this.ElementType));

                if (!hasValue) {
                    readOnly = ' readonly disabled title="This Attribute cannot be configured for this Element Type."';
                }

                return readOnly;
            });

            Handlebars.registerHelper('isExpanded', function (options) {
                var show = null;
                if (this._isExpanded == true) {
                    show = 'style="display: inline-block;"';
                } else {
                    show = 'style="display: none;"';
                }
                return show;
            });

            Handlebars.registerHelper('ifHasChanged', function (trueValue, falseValue, options) {
                var value = null, element: Que.Forms.Models.Element = this;
                if (element._propertyHasChanged == true || element._metaHasChanged == true) {
                    value = trueValue;
                } else {
                    value = falseValue;
                }
                return value;                
            });

            Handlebars.registerHelper('isValid', function (propertyName, options) {
                var isValid;
                if (this[propertyName] == '' || this[propertyName] == null || this[propertyName] == undefined) {
                    isValid = 'validation-error';
                } else {
                    isValid = '';
                }
                return isValid;
            });
            
            Handlebars.registerHelper('GetElementMetaValue', function (metaType: number, equalTo: string, options) {
                var metaAttributes: Models.MetaAttribute[] = this.MetaAttributes;

                console.log(metaAttributes);

                for (var i = 0; i < metaAttributes.length; i++) {

                    if (metaAttributes[i].MetaType == metaType) {

                        if (metaAttributes[i].MetaValue == equalTo) {
                            return options.fn(this);
                        } else {
                            return options.inverse(this);
                        }

                    }

                }

            });
        }
    }

}