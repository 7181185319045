namespace Que.Forms.ViewModels {

    export class edit_Questionnaire_Form_Editor_Copy {

        init(): void {
            
            this.eventSetup();
            this.events();
                       
        }

        static prepareEditorContainer(formID: number, formTitle: string): JQueryDeferred<any> {
            var $result = $.Deferred();
            $result.resolve(mW.handlebars.html(CHSITemplates.AdminTools_Questionniare_Editor_Copy, { FormID: formID, FormTitle: formTitle }));
            return $result;
        }

        eventSetup(): void {            
            $('#oldFormTitle').text(decodeURI($('#formCopyContainer').data('formtitle')));
            $(".date").datepicker({ showOn: "both", buttonImage: "/images/icons/cal.gif", buttonImageOnly: true });
        }

        events(): void {
            var _self = this;            

            $('#jxCopyForm').on('click', (e) => {
                $(e.target).prop('disabled', true);
                if (this.validate()) { 
                    var formObject: PL.Que.Models.Design.Form = new PL.Que.Models.Design.Form;
                    formObject.FormID = +$('#formCopyContainer').data('formid');
                    formObject.Title = $('#newFormTitle').val();
                    formObject.ActiveDate = $('#activeDate').val();
                    formObject.InactiveDate = $('#inactiveDate').val() == '' ? null : $('#inactiveDate').val();
                    PL.AdminTools.APIs.WYSIWYG_Repo.copyForm(formObject).done((newFormID) => {
                        if (+newFormID > 0) {
                            $('#copyNotification').css('color', 'green').text("Form was copied successfully.");
                            setTimeout(function () {
                                GUI.Windows.Popup.closeThisInline('#modal_questionnaireformeditorcopy');
                            }, 2000);
                        } else {
                            $(e.target).prop('disabled', false);
                            $('#copyNotification').css('color', 'red').text("The Form did not copy correctly. If the form continues to not copy please contact your administrator.");
                        }
                    });
                }
            });

            $('#jxCancel').on('click', function () {

                GUI.Windows.Popup.closeThisInline('#modal_questionnaireformeditorcopy');

            });

        }

        validate(): boolean {
            var isValid = true, $copy = $('.copyValidate');

            $copy.each(function () {                
                if ($(this).val() == '') {
                    isValid = false;
                    return false;
                }
            });
            
            $copy.each(function () {                
                $(this).val() == '' ? $(this).addClass('validation-error') : $(this).removeClass('validation-error');
            });

            if (!isValid) {
                alert('Please correct fields marked in red to continue.');
            }

            return isValid;
        }

    }

}