namespace CRM.Models {

    export class ClientAddInfo {

        ClientAddID: number;
        ClientID: number;
        AddTypeID: number;
        DetailValue: string;
        DetailName: string;
        CreateTmsp: Date;
        CreateUser: string;
        UpdateTmsp: Date;
        UpdateUser: string;

        constructor(client?: Interfaces.IClientAddInfo) {
            this.ClientAddID = client.ClientAddID == undefined ? null : client.ClientAddID;
            this.ClientID = client.ClientID == undefined ? null : client.ClientID;
            this.AddTypeID = client.AddTypeID == undefined ? null : client.AddTypeID;
            this.DetailValue = client.DetailValue == undefined ? null : client.DetailValue;
            this.DetailName = client.DetailName == undefined ? null : client.DetailName;
            this.CreateTmsp = client.CreateTmsp == undefined ? null : client.CreateTmsp;
            this.CreateUser = client.CreateUser == undefined ? null : client.CreateUser;
            this.UpdateTmsp = client.UpdateTmsp == undefined ? null : client.UpdateTmsp;
            this.UpdateUser = client.UpdateUser == undefined ? null : client.UpdateUser;
        }

        get(): JQueryDeferred<ClientAddInfo> {
            var $result = $.Deferred();

            CRM.APIs.Clients.getAdditionalClientInfo(this.ClientID, this.AddTypeID).done((clientInformation) => {

                $result.resolve(clientInformation);

            });

            return $result;

        }

        static saveAll(clientAddInfo: ClientAddInfo[]): JQueryDeferred<any> {
            var $result = $.Deferred();
            APIs.ClientAddInfo.saveClientAddInfo(clientAddInfo).done(() => {
                $result.resolve(true);
            });
            return $result;
        }
    }

}