namespace BI.APIs {

    export class PBI {

        private static _routePrefix = '/api/BI/PowerBI/';

        static get_widgets(): JQueryDeferred<any> {
            var $result = $.Deferred();
            $.get(this._routePrefix + 'GetPBIWidgets').done((data) => {
                $result.resolve(data);
            });
            return $result;
        }

    }

}