namespace Que.Forms.APIs {
    export class WYSIWYG_Repo {

        private static _routePrefix: string = '/api/Que/WYSIWYG/';

        public static getForms(dataStructureID: number, templateID: number): JQueryDeferred<PL.Common.Models.SmartGrid> {
            var results = $.Deferred();
            $.get(this._routePrefix + 'GetForms?DataStructureID=' + dataStructureID + '&TemplateID=' + templateID).done((data) => {
                results.resolve(data);
            });
            return results;
        }

        public static getFormTypeGridByDataStructureID(dataStructureID: number): JQueryDeferred<PL.Common.Models.SmartGrid> {
            var results = $.Deferred();
            $.get(this._routePrefix + 'GetFormTypeGridByDataStructureID?DataStructureID=' + dataStructureID).done((data) => {
                results.resolve(data);
            });
            return results;
        }

        public static saveForm(form: Que.Forms.Models.Form): JQueryDeferred<number> {
            var results = $.Deferred();
            mW.io.post(this._routePrefix + 'SaveForm', form).done((data) => {
                results.resolve(data);
            });
            return results;
        }

        public static getCompleteFormByFormID(formID: number): JQueryDeferred<any> {
            var results = $.Deferred<any>();
            $.get(this._routePrefix + 'GetCompleteFormByFormID?FormID=' + formID).done((data) => {
                results.resolve(data);
            });
            return results;
        }

        public static getSections(formID: number): JQueryDeferred<Que.Forms.Models.Section> {
            var results = $.Deferred();
            $.get(this._routePrefix + 'GetSections').done((data) => {
                results.resolve(data);
            });
            return results;
        }

        public static deleteSection(section: Que.Forms.Models.Section): JQueryDeferred<any> {
            var results = $.Deferred();
            mW.io.post(this._routePrefix + 'DeleteSection', section).done((data) => {
                results.resolve(data);
            });
            return results;
        }

        public static saveSection(section: Que.Forms.Models.Section): JQueryDeferred<number> {
            var results = $.Deferred();
            mW.io.post(this._routePrefix + 'SaveSection', section).done((data) => {
                results.resolve(data);
            });
            return results;
        }

        public static saveSections(sections: Que.Forms.Models.Section[]): JQueryDeferred<any> {
            var results = $.Deferred<any>();
            var cleanedSections = JSON.stringify(sections);
            mW.io.post(this._routePrefix + 'SaveSections', sections).done((data) => {
                results.resolve(data);
            });
            return results;
        }

        public static saveElement(element: Que.Forms.Models.Element): JQueryDeferred<number> {
            var results = $.Deferred();
            mW.io.post(this._routePrefix + 'SaveElement', element).done((data) => {
                results.resolve(data);
            });
            return results;
        }

        public static saveElements(elements: Que.Forms.Models.Element[]): JQueryDeferred<any> {
            var results = $.Deferred<any>();            
            mW.io.post(this._routePrefix + 'SaveElements', elements).done((data) => {
                results.resolve(data);
            });
            return results;
        }

        public static getValueList(): JQueryDeferred<any> {
            var results = $.Deferred();
            $.get(this._routePrefix + 'GetListSource').done((data) => {
                results.resolve(data);
            });
            return results;
        }

        public static getContentType(): JQueryDeferred<any> {
            var results = $.Deferred();
            $.get(this._routePrefix + 'GetContentType').done((data) => {
                results.resolve(data);
            });
            return results;
        }

        public static copyForm(formObject: PL.Que.Models.Design.Form): JQueryDeferred<any> {
            var results = $.Deferred();
            mW.io.post(this._routePrefix + 'CopyForm', formObject).done((data) => {
                results.resolve(data);
            });
            return results;
        }

        public static saveElementHelpDetails(help: Que.Forms.Models.ElementHelpDetails): JQueryDeferred<number> {
            var results = $.Deferred();
            mW.io.post(this._routePrefix + 'SaveElementHelpDetails', help).done((data) => {
                results.resolve(data);
            });
            return results;
        }
        
        public static getNotifications(notificationID?: number): JQueryDeferred<any> {
            var results = $.Deferred();
            $.get(this._routePrefix + 'GetNotifications?NotificationID=' + notificationID).done((data) => {
                results.resolve(data);
            });
            return results;
        }

        public static saveNotification(notification: Que.Forms.Models.FormNotification): JQueryDeferred<any> {            
            var results = $.Deferred();
            mW.io.post(this._routePrefix + 'SaveNotification', notification).done((data) => {
                results.resolve(data);
            });
            return results;
        }

        public static deleteNotification(notificationID?: number): JQueryDeferred<any> {
            var results = $.Deferred();
            $.get(this._routePrefix + 'DeleteNotification?NotificationID=' + notificationID).done(() => {
                results.resolve(true);
            });
            return results;
        }

        public static getContactTypes(notificationID?: number): JQueryDeferred<any> {
            var results = $.Deferred();
            $.get(this._routePrefix + 'GetContactTypes').done((data) => {
                results.resolve(data);
            });
            return results;
        }        

        public static getStatusTrigger(formID: number): JQueryDeferred<any> {
            var results = $.Deferred();
            $.get(this._routePrefix + 'GetStatusTrigger?FormID=' + formID).done((data) => {
                results.resolve(data);
            });
            return results;
        }

        public static saveStatusTrigger(trigger: Que.Forms.Models.FormStatusTrigger): JQueryDeferred<any> {
            var results = $.Deferred();
            mW.io.post(this._routePrefix + 'SaveStatusTrigger', trigger).done((data) => {
                results.resolve(data);
            });
            return results;
        }

        public static deleteStatusTrigger(triggerID: number): JQueryDeferred<any> {
            var results = $.Deferred();
            $.get(this._routePrefix + 'DeleteStatusTrigger?TriggerID=' + triggerID).done((data) => {
                results.resolve(data);
            });
            return results;
        }
    }

}