namespace Que.Forms.Models {

    export class MetaAttribute implements Interfaces.IMetaAttribute {

        ParentID: number;
        ParentSecondID: number;
        ParentThirdID: number;
        ObjectTypeID: number;
        Description: string;
        MetaType: number;
        MetaIndex: number;
        MetaValue: string;
        Status: string;
        CreateUser: string;
        CreateTmsp: string | Date;
        UpdateUser: string;
        UpdateTmsp: string | Date;

        constructor(metaAttribute?: Interfaces.IMetaAttribute) {
            this.ParentID = metaAttribute == undefined ? null : metaAttribute.ParentID;
            this.ParentSecondID = metaAttribute == undefined ? null : metaAttribute.ParentSecondID;
            this.ParentThirdID = metaAttribute == undefined ? null : metaAttribute.ParentThirdID;
            this.ObjectTypeID = metaAttribute == undefined ? null : metaAttribute.ObjectTypeID;
            this.Description = metaAttribute == undefined ? null : metaAttribute.Description;
            this.MetaType = metaAttribute == undefined ? null : metaAttribute.MetaType;
            this.MetaIndex = metaAttribute == undefined ? null : metaAttribute.MetaIndex;
            this.MetaValue = metaAttribute == undefined ? null : metaAttribute.MetaValue;
            this.Status = metaAttribute == undefined ? null : metaAttribute.Status;
            this.CreateUser = metaAttribute == undefined ? null : metaAttribute.CreateUser;
            this.CreateTmsp = metaAttribute == undefined ? null : metaAttribute.CreateTmsp;
            this.UpdateUser = metaAttribute == undefined ? null : metaAttribute.UpdateUser;
            this.UpdateTmsp = metaAttribute == undefined ? null : metaAttribute.UpdateTmsp;
        }

    }

}