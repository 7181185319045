namespace CRM.APIs {

    export class ContactTypes {

        private static _routePrefix: string = '/api/CRM/ContactTypes/';

        static get_contact_type(isActive: boolean): JQueryDeferred<any> {
            var $result = $.Deferred();
            $.get(this._routePrefix + 'GetContactTypes?isActive=' + isActive).done((data) => {
                $result.resolve(data);
            });
            return $result;
        }        

        static get_contact_type_flat(isActive: boolean): JQueryDeferred<any> {
            var $result = $.Deferred();
            $.get(this._routePrefix + 'GetContactTypesFlat?isActive=' + isActive).done((data) => {
                $result.resolve(data);
            });
            return $result;
        }        

        static save_contact_type(contactType: any): JQueryDeferred<any> {
            var $result = $.Deferred();
                mW.io.post(this._routePrefix + 'SaveContactType', contactType).done((id) => {
                    $result.resolve(id);
                });
            return $result;
        }     

        static delete_contact_type(contactTypeID: number): JQueryDeferred<any> {
            var $result = $.Deferred();
            $.get(this._routePrefix + 'DeleteContactType?contactTypeID=' + contactTypeID).done((id) => {
                $result.resolve(id);
            });
            return $result;
        }        

        static get_in_use_contact_type_count(contactTypeID: number): JQueryDeferred<any> {            
            var $result = $.Deferred();
            $.get(this._routePrefix + 'GetInUseContactTypeCount?contactTypeID=' + contactTypeID).done((data) => {
                $result.resolve(data);
            });
            return $result;
        }

    }

}