interface Window {
    showPopUP: any;
}
namespace CRM.ViewModels {

    export class user_Location_Edit extends CRM.Base.user_Location_Edit_Base {

        LocationEvents: any = {};

        init(baseData: CRM.Base.Location_Base_Data, demographicsEdit: CRM.ViewModels.user_Location_Edit_Demographics): void {

            this.DemographicsEdit = demographicsEdit;

            this.BaseData = baseData;

            this.setup();

            this.prepareData();

            this.prepareEvents();

            this.customBindings();

            this.bind().done(() => {

                // extra stuff we need to do later if any

            });
        }

        bind(): JQueryDeferred<any> {
            var $result = $.Deferred();
            ko.applyBindingsWithValidation({ Location: this.BaseData.Location, Statuses: ['Active', 'Inactive', 'Deleted'], PropertyScheduleTypes: this.BaseData.PropertyScheduleTypes, associtedContacts: this.BaseData.Contacts, States: this.BaseData.States, LocationEvents: this.LocationEvents }, document.getElementById('locationContainer'), { insertMessages: false });
            $result.resolve(true);
            return $result;
        }

        setup(): void {
            $(".date").datepicker({ showOn: "both", buttonImage: "/images/icons/cal.gif", buttonImageOnly: true });
            this.validate_zip_on_change();
        }

        validate_zip_on_change(): void {

            $('#zip').on('change', (e) => {

                if ($(e.target).val().length != 5) {
                    alert('Min zip value is 5.');
                    $(e.target).val('');
                }

            });

            $('#zip4').on('change', (e) => {
                if ($(e.target).val().length != 4) {
                    alert('Min zip4 value is 4.');
                    $(e.target).val('');
                }
            });

        }

        moveLocation(clickEvent?: any): void {

            var showPopUP = new GUI.Windows.Popup();
            showPopUP.URL = '/CM/modalmovelocation.aspx?LocationID=' + this.BaseData.Location.LocationID() + '&CompanyID=' + this.BaseData.Client.ClientID;
            showPopUP.WindowID = 'modalmovelocation';
            showPopUP.Title = 'Move Location';
            showPopUP.Width = 400;
            showPopUP.Height = 300;
            showPopUP.isParent = true;
            //showPopUP.CloseFunction = () => { this.BaseData.Location.get() }
            showPopUP.showIFrame(clickEvent);

        }

        viewEditHistory(clickEvent?: any): void {

            var showPopUP = new GUI.Windows.Popup();
            showPopUP.URL = '/CM/modallocationhistory.aspx?LocationID=' + this.BaseData.Location.LocationID() + '&CompanyID=' + this.BaseData.Client.ClientID;
            showPopUP.WindowID = 'modalmovelocation';
            showPopUP.Title = 'Move Location';
            showPopUP.Width = 900;
            showPopUP.Height = 285;
            showPopUP.isParent = true;
            showPopUP.CloseFunction = () => { this.BaseData.Location.get() }
            showPopUP.showIFrame(clickEvent);
        }

        getDirections(clickEvent?: any): void {
            var showPopUP = new GUI.Windows.Popup(), mapaddresss = this.BaseData.Location.Address1() + ', ' + this.BaseData.Location.City();
            showPopUP.URL = '/utilities/DrivingDirections.aspx?EndURL=' + encodeURI(mapaddresss);
            showPopUP.WindowID = 'modalmovelocation';
            showPopUP.Title = 'Get Directions';
            showPopUP.Width = 750;
            showPopUP.Height = 550;
            showPopUP.isParent = true;
            showPopUP.CloseFunction = () => { this.BaseData.Location.get() }
            showPopUP.showIFrame(clickEvent);
        }

        foundLocations(clickEvent?: any): void {
            var showPopUP = new GUI.Windows.Popup();
            showPopUP.URL = '/cm/modalfoundlocations.aspx?LocationID=' + this.BaseData.Location.LocationID() + '&Address=' + this.BaseData.Location.Address1() + '&City=' + this.BaseData.Location.City() + '&State=' + this.BaseData.Location.State() + '&Zip5=' + this.BaseData.Location.Zip();
            showPopUP.WindowID = 'modalmovelocation';
            showPopUP.Title = 'Found Location';
            showPopUP.Width = 600;
            showPopUP.Height = 300;
            showPopUP.isParent = true;
            showPopUP.CloseFunction = () => {
                $('#locationMessage').text('Updating location...');
                this.BaseData.Location.get().done(() => {
                    this.prepareData();
                    $('#locationMessage').text('Updated.').delay(5000).fadeOut('fast', function () {
                        $('#locationMessage').text('').show();
                    });
                });
            };
            showPopUP.showIFrame(clickEvent);
        }

        prepareData(): void {
            this.BaseData.Location.LocationNumber.extend({ required: true, number: true });
            this.BaseData.Location.StartDate() == null || this.BaseData.Location.StartDate() == '0001-01-01T00:00:00' ? this.BaseData.Location.StartDate(null) : this.BaseData.Location.StartDate(moment(this.BaseData.Location.StartDate()).format('MM/DD/YYYY'));
            this.BaseData.Location.TerminationDate() == null || this.BaseData.Location.TerminationDate() == '0001-01-01T00:00:00' ? this.BaseData.Location.TerminationDate(null) : this.BaseData.Location.TerminationDate(moment(this.BaseData.Location.TerminationDate()).format('MM/DD/YYYY'));
        }

        saveLocation(): JQueryDeferred<any> {
            var $result = $.Deferred(), isNew = false;
            $('button').prop('disabled', true);
            $('#locationMessage').text('Saving location...');
            if (this.BaseData.Location.LocationID() == null) {
                isNew = true;
            }
            this.BaseData.Location.save().done((locationID) => {
                if (isNew) {
                    $("#locationTabs").tabs("enable", 1);
                    this.BaseData.Location.LocationID(locationID);
                    this.DemographicsEdit.updateDemographicsView(true);
                }
                $('#locationMessage').text('Saved.').delay(5000).fadeOut('fast', function () {
                    $('#locationMessage').text('').show();
                });
                $('button').prop('disabled', false);
                $result.resolve(true);
            });
            return $result;
        }

        prepareEvents(): void {
            this.LocationEvents = {
                isLocationNumberValid: (data, event) => {
                    if (this.BaseData.Location.LocationNumber.isValid()) { // is valid if numeric                        
                        this.BaseData.Location.isLocationNumberValid().done((isValid) => {
                            var $message = $('#locationNumberMessage');

                            if (!isValid) {
                                $message.text('Warning: This location number is assigned to another location.').show();
                            } else {
                                $message.text('').hide();
                            }
                        });
                    }
                },
                updateDemographicsView: (data, event) => {
                    this.DemographicsEdit.updateDemographicsView(true);
                    $('#demoScheduleOnly').attr('checked', 'checked');
                },
                getDirections: (data, event) => {
                    this.getDirections();
                },
                copyToClipBoard: (data, event) => {
                    window.prompt("Copy to clipboard: Ctrl+C, Enter", this.BaseData.Location.Latitude().toString() + this.BaseData.Location.Longitude().toString());
                },
                associtedContacts: (data, event) => {
                    ko.observableArray(this.BaseData.Contacts);
                }
            }
        }

        customBindings(): void {

            (<any>ko.bindingHandlers).formatLocationPhone = {

                init: (element, valueAccessor, allBinding) => {
                    var value = valueAccessor();
                    var phone = ko.utils.unwrapObservable(valueAccessor());
                    if (phone != undefined && phone != null) {
                        var formatedNumber = function () {
                            return phone.replace(/(\d{3})(\d{4})/, "$1-$2");
                        }
                        $(element).val(formatedNumber);
                        phone = phone.replace(/-/g, '');
                        value(phone);
                    }
                },

                update: (element, valueAccessor, allBinding) => {
                    var value = valueAccessor();
                    var phone = ko.utils.unwrapObservable(valueAccessor());
                    ko.bindingHandlers.value.update(element, phone);
                    if (phone != undefined && phone != null) {
                        if (phone.length >= 3) {
                            phone = phone.replace(/-/g, '');
                            value(phone);
                            var station = phone.substring(0, 3);
                            var exchange = phone.substring(3, 7);
                            var concatPhone = station + '-' + exchange;
                            $(element).val(concatPhone);

                        }
                    }
                }
            }
        }
    }
}