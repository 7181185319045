namespace Shared.ViewModels {

    export class WidgetManager {
        public WidgetPreviews: mW.interfaces.DicMap<Models.WidgetPreview>;
        public PreviewPanelTemplate: any;
        public WidgetBuilder: Shared.Models.Widget_Builder;
        public $WidgetViewButton: JQuery;

        constructor(public $widgetViewButton: JQuery, public $WidgetViewPanel: JQuery, public PageName: string) {
            this.WidgetBuilder = new Shared.Models.Widget_Builder({ page_name: PageName });
            this.$WidgetViewButton = $widgetViewButton;            
            this.WidgetPreviews = {};
        }

        public Init = () => {
            Security.Models.UserSecurity.HasPermission('WidgetAdmin').done((hasPermission) => {

                if (hasPermission) {

                    this.HandleBarHelpers();                    

                    this.WidgetBuilder.init().done(() => { // Widget_Builder init "gets" widget data now

                        this.WidgetBuilder.on_widget_delete_callback = this.BindPreviewTempalate;

                        this.$WidgetViewButton.show().off().on('click', (e) => {
                            this.WidgetViewButton_ClickHandler(e);
                        });

                        this.SetPreviewPanelTemplate();

                        this.Prepare_Widget_Previews();

                    });

                } else {

                    

                }

            });
            
        }

        Prepare_Widget_Previews = () => {

            for (var w in this.WidgetBuilder.widgets) {

                var widget = this.WidgetBuilder.widgets[w];

                if (widget.Settings['Visible'] == null || +widget.Settings['Visible'] == 0) {
                    this.WidgetPreviews[widget.ID] = new Models.WidgetPreview(widget.ID, widget.Name, widget.Settings);
                }

            }

        }

        // on drop, we need to save the visibility so widget renders by default on page load for this user
        public WidgetPreview_DropHandler = () => {
            // set visibility setting
            // settings = new Array<WidgetSetting>(){ set ish }
            // save setting
            //this.SaveWidgetSettings(settings);
        }

        public WidgetViewButton_ClickHandler = (e: JQueryEventObject) => {
            // handle animations
            var animDuration = 500; // in ms
            // spin animation for gear icon
            $({ deg: 0 }).animate({ deg: 360 }, {
                duration: animDuration,
                step: (now) => {
                    this.$WidgetViewButton.css({
                        transform: 'rotate(' + now + 'deg)'
                    });
                }
            });
            if (this.$WidgetViewPanel.is(':hidden')) {
                // probably even do this in init?
                
                this.BindPreviewTempalate();
                this.$WidgetViewPanel.show().animate({ height: '120px' }, animDuration, () => {
                    this.WidgetBuilder.show_drop_zones();
                });
            } else {
                this.$WidgetViewPanel.animate({ height: '0px' }, animDuration, () => {
                    // remove widget previews
                    this.$WidgetViewPanel.html('').hide();
                    // hide drag'n drop zones
                    this.WidgetBuilder.hide_drop_zones();
                });
            }
        }

        BindPreviewTempalate = () => {
            this.Prepare_Widget_Previews();
            mW.handlebars.bind(this.PreviewPanelTemplate, { WidgetPreviews: this.WidgetPreviews }, this.$WidgetViewPanel);
        }

        public SetPreviewPanelTemplate = (templateID: number = -1) => {
            switch (templateID) { // enum later?
                default:
                    this.PreviewPanelTemplate = CHSITemplates.Widgets_TopPreviewPanel;
                    break;
            }
        }

        HandleBarHelpers = () => {

            Handlebars.registerHelper('IgnoreWidgetIf', function (widget: Shared.Models.Abstract_Widget) {
                var _class = '';
                if (+widget.Settings['Visible'] == 1 && +widget.Settings['RegionID'] == 1) {
                    _class = 'hide-widget-preview';
                }
                return _class;
            });

        }
    }
}