namespace Shared.Models {

    export class PBI_Widget extends Shared.Models.Abstract_Widget {

        Elements: Shared.Models.PBI_Element[];

        constructor(widget?: Shared.Interfaces.IAbstract_Widget) {
            if (widget == null) widget = {};
            super(widget);
            this.Elements = widget.Elements;
        }

        save(): number {
            throw Error('Not implemented.');

        }

    }

}