namespace Que.Forms.Enums {

    export enum Alert_Type {

    }

    export enum Editor_Mode {

        FORM,
        GRID,
        NEW_FORM,
        NEW_GRID

    }

    export enum Element_Sub_Editor_Mode {

        HTML,
        TOOLTIP,
        FOOTER_CALCULATION

    }

    export enum Element_Sub_Editor_Status {

        SHOW_SUB_EDITOR,
        SAVE_RETURN,
        CANCEL_RETURN

    }
}