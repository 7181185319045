namespace CRM.APIs {

    export class ClientTypes {

        private static _routePrefix: string = '/api/CRM/ClientType/';

        public static get_client_types(): JQueryDeferred<any> {
            var $result = $.Deferred();
            $.get(this._routePrefix + 'GetClientTypes').done((data) => {
                $result.resolve(data);
            });
            return $result;
        }

        public static save_client_type(clientType: CRM.Models.ClientTypes): JQueryDeferred<any> {
            var $result = $.Deferred();
            mW.io.post(this._routePrefix + 'SaveClientType', clientType).done((id) => {
                $result.resolve(id);
            });
            return $result;
        }

        public static delete_client_type(clientTypeName: string): JQueryDeferred<any> {
            var $result = $.Deferred();
            mW.io.post(this._routePrefix + 'DeleteClientType?clienttype=' + clientTypeName).done(() => {
                $result.resolve(true);
            });
            return $result;
        }

    }

}