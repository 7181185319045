namespace Que.Forms.ViewModels {

    export class edit_Questionnaire_Editor_Grid {

        //private _$container: JQuery = $('#jxQuestionnaireFormEditGridContainer');
        Forms: any;
        FormEditGrid: GUI.Tables.DTables = new GUI.Tables.DTables;
        TemplateID: number;
        DataStructureID: number;

        init(): void {

            this.getDataStructures();

            this.events();

        }

        events(): void {
            var $container = $('#jxQuestionnaireFormEditGridContainer');

            $('#jxDataStructure').on('change', (e) => {
                this.DataStructureID = $(e.target).val();
                this.getForms();
            });                                    
            // return false didn't work to prevent any bubbling, was forced to use the ugly e.blah stuff to stop it
            $container.on('click', '.jxFormEditorCopy', (e) => {                
                var d = this.FormEditGrid.$grid.row($(e.target).closest('tr')).data(), formID = d[0], title = d[1];
                this.openFormCopy(formID, title, e);
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
            });

            $container.on('click', '.jxFormEdit', (e) => {
                var d = this.FormEditGrid.$grid.row($(e.target).closest('tr')).data();
                var formID = d[0];
                this.openForm(formID, e);
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
            });

            $container.on('click', '.jxFormDelete', (e) => {                
                var d = this.FormEditGrid.$grid.row($(e.target).closest('tr')).data(), isUsed = d[9] == 'True' ? true : false;
                this.deleteForm(+d[0], +d[7], isUsed); 
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
            });            

            $('#jxNewQuestionnaireForm, #jxNewQuestionnaireFormGrid').on('click', (e) => {
                this.openForm(null, e);
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
            });

        }

        getForms(): void {
            new PL.AdminTools.Models.QuestionnaireWYSIWYG().getForms(this.DataStructureID, this.TemplateID).done((data) => {
                this.Forms = data;
                this.bindGrid();
            });
        }

        deleteForm(formID: number, templateID: number, isUsed: boolean): void {
            var message = null
            if (isUsed == true) {
                message = 'This Form is being by used by active Questionnaire(s). Are you sure you want to delete it?';
            } else {
                message = 'Are you sure you want to delete this form?';
            }

            var c = confirm(message);

            if (c) {
                new Que.Forms.Models.Form({ FormID: formID, DesignTemplateID: templateID }).delete().done(() => {
                    this.init();
                });
            }
        }

        openFormCopy(formID: number, formTitle: string, clickEvent?: any): void {            
            Que.Forms.ViewModels.edit_Questionnaire_Form_Editor_Copy.prepareEditorContainer(formID, formTitle).done((html) => {
                var showPopUP = new GUI.Windows.Popup();                
                showPopUP.WindowID = 'questionnaireformeditorcopy';
                showPopUP.Title = 'Questionnaire Form Copy';
                showPopUP.Width = 300;
                showPopUP.Height = 130;
                showPopUP.CloseFunction = () => this.init();                
                showPopUP.HTMLContent = html;                
                showPopUP.showInline();
            });            

        }

        openForm(formID: number, clickEvent?: any): void {

            Que.Forms.ViewModels.edit_Questionnaire_Form_Editor.prepareEditorContainer(formID, this.TemplateID).done((html) => {

                var showPopUP = new GUI.Windows.Popup();                
                showPopUP.WindowID = 'questionnaireformeditor';
                showPopUP.Title = 'Questionnaire Form Editor';
                showPopUP.Width = 800;
                showPopUP.Height = 450;
                showPopUP.CloseFunction = () => this.init();
                showPopUP.HTMLContent = html;
                showPopUP.isModal = false;
                showPopUP.showInline();
                
            });            
            
        }

        getDataStructures(): void {
            new PL.Que.Models.Data.DataStructure().getDataStructures().done((dataStructures) => {
                $('#jxDataStructure').empty();
                $('#jxDataStructure').append($('<option>', {
                    value: null,
                    text: 'Show All',
                }));

                for (var i = 0; i < dataStructures.length; i++) {
                    $('#jxDataStructure').append($('<option>', {
                        value: dataStructures[i].StructureID,
                        text: dataStructures[i].StructureName,
                    }));
                }

                if (dataStructures.length > 0) {
                    $("#jxDataStructure").val($("#jxDataStructure option:eq(1)").val());
                    this.DataStructureID = $("#jxDataStructure").val();
                    this.getForms();
                }
            });



        }

        addEditColumn(): void {

            var _self = this;
            var cdt: GUI.Tables.DTables = new GUI.Tables.DTables;

            if (this.Forms) {
                var Column = new GUI.Smart.SmartColumnObject();
                //title: '',
                Column.data = null;
                Column.orderable = false;
                Column.class = 'details-control';
                Column.defaultContent = '[<a href="#" class="jxFormEdit minilink">Edit</a>]';
                Column.render = function (data, type, row) {
                    return "<a class='jxFormEdit minilink' title='Form ID " + data[0] + "'>[Edit]</a>";
                }

                this.Forms.Columns.push(Column);
            }

        }

        addDeleteColumn(): void {

            var _self = this;
            var cdt: GUI.Tables.DTables = new GUI.Tables.DTables;

            if (this.Forms) {
                var Column = new GUI.Smart.SmartColumnObject();
                //Column.title = 'Value';
                Column.data = null;
                Column.orderable = false;
                Column.class = 'details-control';
                Column.defaultContent = '[<a href="#" class="jxFormCopy minilink">Delete</a>]';
                Column.render = function (data, type, row) {
                    return "<a class='jxFormDelete minilink' title='Form ID " + data[0] + "'>[Delete]</a>";
                }

                this.Forms.Columns.push(Column);
            }

        }

        addCopyColumn(): void {

            var _self = this;
            var cdt: GUI.Tables.DTables = new GUI.Tables.DTables;

            if (this.Forms) {
                var Column = new GUI.Smart.SmartColumnObject();
                //Column.title = 'Value';
                Column.data = null;
                Column.orderable = false;
                Column.class = 'details-control';
                Column.defaultContent = '[<a href="#" class="jxFormEditorCopy minilink">Copy</a>]';
                Column.render = function (data, type, row) {
                    return "<a class='jxFormEditorCopy minilink' title='Form ID " + data[0] + "'>[Copy]</a>";
                }

                this.Forms.Columns.push(Column);
            }

        }

        bindGrid(): void {            
            this.FormEditGrid.stateSave = true;
            this.FormEditGrid.containerName = 'jxQuestionnaireFormEditGridContainer';
            this.FormEditGrid.tableName = 'jxQuestionnaireFormEditGridTable';
            this.FormEditGrid.data = this.Forms;            
            this.addCopyColumn();
            this.addEditColumn();
            this.addDeleteColumn();
            this.FormEditGrid.buildTable();

        }

    }

}