namespace Que.Forms.ViewModels {

    export class edit_Questionnaire_Form_Preview {

        Editor: Que.Forms.Models.Questionnaire_Admintool_Base_Model;
        //WYSIWYG: Que.Forms.Models.QuestionnaireWSYIWYG;          

        init(editor: Que.Forms.Models.Questionnaire_Admintool_Base_Model): JQueryDeferred<any> {    
            var $result = $.Deferred();
            this.Editor = editor;
            if (this.Editor.Data.Form.DesignTemplateID == 2) {
                //this.WYSIWYG = wsyiwyg;
                this.handlebarHelpers();
                this.bind().done(() => {
                    this.setup();
                    this.events();
                    $result.resolve(true);
                });
            } else {
                $result.resolve(true);
            }
            return $result;      
        }

        bind = (): JQueryDeferred<any> => {
            var $result = $.Deferred();
            if (this.Editor.Data.Form != undefined) {
                if (this.Editor.Data.Form.DesignTemplateID == 2) {
                    mW.handlebars.bind(CHSITemplates.AdminTools_Questionnaire_Editor_Grid_Preview, { Elements: this.Editor.Data.Sections[0].Elements }, $('#preview'));
                    $result.resolve(true);
                } else {
                    $result.resolve(true);
                }
            } else {
                $result.resolve(true);
            }
            return $result;
        }

        setup(): void {            
            this.handlebarHelpers();
        }

        //updateData(wsyiwyg: Models.QuestionnaireWSYIWYG): JQueryDeferred<any> {
        //    var $result = $.Deferred();
        //    this.WYSIWYG = wsyiwyg;
        //    return $result.resolve(true);
        //}        

        events(): void {
            var _self = this;

            $('#preview').on('click', '#jxGridPreview', () => {
                // we need to save all change before we preview - TODO
                $('#mainFormSectionContainer').hide('fast', function () {
                    mW.handlebars.bind(CHSITemplates.AdminTools_Questionnaire_Editor_Grid_Preview, { Elements: _self.Editor.Data.Sections[0].Elements }, $('#gridPreviewContainer'), 'fadein').done(() => {                                       
                        $('#jxGridPreview').hide();
                        $('#jxBackToEdit').show();
                    });
                });                
            });

            $('#preview').on('click', '#jxBackToEdit', () => {
                var sectionID = $('.activeSection').data('sectionid');
                $('#gridPreviewContainer').hide('fast', function () {
                    $('#mainFormSectionContainer').show('fast', function () {    
                        $('#jxGridPreview').show();
                        $('#jxBackToEdit').hide();                    
                    });
                });
            });            

        }

        refreshPreview = () => {
            this.bind().done(() => {
                edit_Questionnaire_Form_Editor.notifcation('Grid Preview updated.', true);
            });
        }

        handlebarHelpers(): void {            

            Handlebars.registerHelper('buildGridPreviewHeader', function (elements) {
                var html = [];

                html.push('<tr>');

                for (var i = 0; i < elements.length; i++) {
                    html.push('<td>');

                    html.push('<span class="textBold" title="If the label is formula based, it will automatically be convereted to the desired value when the questionnaire is being filled out.">' + elements[i].Label + '</span>');

                    html.push('</td>');

                }

                html.push('</tr>');

                return html.join('');
            });

            Handlebars.registerHelper('buildGridPreviewBody', function (elements: Que.Forms.Models.Element[]) {
                var html = [], rnd = Math.floor((Math.random() * 10) + 3);
                for (var i = 0; i < rnd; i++) {

                    html.push('<tr>');

                    for (var x = 0; x < elements.length; x++) {

                        html.push('<td>');

                        html.push('<input type="text" value="' + mW.data.generate_random_data(+elements[x].DataStructure.DataTypeID, elements[x].Label) + '" />');

                        html.push('</td>');

                    }

                    html.push('</tr>');

                }

                return html.join('');
            });

            Handlebars.registerHelper('buildGridPreviewFooter', function (elements: Que.Forms.Models.Element[]) {
                var html = []

                html.push('<tr>');

                for (var i = 0; i < elements.length; i++) {
                    var dataType = null;
                    switch (+elements[i].DataStructure.DataTypeID) {
                        case 1:
                            dataType = 'String';
                            break;
                        case 2:
                            dataType = 'DateTime';
                            break;
                        case 3:
                            dataType = 'Decimal';
                            break;
                        case 4:
                            dataType = 'Bit';
                            break;
                        case 5:
                            dataType = 'Integer';
                            break;
                    }

                    html.push('<td>');

                    html.push('<span class="textBold" title="General information regarding the DataStructure Column"> Data Type: ' + dataType + '</span>');

                    html.push('</td>');

                }

                html.push('</tr>');

                return html.join('');
            });

        }

    }

}