namespace mW {

    export class DataTableHelpers
    {
        constructor() {
            throw new Error("This class is static, do not instantiate!");
        };

        public static BuildSettings(data: any): DataTables.Settings {
            return {
                data: data.Values,
                columns: data.Columns,
                deferRender: true,
                paging: true,
                pageLength: 250,
                dom: "t"
            }
        }

        public static BuildDataTable(id: string, settings: DataTables.Settings): DataTables.DataTable {
            if ($('#' + id + '_wrapper').length) {
                var parent = $('#' + id + '_wrapper').parent();
                $('#' + id).DataTable().destroy(true);
                $('#' + id + '_wrapper').remove();
                parent.append("<table id='" + id + "' class='dtable'></table>");
            }
            return $('#' + id).DataTable(settings);
        }

        public static BuildDataTableWithFooter(id: string, settings: DataTables.Settings): DataTables.DataTable {
            if ($('#' + id + '_wrapper').length) {
                var parent = $('#' + id + '_wrapper').parent();
                $('#' + id).DataTable().destroy(true);
                $('#' + id + '_wrapper').remove();
                parent.append("<table id='" + id + "' class='dtable'></table>");
            }
            var footer = '';
            for (var i = 0; i < settings.columns.length; i++) {
                footer += '<th></th>';
            }
            $('#' + id).html('<tfoot class="dt-footer"><tr>' + footer + '</tr></tfoot>');
            return $('#' + id).DataTable(settings);
        }

        // build datatable with header filters using given table ID and smartgrid data object
        // todo - have settings object passed in 
		public static BuildDataTableWithHeaders(id: string, data: any, searchVal?: string, overrideHeight?: number, disableHeaderFilters?: boolean): DataTables.DataTable {

			if (disableHeaderFilters == null || disableHeaderFilters == undefined) {
				disableHeaderFilters = false;
			}

            if ($('#' + id + '_wrapper').length) {
                var parent = $('#' + id + '_wrapper').parent();
                $('#' + id).DataTable().destroy(true);
                $('#' + id + '_wrapper').remove();
				parent.append("<table id='" + id + "' class='dtable'></table>");
            }
            var height = '520px';
			if ($('.containerContent').css('display') == 'block') height = '380px';

			if (overrideHeight != null && overrideHeight > 0) {
				height = overrideHeight + 'px';
			}

            return $('#' + id).DataTable({
                data: data.Values,
                columns: data.Columns,
                //dom: 'lrtip', // no search input
                dom: 'Bfrtip', // with buttons
                scrollY: height,
                scrollX: true,
                paging: true,
                aaSorting: [],
                pageLength: 250, // for speed
                //pagingType: "simple",
                //scroller: true,
                deferRender: true, // for moar speed
                buttons: [
                    {
                        extend: 'excel',
                        text: 'Excel',
                        className: 'dtDefaultBtn',
                        exportOptions: {
                            columns: ':visible'
                        }
                    },
                    {
                        extend: 'csv',
                        text: 'CSV',
                        className: 'dtDefaultBtn',
                        exportOptions: {
                            columns: ':visible'
                        }
                    },
                    //{
                    //    extend: 'pdf',
                    //    text: 'PDF',
                    //    className: 'dtDefaultBtn',
                    //    exportOptions: {
                    //        columns: ':visible'
                    //    }
                    //},
                    {
                        extend: 'print',
                        autoPrint: false,
                        className: 'dtDefaultBtn',
                        exportOptions: {
                            columns: ':visible'
                        },
                        customize: function (win) {
                            var printData = win.document.body.innerHTML;
                            var printHtml = '';

                            // we need to clear document and rewrite markup to page so it will print correctly, while adding better print styling
                            win.document.body.innerHTML = '';
                            printHtml += '<html><head><link type="text/css" rel="stylesheet" href="/src/css/core/PrintView.css" </head><body><table>';
                            //var printBtn = '<tr class="notPrinted;"><th colspan="99"><input type="button" value="Print Page" onclick="javascript: window.print();" /></th></tr>';
                            //printHtml += '<thead>' + printBtn + $(printData).find('thead').html() + '</thead><tbody>';
                            printHtml += '<thead>' + $(printData).find('thead').html() + '</thead><tbody>';
                                                   
                            // add table data
                            $.each($(printData).find('tbody>tr'), function (key, val) {
                                if (key % 2 == 0)
                                    printHtml += '<tr class="aRow">';
                                else
                                    printHtml += '<tr class="bRow">';
                            
                                printHtml += val.innerHTML;
                                printHtml += '</tr>';
                            });

                            printHtml += '</tbody></table></body></html>';
                            var printMe = '<div class="stickBottom notPrinted" style="width:100%;"><div class="printMe" onclick="window.focus();window.print();"><h2 style="padding-top:15px;">Click to print</h2></div></div>';
                            printHtml += printMe;
                            win.document.writeln(printHtml);
                            win.document.close();
                        }
                    }
                ],
                searching: true,
                search: { search: searchVal }, // default search filter if searchVal exists
                initComplete: function (settings, json) {
                    var _dt = this.api();
                    // customize quick search
                    var sLabel = $('#' + id + '_filter label');
                    var sInput = $(sLabel).children('input').clone(true);
                    $(sLabel).text("Quick Search: ");
                    $(sLabel).append(sInput);
                    
					if (!disableHeaderFilters) {
						// Setup - add a text input to each footer cell
						$('#' + id + '_wrapper .dataTables_scrollHead table thead th').each(function () {
							var title = $('#' + id + '_wrapper .dataTables_scrollHead table thead th').eq($(this).index()).text();
							if (title != "") {
								$(this).html(title + '<br /><input class="headerFilter" type="text" placeholder="filter" onclick="stopPropagation(event)"/>');
							}
						});

						// Apply the filter
						$('#' + id + '_wrapper .headerFilter').on('keyup change', function () {
							mW.DataTableHelpers.DoWildcardFilter(_dt, $(this).parent().index() + 1, this.value); // add 1 to index to account for invisible ClientID col at index 0
						});

					}                    

                    // bind button
					if ($('#' + id + '_buttonsContainer').length != 0) { 
						_dt.buttons().container().find('a').appendTo($('#' + id + '_buttonsContainer'), _dt.table().container());
						_dt.buttons().container().remove();
					}
                    // fix col widths due to added header filters
                    _dt.columns.adjust();
                }
            });
        };

        // add new filter func's to expand functionality
        public static DoWildcardFilter(dt: DataTables.DataTable, colIndex: number, val: string) {
            dt.column(colIndex).search(val).draw();
        };        

    }
}