namespace Que.Forms.Models {

    export class ValueListOptions implements Interfaces.IValueListOptions {

        ValueListID: number;
        Description: string;
        Status: string;
        Value: string;
        SortOverride: number;

        constructor(valueListOptions?: Interfaces.IValueListOptions) {
            this.ValueListID = valueListOptions == undefined ? null : valueListOptions.ValueListID;
            this.Description = valueListOptions == undefined ? null : valueListOptions.Description;
            this.Status = valueListOptions == undefined ? null : valueListOptions.Status;
            this.Value = valueListOptions == undefined ? null : valueListOptions.Value;
            this.SortOverride = valueListOptions == undefined ? null : valueListOptions.SortOverride;
        }

    }

}