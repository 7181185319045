namespace Que.Forms.ViewModels {

    export class edit_Questionnaire_Notification_Grid {

        NotificationData: any;
        Forms: Que.Forms.Models.Form[];
        NotificationGrid: GUI.Tables.DTables = new GUI.Tables.DTables;

        init(): void {

            new Que.Forms.Models.QuestionnaireWSYIWYG().getForms(null, 1).done((forms) => {                         
                new Que.Forms.Models.FormNotification({ NotificationID: null }).get().done((notifications) => {
                    this.Forms = forms;
                    this.NotificationData = notifications;
                    this.bind_grid();
                    this.events();
                });
            });

        }

        events(): void {

            $('.notification_edit').on('click', (e) => {
                var d = this.NotificationGrid.$grid.row($(e.target).closest('tr')).data();
                this.show_editor(d[1]);                
            });

            $('#new_notification').on('click', (e) => {
                this.show_editor(null);                
            });

        }

        show_editor(notificationID?: number): void {
            var editor = new Que.Forms.ViewModels.edit_Questionnaire_Notification_Editor(), notification = mW.io.reconstruct_object(this.NotificationData), currentNotification = new Que.Forms.Models.FormNotification({});

            notification.forEach((notification) => {
                if (notification.NotificationID == notificationID) {
                    currentNotification = notification;
                }
            });

            editor.prepare_inline_container(currentNotification).done((html) => {
                var showPopUP = new GUI.Windows.Popup();                
                showPopUP.WindowID = 'questionnairenotificationeditor';
                showPopUP.Title = 'Questionnaire Notification Editor';
                showPopUP.Width = 900;
                showPopUP.Height = 510;
                showPopUP.CloseFunction = () => this.init();                
                showPopUP.HTMLContent = html;                
                showPopUP.isModal = false;
                showPopUP.showInline();                
                editor.init(this.NotificationData, this.Forms, this.init);
            });                             
        }

        add_edit_column(): void {
            var _self = this;
            var cdt: GUI.Tables.DTables = new GUI.Tables.DTables;

            if (this.NotificationData) {
                var Column = new GUI.Smart.SmartColumnObject();
                //title: '',
                Column.data = null;
                Column.orderable = false;
                Column.class = 'details-control';
                Column.defaultContent = '[<a href="#" class="notification_edit minilink">Edit</a>]';
                Column.render = function (data, type, row) {
                    return "<a class='notification_edit minilink' title='Notification ID " + data[1] + "'>[Edit]</a>";
                }
                this.NotificationData.Columns.push(Column);
            }
        }

        bind_grid(): void {
            this.NotificationGrid.stateSave = true;
            this.NotificationGrid.containerName = 'jxQuestionnaireNotificationGridContainer';
            this.NotificationGrid.tableName = 'jxQuestionnaireNotificationGridTable';
            this.NotificationGrid.data = this.NotificationData;            
            this.add_edit_column();            
            this.NotificationGrid.buildTable();

        }

    }

}