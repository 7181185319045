namespace CRM.Models {

    export class Contact {

        ContactID: number;
        ClientID: number;
        ClientName: string;
        ContactName: string;
        ContactFirst: string;
        ContactLast: string;
        ContactMI: string;
        ContactTitle: string;
        ContactType: string;
        UserName: string;
        Greeting: string;
       // PrimaryContact: string;
        Status: string;
        Address1: string;
        Address2: string;
        AddressCSZ: string;
        City: string;
        State: string;
        Zip: string;
        Zip4 : number;
       // Phone1AC : number;
        Phone1: string;
        Phone1Ext: string;
        //Phone2AC : number;
        Phone2: string;
        Phone2Ext: string;
        //FaxAC : number;
        Fax: string;
        Email: string;
        DND: string;
        CreateTmsp: string |Date;
        CreateUser: string;
        UpdateTmsp: string|Date;
        UpdateUser: string;

        constructor(contact?: Interfaces.IContact) {
            if (contact == null) contact = {};
            this.ContactID = contact == undefined ? null : contact.ContactID;
            this.ClientID = contact == undefined ? null : contact.ClientID;
            this.ClientName = contact == undefined ? null : contact.ClientName;
            this.ContactName = contact == undefined ? null : contact.ContactName;
            this.ContactFirst = contact == undefined ? null : contact.ContactFirst;
            this.ContactLast = contact == undefined ? null : contact.ContactLast;
            this.ContactMI = contact == undefined ? null : contact.ContactMI;
            this.ContactTitle = contact == undefined ? null : contact.ContactTitle;
            this.ContactType = contact == undefined ? null : contact.ContactType;
            this.UserName = contact == undefined ? null : contact.UserName;
            this.Greeting = contact == undefined ? null : contact.Greeting;
           // this.PrimaryContact = contact == undefined ? null : contact.PrimaryContact;
            this.Status = contact == undefined ? null : contact.Status;
            this.Address1 = contact == undefined ? null : contact.Address1;
            this.Address2 = contact == undefined ? null : contact.Address2;
            this.City = contact == undefined ? null : contact.City;
            this.State = contact == undefined ? null : contact.State;
            this.Zip = contact == undefined ? null : contact.Zip;
            this.Zip4 = contact == undefined ? null : contact.Zip4;
            //this.Phone1AC = contact == undefined ? null : contact.Phone1AC;
            this.Phone1 = contact == undefined ? null : contact.Phone1;
            this.Phone1Ext = contact == undefined ? null : contact.Phone1Ext;
            //this.Phone2AC = contact == undefined ? null : contact.Phone2AC;
            this.Phone2 = contact == undefined ? null : contact.Phone2;
            this.Phone2Ext = contact == undefined ? null : contact.Phone2Ext;
            //this.FaxAC = contact == undefined ? null : contact.FaxAC;
            this.Fax = contact == undefined ? null : contact.Fax;
            this.Email = contact == undefined ? null : contact.Email;
            this.DND = contact == undefined ? null : contact.DND;
            this.CreateTmsp = contact == undefined ? null : contact.CreateTmsp;
            this.CreateUser = contact == undefined ? null : contact.CreateUser;
            this.UpdateTmsp = contact == undefined ? null : contact.UpdateTmsp;
            this.UpdateUser = contact == undefined ? null : contact.UpdateUser;
        }

        public static get(clientID: number): JQueryDeferred<any> {
            var $result = $.Deferred();
            CRM.APIs.Contacts.get_client_contacts_by_clientid(clientID).done((contacts) => { $result.resolve(contacts); });
            return $result;
        }
    }

}