module mW {

    export class handlebars {

        static bind(template: any, object: any, $container: JQuery, effect?: string): JQueryDeferred<any> {

            var result = $.Deferred<any>();
            var compiledTemplate = template(object);
            
            switch (effect) {
                case 'slidedown':
                    $container.hide();
                    result.resolve($container.html(compiledTemplate).slideDown());
                    break;
                case 'slideup':
                    $container.hide();
                    result.resolve($container.html(compiledTemplate).slideUp());
                    break;
                case 'fadein':
                    $container.hide();
                    result.resolve($container.html(compiledTemplate).fadeIn());
                    break;
                default:
                    result.resolve($container.html(compiledTemplate));

            }
            return result;
        }

        static html(template: any, object: any): string {
            return template(object);
        }

    }

}