namespace CRM.APIs {

    export class ClientUsers {

        private static _routePrefix: string = '/api/CRM/ClientUser/';

        static getUserClients(extUserID: number): JQueryDeferred<any> {
            var $results = $.Deferred();
            $.get(this._routePrefix + 'GetUserClients?ExtUserID=' + extUserID).done((clients) => {
                $results.resolve(clients);
            });
            return $results;
        }

        static deleteClientUser(clientUserID: number, userID: string): JQueryDeferred<any> {
            var $results = $.Deferred();
            mW.io.post(this._routePrefix + 'DeleteClientUser?ClientUserID=' + clientUserID + '&UserID=' + userID).done(() => {
                $results.resolve(true);
            });
            return $results;
        }
    }

}