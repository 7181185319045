declare module CHSI.Security {
    export function HasPermission(Options: any): any;
};

namespace CRM.ViewModels {

    export class user_Demographics_Edit {

        ClientID: number;
        DemoGrid: GUI.Tables.DTables = new GUI.Tables.DTables;

        init(clientID: number): void {
            this.ClientID = +clientID;                                
            this.get(true).done(() => {                           
                this.addFieldType();
                this.addStatus();
                this.addEditColumn();
                this.bindGrid().done(() => {
                    this.hasPermission();
                    this.nonGridEvents();
                    this.events();
                });                                
            });                        
        }

        nonGridEvents(): void {

            $('#addDemo').on('click', () => {
                this.openEdit(null);
            });

            $("input[name='demoStatus']").on('change', (e) => {
                this.refresh();
            });        

            $('#saveDemo').on('click', () => {
                this.save();
            });

        }

        events(): void {            
            
            $('#demoDataTableContainer tbody').on('click', '.editDemo', (e) => {
                this.openEdit($(e.target).data('addtypeid'));
            });            

            $('#demoDataTableContainer tbody').on('change', '.demoElement', (e) => {                
                $(e.target).data('ischanged', true);
                $('#saveDemo').prop('disabled', false);
            });

            $('#demoDataTableContainer tbody').on('keyup', '.demoInput', (e) => {
                $(e.target).data('ischanged', true);
                $('#saveDemo').prop('disabled', false);
            });

            $('#demoDataTableContainer tbody').on('keyup', '.demoURL', (e) => {
                var isValidURL = ($(e.target).val().indexOf("http://", 0) >= 0 || $(e.target).val().indexOf("www.", 0) >= 0);
                if (!isValidURL) {
                    $(e.target).val('www.' + $(e.target).val());    
                }                
            });

            
        }

        hasPermission(): void {
            var Options = {
                PermissionID: 18,
                Success: function (hasPermission) {
                    if (hasPermission) {
                        $('#addDemo, .editDemo').show();
                    } else {
                        $('#addDemo, .editDemo').hide();
                    }

                }
            };
            CHSI.Security.HasPermission(Options); 
        }

        get(showActiveOnly: boolean): JQueryDeferred<any> {
            var $result = $.Deferred();
            new CRM.Models.ClientAddType().getClientAddTypes(this.ClientID, showActiveOnly).done((addTypes) => {
                this.DemoGrid.data = addTypes;
            $result.resolve(true);
            });
            return $result;
        }

        refresh(): void {
            var isActive = false;

            if ($('#demoStatusAll').is(':checked')) {
                isActive = false;
            } else {
                isActive = true;
            }
                            
            this.get(isActive).done(() => {
                this.addFieldType();
                this.addStatus();
                this.addEditColumn();
                this.DemoGrid.refreshTable();
                this.hasPermission();
                this.events();
            });
        }

        save(): JQueryDeferred<any> {            
            var demographics: CRM.Models.ClientAddInfo[] = new Array<CRM.Models.ClientAddInfo>(), $result = $.Deferred(), _self = this;

            $('.demoElement').each(function (i, e) {
                if($(this).data('ischanged') == true) {                                    
                    var demo = new CRM.Models.ClientAddInfo({ ClientID: _self.ClientID, AddTypeID: $(this).data('addtypeid'), DetailValue: $(this).val() });
                    demographics.push(demo);
                    $(this).data('ischanged', false);
                }
            });

            CRM.Models.ClientAddInfo.saveAll(demographics).done(() => {
                $('#saveDemo').prop('disabled', true);
                $result.resolve(true);
            });

            return $result;
        }

        addFieldType(): void {
            var _self = this;
            if (this.DemoGrid.data) {
                var Column = new GUI.Smart.SmartColumnObject();
                Column.title = 'Value',
                Column.data = null;
                Column.orderable = false;
                Column.class = 'details-control';
                Column.render = function (data, type, row) {
                    return _self.configureFieldType(+data[0], data[12], data[8], data[3], data[7]);
                }
                this.DemoGrid.data.Columns.push(Column);
            }
        }

        addStatus(): void {
            var _self = this;
            if (this.DemoGrid.data) {
                var Column = new GUI.Smart.SmartColumnObject();
                Column.title = 'Status',
                    Column.data = null;
                Column.orderable = true;
                Column.class = 'details-control';
                Column.render = function (data, type, row) {
                    return data[4];
                }
                this.DemoGrid.data.Columns.push(Column);
            }
        }

        configureFieldType(addtypeID: number, fieldType: string, value: string, savedValue: string, isRequired: string): string {
            var html = [];
            switch (fieldType.toLowerCase()) {
                case 'text':
                case 'textbox':
                case 'url':
                    var isURL = fieldType == 'url' ? ' demoURL' : '';
                    html.push('<input type="text" class="demoElement demoInput' + isURL + '" data-ischanged="false" data-addtypeid="' + addtypeID + '" value="' + savedValue + '" />');
                    break;
                case 'sqldropdown':
                case 'dropdown':
                    var splitValues = value.split(','), isSelected = '';
                    html.push('<select class="demoElement" data-ischanged="false" data-addtypeid="' + addtypeID + '"><option></option>');
                    splitValues.forEach((splitValue) => {
                        isSelected = splitValue == savedValue ? ' selected' : '';                        
                        html.push('<option value="' + splitValue + '"' + isSelected + '>' + splitValue + '</option>');
                    });
                    html.push('</select>');
                    break;
                case 'number':
                    html.push('<input type="number" class="demoElement demoInput" data-ischanged="false" data-addtypeid="' + addtypeID + '" value="' + savedValue + '" />');
                    break;                
            }
            if (isRequired == 'Y') {
                html.push('<span class="isRequiredField">*</span>');
            }
            return html.join('');
        }

        openEdit(addTypeID: number, clickEvent?: any): void {
            var url = '/SIG/Groups/Modals/ClientDemoGraphicEdit.aspx?AddTypeID=' + addTypeID;
            var showPopUP = new GUI.Windows.Popup();
            showPopUP.URL = url;
            showPopUP.WindowID = 'editdemographics';
            showPopUP.Title = 'Edit Demographics';
            showPopUP.Width = 330;
            showPopUP.Height = 280;
            showPopUP.isParent = true;
            showPopUP.SuccessFunction = () => { this.refresh() };            
            showPopUP.showIFrame(clickEvent);

        }

        addEditColumn(): void {
            if (this.DemoGrid.data) {
                var Column = new GUI.Smart.SmartColumnObject();                
                Column.data = null;
                Column.orderable = false;
                Column.class = 'details-control';
                Column.defaultContent = '[<a href="#" class="editDemo minilink">Edit</a>]';
                Column.render = function (data, type, row) {
                    return '<a class="editDemo minilink" data-addtypeid="' + data[0] + '" title="AddTypeID "' + data[0] + '">[Edit]</a>';
                }
                this.DemoGrid.data.Columns.push(Column);
            }
        }

        bindGrid(): JQueryDeferred<any> {
            var $result = $.Deferred();
            this.DemoGrid.columnSearch = false;
            this.DemoGrid.dtObject.showEntryDropDown = false;
            this.DemoGrid.dtObject.displayLength = 16;            
            this.DemoGrid.dtObject.defaultSortColumn = [[7, "desc"],[6, "asc"]];
            this.DemoGrid.containerName = 'demoDataTableContainer';
            this.DemoGrid.tableName = 'demoDataTableGrid';
            this.DemoGrid.buildTable();
            return $result.resolve(true);
        } 

    }

}