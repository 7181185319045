module Integrations.Otis.API {
    export class OtisAPI {
        static getSSOLinkResponse(): JQueryDeferred<any> {            
            var $result = $.Deferred<any>();
            var URL = '/API/Integrations/Otis/GetSSOLink';
            $.get(URL).done((data) => {                
                $result.resolve(data);
            });
            return $result;
        }

        static getSSOAdminLinkResponse(): JQueryDeferred<any> {
            var $result = $.Deferred<any>();
            var URL = '/API/Integrations/Otis/GetSSOAdminLink';
            $.get(URL).done((data) => {
                $result.resolve(data);
            });
            return $result;
        }
    }
} 