namespace Que.Forms.Models {

    export class Questionnaire_Admintool_Base_Model {

        Questionnaire_Editor: Que.Forms.ViewModels.edit_Questionnaire_Form_Editor;
        Form_View: Que.Forms.ViewModels.edit_Questionnaire_Form;
        Form_Sub_Editors: Que.Forms.ViewModels.edit_Questionnaire_Form_Sub_Editors;
        Section_View: Que.Forms.ViewModels.edit_Questionnaire_Form_Sections;
        Element_View: Que.Forms.ViewModels.edit_Questionnaire_Form_Elements;
        Element_Sub_Editors: Que.Forms.ViewModels.edit_Questionnaire_Form_Elements_Sub_Editors;
        Preview_View: Que.Forms.ViewModels.edit_Questionnaire_Form_Preview;
        Schema: Que.ResultSets.Models.Schema[];
        Triggers: Que.Forms.Models.FormStatusTrigger[];
        Data: Que.Forms.Models.QuestionnaireWSYIWYG;
        isGrid: boolean;
        isNew: boolean;
        
        constructor(base: Que.Forms.Interfaces.IQuestionnaire_Admintool_Base_Model) {

            this.Questionnaire_Editor = base.Questionnaire_Editor;
            this.Form_View = base.Form_View;
            this.Form_Sub_Editors = base.Form_Sub_Editors;
            this.Section_View = base.Section_View;
            this.Element_View = base.Element_View;
            this.Element_Sub_Editors = base.Element_Sub_Editors;
            this.Preview_View = base.Preview_View;
            this.Schema = base.Schema;
            this.Triggers = base.Triggers;
            this.Data = base.Data;

        }

    }

}