namespace Shared.Models {

    export class HTML_Widget extends Shared.Models.Abstract_Widget {

        HTML: string;

        constructor(widget?: Shared.Interfaces.IAbstract_Widget) {
            if (widget == null) widget = {};
            super(widget);
            this.HTML = widget.HTML;
        }

        save(): number {
            throw Error('Not implemented.');

        }

    }

}