namespace mW {

    export class data {

        static generate_random_data(dataType: mW.enums.DataType, metaOperator?: string): any {
            var data = null;
            switch (dataType) {
                case mW.enums.DataType.STRING:
                    data = this.generateStringData(metaOperator);
                    break;
                case mW.enums.DataType.DATETIME:
                    data = this.generateDateData();
                    break;
                case mW.enums.DataType.DECIMAL:
                    data = this.generateDecimalData(metaOperator);             
                    break;
                case mW.enums.DataType.BIT:
                    data = this.generateBooleanDate();
                    break;
                case mW.enums.DataType.INTEGER:     
                    data = this.generateIntegerData(metaOperator);
                    break;
            }
            return data;
        }

        private static generateStringData(metaOperator?: string): string {
            var names = ['Bob', 'Dave', 'Richard', 'Sam', 'Trevor', 'Urias', 'Antonio', 'Lee', 'Jim', 'Mike', 'Colin', 'Daniel', 'Peggy', 'Megan', 'Leticia', 'Jade', 'Oz', 'Tober', 'John', 'Bill', 'Charlie', 'Graham', 'Joe', 'LeAnne', 'Tito', 'Conner', 'Lydon', 'Sandy', 'Joe', 'Brendan', 'Bryan'],
                otherWords = ['Fire Fighter', 'Air Plane', 'Police Officer', 'Accident', 'K9', 'Motor Vehicle', 'Resuce Unit', 'Property', 'Auto', 'Farm', 'Farmer', 'Cop', 'Truck Driver', 'Pedestrian', 'Incident', 'General Information', 'Nevada', 'California', 'Florida', 'Minnesota', 'Alabama', 'Coffee', 'Food', 'Bears', 'Lava', 'Values', 'CHSI', 'Commitment', 'Honesty', 'Service', 'Innovation', 'Hospital', 'School', 'Education'],
                allWords = names.concat(otherWords),
                rnd = null, acceptedOperators = ['member', 'officer', 'policy holder', 'client', 'claiment', 'employee', 'status', 'user'], selectedList = null;

            switch (this.filterFormatOperator(metaOperator, acceptedOperators)) {
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    selectedList = names;
                    rnd = Math.floor((Math.random() * names.length) + 0);
                    break;
                case 6:
                    selectedList = ['Active', 'Inactive', 'Deleted', 'Pending'];
                    rnd = Math.floor((Math.random() * 4) + 0);
                    break;
                default:
                    selectedList = otherWords;
                    rnd = Math.floor((Math.random() * otherWords.length) + 0)                    
            }
            return selectedList[rnd];
        }

        private static generateDateData(): string {            
            var day = Math.floor(Math.random() * (30 - 1 + 1) + 1),
                month = Math.floor(Math.random() * (11 - 1 + 1) + 1),
                year = Math.floor(Math.random() * (moment().year() - 1990 + 1) + 1990);
            return moment(month + '/' + day + '/' + year).format('MM/DD/YYYY');
        }

        private static generateDecimalData(metaOperator?: string): string {            
            var acceptedOperators = ['payroll', 'money', 'fte', 'fine', 'fee', 'cost'],
                rnd = Math.floor((Math.random() * 9999) + 1) + '.' + Math.floor((Math.random() * 99) + 1), result = null;            
            switch (this.filterFormatOperator(metaOperator, acceptedOperators)) {
                case 0:
                case 1:
                case 3:
                case 4:
                case 5:
                    result = '$' + rnd;
                    break;
                default:
                    result = rnd;
            }
            return result;            
        }

        private static generateBooleanDate(): boolean {
            var rnd = Math.floor(Math.random() * 10);
            return rnd % 2 === 0 ? true : false;
        }

        private static generateIntegerData(formatOperator?: string): number {
            var acceptedOperators = ['payroll', 'money', 'fte', 'year', 'policyperiod'],
                result = null,
                index = this.filterFormatOperator(formatOperator, acceptedOperators);
                switch (index) {
                    case 0:
                    case 1:                    
                        result = '$' + Math.floor((Math.random() * 9999) + 1);
                        break;
                    case 3:
                    case 4:
                        result = Math.floor(Math.random() * (moment().year() - 1990 + 1) + 1990);
                        break;
                    default:
                        result = Math.floor((Math.random() * 99999) + 1);
                }
                
            return result;
        }

        static filterFormatOperator(metaOperator: string, list: any[]): number {
            var index = null;
            if (metaOperator != undefined && metaOperator != null) {                
                var splitOperator = metaOperator.split(' ');
                metaOperator = metaOperator.toLowerCase();
                for (var i = 0; i < splitOperator.length; i++) {

                    if (list.indexOf(splitOperator[i]) > -1) {
                        index = list.indexOf(splitOperator[i]);
                        break;
                    }

                }
            } else {                
                index = -1;
                //console.warn('no meta operator value was provided. default is -1.');
            }            
            return index;
        }

    }

}