namespace Que.Forms.Models {

    export class ElementHelpDetails {

        HelpID: number;
        ElementID: number;
        HelpText: string;
        Status: string;
        CloseOnBlur: boolean;
        OverrideWidth: number;
        OverrideHeight: number;
        CreateUser: string;
        CreateTmsp: string | Date;
        UpdateUser: string;
        UpdateTmsp: string | Date;

        constructor(help?: Que.Forms.Interfaces.IElementHelpDetails) {

            this.HelpID = help == undefined ? null : help.HelpID;
            this.ElementID = help == undefined ? null : help.ElementID;
            this.HelpText = help == undefined ? null : help.HelpText;
            this.Status = help == undefined ? null : help.Status;
            this.CloseOnBlur = help == undefined ? null : help.CloseOnBlur;
            this.OverrideWidth = help == undefined ? null : help.OverrideWidth;
            this.OverrideHeight = help == undefined ? null : help.OverrideHeight;
            this.CreateUser = help == undefined ? null : help.CreateUser;
            this.CreateTmsp = help == undefined ? null : help.CreateTmsp;
            this.UpdateUser = help == undefined ? null : help.UpdateUser;
            this.UpdateTmsp = help == undefined ? null : help.UpdateTmsp;

        }

        get(): void {

        }

        save(): JQueryDeferred<number> {
            var $result = $.Deferred();
            Que.Forms.APIs.WYSIWYG_Repo.saveElementHelpDetails(this).done((helpID) => {
                $result.resolve(helpID);
            });
            return $result;
        }

    }

}