namespace CRM.Models {

    export class ClientTypes {

        ClientTypeName: string;
        MemberAccess: string;
        BrokerAccess: string;
        PartnerAccess: string;
        IconImage: string;
        OriginalClientTypeName: string;

        constructor(clientType?: CRM.Interfaces.IClientTypes) {
            if (clientType == null) { clientType = {}; }
            this.ClientTypeName = clientType.ClientTypeName;
            this.MemberAccess = clientType.MemberAccess;
            this.BrokerAccess = clientType.BrokerAccess;
            this.PartnerAccess = clientType.PartnerAccess;
            this.IconImage = clientType.IconImage;
            this.OriginalClientTypeName = clientType.OriginalClientTypeName;
        }

        public static get(): JQueryDeferred<any> {
            var $result = $.Deferred();
            CRM.APIs.ClientTypes.get_client_types().done((data) => { $result.resolve(data); });
            return $result;
        }

        save(): JQueryDeferred<any> {
            var $result = $.Deferred();
            CRM.APIs.ClientTypes.save_client_type(this).done(() => { $result.resolve(true); });
            return $result;
        }

        delete() {
            var $result = $.Deferred();
            CRM.APIs.ClientTypes.delete_client_type(this.OriginalClientTypeName).done(() => { $result.resolve(true); });
            return $result;
        }

    }

}