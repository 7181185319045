var showPopUP;
namespace Que.Forms.ViewModels {

    export class edit_Questionnaire_Form_Editor {

        Editor: Que.Forms.Models.Questionnaire_Admintool_Base_Model;

        constructor() {
            this.Editor = new Que.Forms.Models.Questionnaire_Admintool_Base_Model({
                //Questionnaire_Editor: Que.Forms.ViewModels.edit_Questionnaire_Form_Editor,
                Questionnaire_Editor: this,
                Form_View: new Que.Forms.ViewModels.edit_Questionnaire_Form,
                Form_Sub_Editors: new Que.Forms.ViewModels.edit_Questionnaire_Form_Sub_Editors,
                Section_View: new Que.Forms.ViewModels.edit_Questionnaire_Form_Sections,
                Element_View: new Que.Forms.ViewModels.edit_Questionnaire_Form_Elements,
                Element_Sub_Editors: new Que.Forms.ViewModels.edit_Questionnaire_Form_Elements_Sub_Editors,
                Preview_View: new Que.Forms.ViewModels.edit_Questionnaire_Form_Preview,
                Data: new Que.Forms.Models.QuestionnaireWSYIWYG,
                Schema: new Array<Que.ResultSets.Models.Schema>(),
                Triggers: new Array<Que.Forms.Models.FormStatusTrigger>()
            });            
        }

        init(formID: number, templateID: number): void {            
            // prepareEditorContainer needs to be called before bindChildren, as it contains the container divs the children look for.
            this.configure_base_editor_class(formID, templateID);
            this.bind_children();
        }

        static prepareEditorContainer(formID: number, templateID: number): JQueryDeferred<any> {
            var $result = $.Deferred();            
            $result.resolve(mW.handlebars.html(CHSITemplates.AdminTools_Questionnaire_Editor, { FormID: formID, TemplateID: templateID }));
            return $result;
        }

        configure_base_editor_class(formID: number, templateID: number): void {
            this.Editor.Data.Form.FormID = formID;
            this.Editor.Data.Form.DesignTemplateID = templateID;

            this.Editor.isGrid = templateID == 2 ? true : false;
            this.Editor.isNew = formID == null ? true : false;
        }

        bind_children(): void {
            $.when(this.Editor.Data.get_form(), this.get_additional_data())
                .then(() => { this.process_missing_section() })
                .then(() => { this.Editor.Form_View.init(this.Editor) })
                .then(() => { this.Editor.Form_Sub_Editors.init(this.Editor) })
                .then(() => { this.Editor.Section_View.init(this.Editor) })
                .then(() => { this.Editor.Element_View.init(this.Editor) })
                .then(() => { this.Editor.Element_Sub_Editors.init(this.Editor) })
                .then(() => { this.Editor.Preview_View.init(this.Editor) })
                .done(() => {
                    this.configure_grid();
                    this.post_setup();
                });
        }

        get_additional_data(): JQueryDeferred<any> {
            var $result = $.Deferred();
            new Que.ResultSets.Models.Schema({ SchemaID: 2 }).get(true).done((_schemas) => {
                _schemas = mW.io.reconstruct_object(_schemas);
                _schemas.forEach((schema) => {
                    if (schema.Status != 'All') {
                        this.Editor.Schema.push(new Que.ResultSets.Models.Schema(schema));
                    }                    
                });

                if (!this.Editor.isNew) {
                    new Que.Forms.Models.FormStatusTrigger({ FormID: this.Editor.Data.Form.FormID }).get().done((_triggers) => {
                        _triggers.forEach((trigger) => {
                            this.Editor.Triggers.push(new Que.Forms.Models.FormStatusTrigger(trigger));
                        });
                        $result.resolve(true);
                    });
                } else {
                    $result.resolve(true);
                }
                
            });
            return $result;
        }

        post_setup(): void {            
            this.toggleButtons();    
            edit_Questionnaire_Form_Editor.bindSortable();
            //this.captureKeyStroke();
            //this.editorHintPrompt();            
            this.events();
            edit_Questionnaire_Form_Editor.toggleTabs(this.Editor.Data);
            $('#loading_questionnaire_editor').fadeOut('fast', function () {
                $('#main_questionnaire_editor_container').fadeIn('fast');
            });   
        }

        events(): void {
            
        }

        static bindSortable(): void {
            var fixHelper = function (e, ui) {
                ui.children().each(function () {
                    $(this).width($(this).width());
                });
                return ui;
            };            
            $('.sortableQuestionnaire tbody').sortable({
                containment: "parent",
                items: '.sortableOnly',
                helper: fixHelper
            }).disableSelection();
        }

        configure_grid(): void {
            if (this.Editor.isGrid) {
            // show the preview tab                
                $('[href="#preview"]').closest('li').show();
            // hide the sections tab - they can't edit or add sections anyways
                $('[href="#sections"]').closest('li').hide();
            // hide the sections filtering select on the elements tab
                $('#section_filter_container').hide();
            // if the grid form is new, add a section to so the user doesn't have to
                //if (this.WYSIWYG.Form.FormID == null) {
                //    this.WYSIWYG.Sections.push(new Models.Section({ SectionID: -1, Title: 'Enter Section Name', Status: 'Active' }));
                    
                //}                            
            }                        
        }

        process_missing_section(): void {        
            if (this.Editor.Data.Sections.length == 0) {
                this.Editor.Data.Sections.push(new Models.Section({ SectionID: -1, Title: 'Enter Section Name', Status: 'Active' }));
            }
        }

        validateUnsavedData(): boolean {
            var isValid = false, isUnsavedSections = false, isUnsavedElements: boolean[] = [], combinedSaveUnsaveResults: boolean[] = [];

            // check if there are unsaved sections
            isUnsavedSections = this.Editor.Data.Sections.some(sec => sec.SectionID < 0);

            // check if there are unsaved elements
            this.Editor.Data.Sections.forEach((section) => {

                isUnsavedElements.push(section.Elements.some(ele => ele.ElementID < 0));

            });

            // combind the results together
            isUnsavedElements.push(isUnsavedSections);
            combinedSaveUnsaveResults = isUnsavedElements;

            isValid = combinedSaveUnsaveResults.every(isUnsaved => isUnsaved == false);

            if (!isValid) {
                var c = confirm('There are unsaved changes. If you wish to save them before closing, please click "Cancel".');
                if (c) {
                    GUI.Windows.Popup.closeThisInline('#modal_questionnaireformeditor');
                    //$('#modal_questionnaireformeditor').bPopup().close()
                } else {
                    // finish markUnsavedData
                    //this.markUnsavedData(isUnsavedSections, isUnsavedElements);
                }
            }

            return isValid;
        }

        rebindAll = () => {
            var $result = $.Deferred()            
            this.Editor.Section_View.bind().done(() => {
                this.Editor.Element_View.bind().done(() => {
                    if (this.Editor.isGrid) {
                        this.Editor.Preview_View.bind().done(() => {
                            $result.resolve(true);
                        });
                    } else {
                        $result.resolve(true);
                    } 
                });
            });
            return $result;
        }

        static notifcation(message?: string, fadeOut?: boolean): void {
            var $notificaiton = $('#systemNotification');
            $notificaiton.stop();            
            if (message == (undefined || null)) {
                message = '';
            }
            if (fadeOut) {
                $notificaiton.text(message).fadeIn('fast');
                setTimeout(function () {
                    $notificaiton.fadeOut('fast', function () {
                        $notificaiton.text('');
                    });                    
                }, 3000);
            } else {                
                $notificaiton.text(message);
                $notificaiton.show();
            }            
        }

        static toggleTabs = (wsyiwyg: Models.QuestionnaireWSYIWYG) => {
            var isNew = wsyiwyg.Form.FormID == null ? true : false, isGrid = wsyiwyg.Form.DesignTemplateID == 2 ? true : false, hasSections = wsyiwyg.Sections.length > 0 ? true : false, hasPositiveSectionID = false, hasElements = false, listOfTabsToDisable = [];

            if (hasSections) {
                hasPositiveSectionID = wsyiwyg.Sections.some((section => section.SectionID > 0));
            }

            if (hasSections) {
                hasElements = wsyiwyg.Sections.some((section => section.Elements.length > 0));
            }

            // lets just always disable all tabs and re-enable them as the user progresses or makes gains in the editor
            
            listOfTabsToDisable.push(1, 2, 3);

            if (!isNew) {
                if (hasSections) {
                    enableTab(1);
                }

                if (hasPositiveSectionID) {
                    enableTab(2);
                }

                if (hasElements) {
                    enableTab(3);
                }
            }            

            function enableTab(tabID: number) {
                for (var i = 0; i < listOfTabsToDisable.length; i++) {
                    if (listOfTabsToDisable[i] == tabID) {
                        listOfTabsToDisable.splice(i, 1);
                        break;
                    }
                }
            }

            $('#questionniare_editor_tabs').tabs("option", "disabled", listOfTabsToDisable);
        }        

        static toggle_disable_tabs =(tabs: number[]) => {
            $('#questionniare_editor_tabs').tabs("option", "disabled", tabs);
        }

        toggleButtons(): void {
            $('#questionniare_editor_tabs').tabs().on("tabsshow", (event, ui) => {
                $('#editAdd').removeClass('sectionAdd elementAdd');
                $('#editSave').removeClass('formSave sectionSave elementSave');
                $('#editCancel').removeClass('formCancel sectionCancel elementCancel previewCancel');
                $('#editAdd, #editSave, #editCancel').show();                
                $('#gridPreview, #preview_information_container').hide();
                switch (ui.index) {
                    case 0:
                        $('#editAdd').hide();
                        $('#editSave').addClass('formSave');
                        $('#editCancel').addClass('formCancel');
                        break;
                    case 1:
                        if (this.Editor.isGrid) {
                            $('#editAdd').hide();
                        } else {
                            $('#editAdd').addClass('sectionAdd');
                        }                        
                        $('#editSave').addClass('sectionSave');
                        $('#editCancel').addClass('sectionCancel');
                        break;
                    case 2:
                        $('#editAdd').addClass('elementAdd');
                        $('#editSave').addClass('elementSave');
                        $('#editCancel').addClass('elementCancel');
                        break;
                    case 3:
                        $('#editAdd, #editSave').hide();
                        $('#editCancel').hide();
                        $('#gridPreview, #preview_information_container').show();
                        break;
                }

            });

        }

        captureKeyStroke(): void {

            $(document).keypress((e) => {
                var keyCode = e.keyCode;

                switch (keyCode) {
                    case 13: /* Enter */
                        $('.formSave').trigger('click');
                        $('.sectionSave').trigger('click');
                        $('.elementSave').trigger('click');
                        break;
                }

            });

        }

        editorHintPrompt(): void {
            // if we want to create some kind of interval we can, this is really just a test to see if anyone likes this sort of thing.
            var listOfHints = [
                'Hitting Enter on your keyboard on any tab during the editing process will always validate and save your current work for that tab.',
                'When adding new Elements, you cannot select a new Section via the Section Filter on the Elements tab unless you save newly added or edited elements.',
                'When creating a new form or just editing an existing one, you can click Add on the Sections tab multiple times to quickly create new Sections. ',
                'When creating a new form or just editing an existing one, you can click Add on the Elements tab multiple times to quickly create new Elements. '
            ];
            var randHint = listOfHints[Math.floor(Math.random() * listOfHints.length)];

            $('#form_editor_hint_container > h3').html(randHint);
            $('#form_editor_hint_container').delay(30000).fadeOut('fast');            

        }

    }

}