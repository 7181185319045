var string_formatter = (<any>String).__Format;

interface JQuery {
    qtip(options?: any): JQuery;
}

namespace Que.Forms.ViewModels {

    export class edit_Questionnaire_Form_Elements_Sub_Editors {

        Editor: Que.Forms.Models.Questionnaire_Admintool_Base_Model;   

        init(editor: Que.Forms.Models.Questionnaire_Admintool_Base_Model): JQueryDeferred<any> {
            var $result = $.Deferred();  
            this.Editor = editor;
            this.bind();
            this.events();            
            return $result.resolve(true);
        }

        bind(): void {
            $('#editor').html(mW.handlebars.html(CHSITemplates.AdminTools_Questionnaire_Editor_Element_Mini_Editors, this.Editor.Data));
        }

        events(): void {

            //#region tool tip        
            $('#help_title').on('keyup', (e) => {
                var elementID = $('#update_and_return').data('elementid'), help = this.Editor.Element_View.get_element(elementID).Help;
                $('#help_icon').attr('title', $(e.target).val());
                this.Editor.Element_View.update(elementID, 'Help.HelpText', $(e.target).val());
                // we have to maintain the height and width of the tooltip container as we update it                
                $('.ui-tooltip-default').css({ 'width': help.OverrideWidth, 'height': help.OverrideHeight });
            });

            $('#help_title').on('focus', (e) => {
                if ($(e.target).val() == 'Enter help text') {
                    $(e.target).val('');
                }
            });

            $('#help_override_width').on('change', (e) => {
                var elementID = $('#update_and_return').data('elementid'), $width = $(e.target);
                if ($width.val() < 50) {
                    $width.val('22');
                }
                if (+$width.val() > 280) {
                    $width.val('280');
                }
                $('.ui-tooltip-default').css('width', +$width.val());
                this.Editor.Element_View.update(elementID, 'Help.OverrideWidth', $(e.target).val());
            });

            $('#clear_override_width').on('click', (e) => {
                var elementID = $('#update_and_return').data('elementid'), $width = $(e.target);
                $('.ui-tooltip-default').css('width', '');
                $('#help_override_width').val('');
                this.Editor.Element_View.update(elementID, 'Help.OverrideWidth', null);
            });

            $('#help_override_height').on('change', (e) => {
                var elementID = $('#update_and_return').data('elementid'), $height = $(e.target);
                if (+$height.val() < 22) {
                    $height.val('22');
                }
                if (+$height.val() > 230) {
                    $height.val('230');
                }
                $('.ui-tooltip-default').css('height', +$height.val());
                this.Editor.Element_View.update(elementID, 'Help.OverrideHeight', $(e.target).val());
            });

            $('#clear_override_height').on('click', (e) => {
                var elementID = $('#update_and_return').data('elementid'), $width = $(e.target);
                $('.ui-tooltip-default').css('height', '');
                $('#help_override_height').val('');
                this.Editor.Element_View.update(elementID, 'Help.OverrideHeight', null);
            });

            $('#help_status').on('change', (e) => {
                var elementID = $('#update_and_return').data('elementid'), $height = $(e.target);
                this.Editor.Element_View.update(elementID, 'Help.Status', $(e.target).val());
            });

            $('#help_closeonblur').on('change', (e) => {
                var elementID = $('#update_and_return').data('elementid'), $height = $(e.target);
                this.Editor.Element_View.update(elementID, 'Help.CloseOnBlur', $(e.target).val());
            });
            //#endregion

            //#region footer calculate
            $('#calculate_format').on('keyup', (e) => {
                this.calculate_editor_total_value($('#calculation_type').val() == 'sum' ? true : false);                
                //this.calculate_editor_apply_format(total);
            });

            $('#calculation_type').on('change', (e) => {
                this.calculate_editor_total_value($(e.target).val() == 'sum' ? true : false);
            });

            $('#calculation_value_type').on('change', (e) => {
                this.calculate_editor_generate_data(+$(e.target).val());
                this.calculate_editor_total_value($('#calculation_type').val() == 'sum' ? true : false);
            });

            $('#calcualation_new_data').on('click', (e) => {
            // default to integer if custom is selected in the generate values drop down
                var valueType = ($('#calculation_value_type').val() == 'custom' ? 5 : $('#calculation_value_type').val());
                $('#calculation_value_type').val(valueType);
                this.calculate_editor_generate_data(+valueType);
                this.calculate_editor_total_value($('#calculation_type').val() == 'sum' ? true : false);
            });

            $('#calculate_container').on('keyup', '.element_grid_value', (e) => {
                this.calculate_editor_total_value($('#calculation_type').val() == 'sum' ? true : false);
                $('#calculation_value_type').val('custom');
            });

            $('#calculate_container').on('mouseover', '.dynamic_element_calc_input_container', function () {
                $(this).find('.clear_dynamic_calculation_input').show();
            });

            $('#calculate_container').on('mouseout', '.dynamic_element_calc_input_container', function () {
                $(this).find('.clear_dynamic_calculation_input').hide();
            });

            $('#calculate_container').on('click', '.clear_dynamic_calculation_input', (e) => {
                $(e.target).parent().find('.element_grid_value').val('');
                $('#calculation_value_type').val('custom');
                this.calculate_editor_total_value($('#calculation_type').val() == 'sum' ? true : false);
                //this.calculate_editor_apply_format(total);
            });

        //#endregion
        }

        select_editor($editorID: string): any {
            var init = null;
            switch ($editorID) {
                case '#html_editor':
                    init = this.toggle_html_editor;
                    break;                
                case '#tooltip_editor':
                    init = this.toggle_tooltip_editor;
                    break;
                case '#calculate_editor':
                    init = this.toggle_calculate_editor;
                    break;
                default:
                    // return to element editor
            }
            return init;
        }

        toggle_editor_mode(editorStatus: Que.Forms.Enums.Element_Sub_Editor_Status, $editorID: string, elementID: number): void {
            var $returnToEditor: JQuery = $('#update_and_return'),
                $verifiedEditorID = $editorID == null ? $returnToEditor.data('editorid') : $editorID,
                $editorContainer: JQuery = $('#editor_container'),
                $parentContainer: JQuery = $('#main_questionnaire_editor_container'),                
                element = this.Editor.Element_View.get_element(elementID == null ? $returnToEditor.data('elementid') : elementID),
                $currentEditor: JQuery = $($verifiedEditorID),
                init = this.select_editor($verifiedEditorID);
            
            if (editorStatus == Que.Forms.Enums.Element_Sub_Editor_Status.SHOW_SUB_EDITOR) {
                $parentContainer.fadeOut('fast', () => {
                    $returnToEditor.data('elementid', element.ElementID).data('editor-status', editorStatus).data('editorid', $editorID);
                    $currentEditor.show();                                        
                    $editorContainer.fadeIn('fast', () => {
                        init(element, editorStatus);
                    });
                });
            }

            if (editorStatus == Que.Forms.Enums.Element_Sub_Editor_Status.SAVE_RETURN || editorStatus == Que.Forms.Enums.Element_Sub_Editor_Status.CANCEL_RETURN) {
                $editorContainer.fadeOut('fast', () => {
                    $returnToEditor.data('elementid', null).data('editor-status', editorStatus).data('editorid', null);
                    $currentEditor.hide();
                    init(element, editorStatus);
                    $parentContainer.fadeIn('fast');
                    this.Editor.Element_View.bind().done(() => {
                        this.Editor.Element_View.Elements.forEach((ele) => {
                            this.Editor.Element_View.toggle_element_change_notifications(ele);
                        });
                    });                    
                });
            }            

        }
        
        toggle_html_editor = (element: Que.Forms.Models.Element): void => {
            var editorStatus = $('#update_and_return').data('editor-status');
            if (editorStatus == Que.Forms.Enums.Element_Sub_Editor_Status.SHOW_SUB_EDITOR) {
                CKEDITOR.replace('html_editor', { height: "345" });
                CKEDITOR.instances['html_editor'].setData(element.Label);
            } else {
                var editor: CKEDITOR.editor = CKEDITOR.instances['html_editor'];
                if (editor) {
                    if (editorStatus == Que.Forms.Enums.Element_Sub_Editor_Status.SAVE_RETURN) {
                        this.Editor.Element_View.update(element.ElementID, 'Label', (editor.getData() == '' ? null : editor.getData()));
                    }                
                    editor.destroy(true);
                }
            }
        }

        toggle_tooltip_editor = (element: Que.Forms.Models.Element): void => {

            var editorStatus = $('#update_and_return').data('editor-status');

            if (editorStatus == Que.Forms.Enums.Element_Sub_Editor_Status.SHOW_SUB_EDITOR) {

                var help = element.Help, helpText = (element.Help.HelpText != null && element.Help.HelpText != '') ? element.Help.HelpText : 'Enter help text';
                // we need some kind of temp key so we just use the elements to generate the id
                if (help.HelpID == null) {
                    help.HelpID = mW.helpers.create_temp_identifier_key_by_array(this.Editor.Data.Sections[0].Elements, 'ElementID');
                }
                $('#help_title').val(helpText);
                $('#help_icon').attr('title', helpText);
                $('#help_status').val(help.Status);
                $('#help_override_width').val(String(help.OverrideWidth));
                $('#help_override_height').val(String(help.OverrideHeight));
                $('#help_icon').qtip({
                    show: {
                        ready: true
                        }, hide: {
                            event: false
                        },
                        style: {
                            width: (help.OverrideWidth),
                            height: (help.OverrideHeight)
                        }
                    });

            } else {

                var help = element.Help;                

                $('#help_icon').qtip('hide');

                if (editorStatus == Que.Forms.Enums.Element_Sub_Editor_Status.CANCEL_RETURN) {
                    // undo any changes
                    this.undo(element.ElementID, 'Help');
                }

            }
        }

        toggle_calculate_editor = (element: Que.Forms.Models.Element): void => {

            var editorStatus = $('#update_and_return').data('editor-status');

            var html = [], rnd = Math.floor((Math.random() * 10) + 3), value = 0;

            if (editorStatus == Que.Forms.Enums.Element_Sub_Editor_Status.SHOW_SUB_EDITOR) {                
                //html.push('<span class="bold_editor_label">Example Grid Result: </span>');
                $('#calculation_type').val('count');
                $('#calculation_value_type').val('5');

                html.push('<table>');

                for (var x = 0; x < 10; x++) {

                    html.push('<tr><td>');

                    html.push('<div class="dynamic_element_calc_input_container"><input type="number" class="element_grid_value" value="" /><input type="button" class="clear_dynamic_calculation_input" value=" Clear " /></div>');

                    html.push('</td></tr>');

                }

                html.push('<tr><td><span id="element_grid_footer_title" class="bold_editor_label">Total: </span><span id="element_grid_footer_value"></span></td></tr>');

                html.push('</table>');

                $('#calculate_container').html(html.join(''));

                this.calculate_editor_generate_data(mW.enums.DataType.INTEGER);
                this.calculate_editor_total_value(false);

            } else {

                if (editorStatus == Que.Forms.Enums.Element_Sub_Editor_Status.SAVE_RETURN) {
                    this.Editor.Element_View.update_meta(2, $('#calculate_format').val(), element.ElementID);
                }

            }            
        }

        calculate_editor_apply_format(totalAmount: any): void {
            var $format: JQuery = $('#calculate_format'), $total: JQuery = $('#element_grid_footer_value');
            if ($format.val() != (null || '')) {
                $total.text(string_formatter($format.val(), totalAmount));
            } else {
                $total.text(totalAmount);
            }
        }

        calculate_editor_generate_data(dataType: mW.enums.DataType): void {
            $('.element_grid_value').each((i, e) => {
                $(e).val(mW.data.generate_random_data(dataType));
            });
        }

        calculate_editor_total_value(isSummed: boolean): number {
            var $allValue = $('.element_grid_value'), total: number = 0;
            if (isSummed) {
                $allValue.each((i, e) => {
                    total = total + +$(e).val();
                });
            } else {
            // we count values;
                $allValue.each((i, e) => {
                    if ($(e).val() != '' && $(e).val() != null && +$(e).val() != 0) {
                        total++;
                    }
                });
            }
            this.calculate_editor_apply_format(total);
            return total;
        }     

        undo(elementID: number, propertyToUndo?: string): void {

            var element = this.Editor.Element_View.get_element(elementID);

            for (var cloneProp in element._clone) {
                if (cloneProp != 'SortIndex' && cloneProp != '_sortHasChanged' && cloneProp != '_clone') {
                    if (cloneProp == 'MetaAttributes') {
                        element.MetaAttributes = new Array<Que.Forms.Models.MetaAttribute>();
                        element._clone.MetaAttributes.forEach((meta) => {
                            element.MetaAttributes.push($.extend({}, meta));
                        });
                    } else {
                        element[cloneProp] = element._clone[cloneProp];
                    }
                }
            }

            this.Editor.Element_View.bind().done(() => {
                this.Editor.Element_View.Elements.forEach((element) => {
                    if ((<any>element)._isExpanded) {
                        this.Editor.Element_View.bind_saved_detail_data(element, this.Editor.Element_View.$get_element(element.ElementID));
                    }
                    this.Editor.Element_View.toggle_element_change_notifications(element);
                });
            });


            if (this.Editor.Element_View.Elements.some(ele => ele._metaHasChanged == true) || this.Editor.Element_View.Elements.some(ele => ele._propertyHasChanged == true)) {
                edit_Questionnaire_Form_Editor.toggle_disable_tabs([0, 1]);
                this.Editor.Element_View.toggle_section_filter(true);
            } else { 
                edit_Questionnaire_Form_Editor.toggle_disable_tabs([]);
                this.Editor.Element_View.toggle_section_filter(false);
            }

            edit_Questionnaire_Form_Editor.notifcation('Element changes have been reverted.', true);
        }

        undo_meta(elementID: number, metaTypeID: number): void {

            var element = this.Editor.Element_View.get_element(elementID);

            //for (var cloneProp in element._clone) {
            //    if (cloneProp != 'SortIndex' && cloneProp != '_sortHasChanged' && cloneProp != '_clone') {
            //        if (cloneProp == 'MetaAttributes') {
            //            element.MetaAttributes = new Array<Que.Forms.Models.MetaAttribute>();
            //            element._clone.MetaAttributes.forEach((meta) => {
            //                element.MetaAttributes.push($.extend({}, meta));
            //            });
            //        } else {
            //            element[cloneProp] = element._clone[cloneProp];
            //        }
            //    }
            //}

            element.MetaAttributes.forEach((meta) => {
                if (meta.MetaType == metaTypeID) {
                    element._clone.MetaAttributes.forEach((meta_clone) => {
                        if (meta.MetaType == meta_clone.MetaType) {
                            meta = meta_clone;
                        }
                    });
                }
            });

            this.Editor.Element_View.bind().done(() => {
                this.Editor.Element_View.Elements.forEach((element) => {
                    if ((<any>element)._isExpanded) {
                        this.Editor.Element_View.bind_saved_detail_data(element, this.Editor.Element_View.$get_element(element.ElementID));
                    }
                    this.Editor.Element_View.toggle_element_change_notifications(element);
                });
            });


            if (this.Editor.Element_View.Elements.some(ele => ele._metaHasChanged == true) || this.Editor.Element_View.Elements.some(ele => ele._propertyHasChanged == true)) {
                edit_Questionnaire_Form_Editor.toggle_disable_tabs([0, 1]);
                this.Editor.Element_View.toggle_section_filter(true);
            } else {
                edit_Questionnaire_Form_Editor.toggle_disable_tabs([]);
                this.Editor.Element_View.toggle_section_filter(false);
            }

            edit_Questionnaire_Form_Editor.notifcation('Element changes have been reverted.', true);
        }
    }
}