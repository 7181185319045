namespace Que.Forms.Models {

    export class QuestionnaireWSYIWYG {

        Form: Que.Forms.Models.Form;        
        Sections: Que.Forms.Models.Section[];
        ContentTypes: Que.Forms.Models.ContentType[];
        ValueList: Que.Forms.Models.ValueList[];
        DataStructures: Que.DataStructures.Models.DataStructures[];
        DataStructureCategories: Que.DataStructures.Models.DataStructureCategory[];
        _DataStructureColunns: Que.DataStructures.Models.DataStructureColumn[];

        constructor(questionnaire?: Interfaces.IQuestionnaireQYSIWYG) {
            this.Form = questionnaire == undefined ? new Que.Forms.Models.Form() : new Que.Forms.Models.Form(questionnaire.Form);    
            this.Sections = new Array<Que.Forms.Models.Section>();
            if (questionnaire != undefined && questionnaire.Sections != undefined) {
                questionnaire.Sections.forEach((secO) => {
                    this.Sections.push(new Que.Forms.Models.Section(secO));
                });
            }
            this.ContentTypes = new Array<Que.Forms.Models.ContentType>();
            if (questionnaire != undefined && questionnaire.ContentTypes != undefined) {
                questionnaire.ContentTypes.forEach((conO) => {
                    this.ContentTypes.push(new Que.Forms.Models.ContentType(conO));
                });
            }
            this.ValueList = new Array<Que.Forms.Models.ValueList>();
            if (questionnaire != undefined && questionnaire.ValueList != undefined) {
                questionnaire.ValueList.forEach((valO) => {
                    this.ValueList.push(new Que.Forms.Models.ValueList(valO));
                });
            }
            this.DataStructureCategories = new Array<Que.DataStructures.Models.DataStructureCategory>();
            if (questionnaire != undefined && questionnaire.DataStructureCategories != undefined) {
                questionnaire.DataStructureCategories.forEach((catO) => {
                    this.DataStructureCategories.push(new Que.DataStructures.Models.DataStructureCategory(catO));
                });
            }

        }

        get_form(reconstitue?: boolean): JQueryDeferred<Que.Forms.Models.QuestionnaireWSYIWYG> {
            // we should break this method down in the future
            if (reconstitue) {
                
            } else {

            }

            var $results = $.Deferred();

            if (this.Form.FormID != null) {

                Que.Forms.APIs.WYSIWYG_Repo.getCompleteFormByFormID(this.Form.FormID).done((questionnaire) => {
                    var form: Que.Forms.Models.Form = mW.io.reconstruct_object(questionnaire.Form)[0];
                    var elements: Que.Forms.Models.Element[] = mW.io.reconstruct_object(questionnaire.Elements);
                    var sections: Que.Forms.Models.Section[] = mW.io.reconstruct_object(questionnaire.Sections);
                    var metaForm: Que.Forms.Models.MetaAttribute[] = mW.io.reconstruct_object(questionnaire.FormMetaAttributes);
                    var metaElements: Que.Forms.Models.MetaAttribute[] = mW.io.reconstruct_object(questionnaire.ElementMetaAttributes);
                    var metaSections: Que.Forms.Models.MetaAttribute[] = mW.io.reconstruct_object(questionnaire.SectionMetaAttributes);
                    var dataStructureColumns: Que.DataStructures.Models.DataStructureColumn[] = mW.io.reconstruct_object(questionnaire.DataStructureColumns);
                    var dataStructureCategories: Que.DataStructures.Models.DataStructureCategory[] = mW.io.reconstruct_object(questionnaire.DataStructureCategories);
                    var valueList: Que.Forms.Models.ValueList[] = mW.io.reconstruct_object(questionnaire.ValueList);
                    var contentType: Que.Forms.Models.ContentType[] = mW.io.reconstruct_object(questionnaire.ContentType);
                    var datastructures: DataStructures.Models.DataStructures[] = mW.io.reconstruct_object(questionnaire.DataStructures);
                    var elementHelps: Que.Forms.Models.ElementHelpDetails[] = mW.io.reconstruct_object(questionnaire.ElementsHelpDetails);

                    this.DataStructures = new Array<DataStructures.Models.DataStructures>();

                    datastructures.forEach((structure) => {
                        this.DataStructures.push(new DataStructures.Models.DataStructures(structure));
                    });                    

                    form.MetaAttributes = metaForm;

                    this.Form = new Que.Forms.Models.Form(form);
                    this._DataStructureColunns = dataStructureColumns;

                    sections.forEach((secO) => {

                        secO.Elements = new Array<Que.Forms.Models.Element>();
                        secO.MetaAttributes = new Array<Que.Forms.Models.MetaAttribute>();

                        metaSections.forEach((metaO) => {
                            if (secO.SectionID == metaO.ParentID) {
                                secO.MetaAttributes.push(new Que.Forms.Models.MetaAttribute(metaO));
                            }
                        });

                        elements.forEach((eleO) => {

                            eleO.MetaAttributes = new Array<Que.Forms.Models.MetaAttribute>();

                            metaElements.forEach((metaO) => {

                                if (eleO.ElementID == metaO.ParentID) {
                                    eleO.MetaAttributes.push(new Que.Forms.Models.MetaAttribute(metaO));
                                }

                            });

                            elementHelps.forEach((help) => {

                                if (help.ElementID == eleO.ElementID) {

                                    eleO.Help = new Que.Forms.Models.ElementHelpDetails(help);

                                }

                            });

                            if (secO.SectionID == eleO.FormSectionID) {

                                dataStructureColumns.forEach((colO) => {
                                    if (eleO.DataStructureColumnID == colO.ColumnID) {
                                        eleO.DataStructure = colO;
                                    }
                                });

                                secO.Elements.push(new Que.Forms.Models.Element(eleO));

                            }

                        });

                        this.Sections.push(new Que.Forms.Models.Section(secO));

                    });

                    dataStructureCategories.forEach((catO) => {
                        catO.Columns = new Array<Que.DataStructures.Models.DataStructureColumn>();
                        dataStructureColumns.forEach((colO) => {
                            if (catO.CategoryID == colO.DataCategoryID) {
                                catO.Columns.push(new Que.DataStructures.Models.DataStructureColumn(colO));
                            }
                        });

                        this.DataStructureCategories.push(new Que.DataStructures.Models.DataStructureCategory(catO));

                    });

                    valueList.forEach((valO) => {
                        this.ValueList.push(new Que.Forms.Models.ValueList(valO));
                    });

                    contentType.forEach((conO) => {
                        this.ContentTypes.push(new Que.Forms.Models.ContentType(conO));
                    });

                    $results.resolve(this);
                });

            } else {
                new DataStructures.Models.DataStructures().getDataStructure().done((dataStructures) => {
                    this.DataStructures = dataStructures;
                    $results.resolve(this);
                });                                    
            }

            return $results;
        }

        private _reconstitueForm(wsyiwyg: any): any {

            return {
            
                Form: mW.io.reconstruct_object(wsyiwyg.Form)[0],
                Elements: mW.io.reconstruct_object(wsyiwyg.Elements),
                Sections: mW.io.reconstruct_object(wsyiwyg.Sections),
                MetaForm: mW.io.reconstruct_object(wsyiwyg.FormMetaAttributes),
                MetaElements: mW.io.reconstruct_object(wsyiwyg.ElementMetaAttributes),
                MetaSections: mW.io.reconstruct_object(wsyiwyg.SectionMetaAttributes),
                DataStructures: mW.io.reconstruct_object(wsyiwyg.DataStructures),
                DataStructureColumns: mW.io.reconstruct_object(wsyiwyg.DataStructureColumns),
                DataStructureCategories: mW.io.reconstruct_object(wsyiwyg.DataStructureCategories),
                ValueLists: mW.io.reconstruct_object(wsyiwyg.ValueList),
                ContentTypes: mW.io.reconstruct_object(wsyiwyg.ContentType)

            };

        }       

        private _constructForm(form: any): void  {

            this.Form = form.Form;
            

        }

        

        getForms(structureID?: number, templateID?: number): JQueryDeferred<any> {
            if (!structureID) {
                structureID = this.Form.DataStructureID;
            }
            if (!templateID) {
                templateID = this.Form.DesignTemplateID;
            }
            var result = $.Deferred();
            Que.Forms.APIs.WYSIWYG_Repo.getForms(structureID, templateID).done((data) => {
                result.resolve(data);
            });
            return result;
        }

        saveForm(): JQueryDeferred<number> {
            var result = $.Deferred();
            Que.Forms.APIs.WYSIWYG_Repo.saveForm(this.Form).done((data) => {
                result.resolve(data);
            });
            return result;
        }

        //saveSection(section: any): JQueryDeferred<number> {
        //    var result = $.Deferred();
        //    PL.AdminTools.APIs.WYSIWYG_Repo.saveSection(section).done((data) => {
        //        result.resolve(data);
        //    });
        //    return result;
        //}

        getSections(): JQueryDeferred<Que.Forms.Models.Section> {
            var results = $.Deferred();
            Que.Forms.APIs.WYSIWYG_Repo.getSections(this.Form.FormID).done((data) => {
                results.resolve(data);
            });
            return results;
        }

        saveSections(): JQueryDeferred<any> {
        // important note - when making to any elements in the Que.Form.Models.Section array, this method only respects sections that are marked as "_hasChanged = true", please be sure to update the section object if you wanted to save.
            var $result = $.Deferred<any>(), modifiedSections: Que.Forms.Models.Section[] = new Array<Que.Forms.Models.Section>();

            this.Sections.forEach((section) => {
                section.FormID = this.Form.FormID;
                if (section._propertyHasChanged || section._metaHasChanged || section._sortHasChanged) {
                    section._propertyHasChanged = false;
                    section._metaHasChanged = false;
                    section._sortHasChanged = false;
                    modifiedSections.push(section);
                }
            });

            Que.Forms.APIs.WYSIWYG_Repo.saveSections(modifiedSections).done((data) => {
                $result.resolve(data);
            });
                   
            return $result;
        }

        deleteSection(sectionID: number): JQueryDeferred<any> {
            var $result = $.Deferred<any>();
            this._get_section(sectionID).delete().done(() => {
                this._splice_section(sectionID).done(() => {
                    $result.resolve(true);
                });                
            });
            return $result;
        }

        private _splice_section(sectionID: number): JQueryDeferred<any> {
            var $result = $.Deferred();
            this.Sections.forEach((section, i, secArry) => {
                if (section.SectionID == sectionID) {
                    secArry.splice(i, 1);
                    $result.resolve(true);
                }
            });
            return $result;
        }

        private _get_section(sectionID: number): Que.Forms.Models.Section {
            var section: Que.Forms.Models.Section = null;
            for (var i = 0; i < this.Sections.length; i++) {
                if (this.Sections[i].SectionID == sectionID) {
                    section = this.Sections[i];
                }
            }                
            return section;
        }

        //saveElement(element: any): JQueryDeferred<number> {
        //    var result = $.Deferred();
        //    PL.AdminTools.APIs.WYSIWYG_Repo.saveElement(element).done((data) => {
        //        result.resolve(data);
        //    });
        //    return result;
        //}

        //saveElements(elements: any[]): JQueryDeferred<any> {
        //    var result = $.Deferred<any>();
        //    PL.AdminTools.APIs.WYSIWYG_Repo.saveElements(elements).done((data) => {
        //        result.resolve(data);
        //    });
        //    return result;
        //}

        //getValueList(): JQueryDeferred<PL.Que.Models.Design.ValueList[]> {
        //    var result = $.Deferred<PL.Que.Models.Design.ValueList[]>();
        //    PL.AdminTools.APIs.WYSIWYG_Repo.getValueList().done((data) => {
        //        result.resolve(data);
        //    });
        //    return result;
        //}

        //getContentType(): JQueryDeferred<PL.Que.Models.Design.ContentType[]> {
        //    var result = $.Deferred<PL.Que.Models.Design.ContentType[]>();
        //    PL.AdminTools.APIs.WYSIWYG_Repo.getContentType().done((data) => {
        //        result.resolve(data);
        //    });
        //    return result;
        //}

        //getSelection(sectionID: number): PL.Que.Models.Design.Section {
        //    var section: PL.Que.Models.Design.Section;
        //    this.Sections.forEach((eO, eI) => {
        //        if (eO.SectionID == sectionID) {
        //            section = eO;
        //        }
        //    });
        //    return section;

        //}

    }

}