module Integrations.Otis.Models {
    export class OtisResponse {
        
        static singleSignOnLink: string;
        static singleSignOnAdminLink: string;

        static getSSOLink_inNewWindow(): void {            
        
            Integrations.Otis.API.OtisAPI.getSSOLinkResponse().done((data) => {
                this.parseOtisResponse(data);
                window.open(this.singleSignOnLink);
            });                       
        }

        static getSSOLink_inCurrentWindow(): void {
            Integrations.Otis.API.OtisAPI.getSSOLinkResponse().done((data) => {
                this.parseOtisResponse(data);
                window.location.href = this.singleSignOnLink;
            });                                   
        }

        static getSSOAdminLink_inNewWindow(): void {
            Integrations.Otis.API.OtisAPI.getSSOAdminLinkResponse().done((data) => {
                this.parseOtisResponse(data, 'admin');
                window.open(this.singleSignOnAdminLink);
            });                       
        }

        static getSSOAdminLink_inCurrentWindow(): void {
            Integrations.Otis.API.OtisAPI.getSSOAdminLinkResponse().done((data) => {
                this.parseOtisResponse(data, 'admin');
                window.location.href = this.singleSignOnAdminLink;
            });                       
        }

        static parseOtisResponse(jsonResponse: any, ssoSignInType?: string): void {            
            jsonResponse = JSON.parse(jsonResponse);
            switch (ssoSignInType) {
                case 'admin':                    
                    this.singleSignOnAdminLink = jsonResponse[Object.keys(jsonResponse)[0]];
                    break;
                case 'student':
                    break;
                default:
                    this.singleSignOnLink = jsonResponse['singleSignOnLink'];
            }
        }        
    }
} 