module CRM.Models {

    export class Client {

        ClientID: number;
        ClientNumber: string;
        GroupID: string;
        ClientName: string;
        DBA: string;
        LegalName: string;
        Industry: string;
        ClientType: string;
        RelatedClientNumber: string;
        PrimaryContact: string;
        AccountNumber: string;
        Phone: string;
        Phone2: string;
        Phone3: string;
        P1Ext: string;
        P2Ext: string;
        P3Ext: string;
        //P1AC: number;
        //P2AC: number;
        //P3AC: number;
        //FaxAC: number;
        Fax: string;
        ShippingAddress1: string;
        ShippingAddress2: string;
        ShippingCity: string;
        ShippingState: string;
        ShippingZip: string;
        BillingAddress1: string;
        BillingAddress2: string;
        BillingCity: string;
        BillingState: string;
        BillingZip: string;
        StartDate: Date;
        TerminationDate: Date;
        Products: string;
        RecordType: string;
        HasBilling: boolean;
        ShippingAddress: any;
        MailingAddress: any;
        BillingAddress: any;
        CreateTmsp: Date;
        CreateUser: string;
        UpdateTmsp: Date;
        UpdateUser: string;

        constructor(client?: Interfaces.IClient) {
            this.ClientID = client == undefined ? null : client.ClientID;
            this.ClientNumber = client == undefined ? null : client.ClientNumber;
            this.GroupID = client == undefined ? null : client.GroupID;
            this.ClientName = client == undefined ? null : client.ClientName;
            this.DBA = client == undefined ? null : client.DBA;
            this.LegalName = client == undefined ? null : client.LegalName;
            this.Industry = client == undefined ? null : client.Industry;
            this.ClientType = client == undefined ? null : client.ClientType;
            this.RelatedClientNumber = client == undefined ? null : client.RelatedClientNumber;
            this.PrimaryContact = client == undefined ? null : client.PrimaryContact;
            this.AccountNumber = client == undefined ? null : client.AccountNumber;
            this.Phone = client == undefined ? null : client.Phone;
            this.Phone2 = client == undefined ? null : client.Phone2;
            this.Phone3 = client == undefined ? null : client.Phone3;
            this.P1Ext = client == undefined ? null : client.P1Ext;
            this.P2Ext = client == undefined ? null : client.P2Ext;
            this.P3Ext = client == undefined ? null : client.P3Ext;
            //this.P1AC = client == undefined ? null : client.P1AC;
            //this.P2AC = client == undefined ? null : client.P2AC;
            //this.P3AC = client == undefined ? null : client.P3AC;
            //this.FaxAC = client == undefined ? null : client.FaxAC;
            this.Fax = client == undefined ? null : client.Fax;
            this.ShippingAddress1 = client == undefined ? null : client.ShippingAddress1;
            this.ShippingAddress2 = client == undefined ? null : client.ShippingAddress2;
            this.ShippingCity = client == undefined ? null : client.ShippingCity;
            this.ShippingState = client == undefined ? null : client.ShippingState;
            this.ShippingZip = client == undefined ? null : client.ShippingZip;
            this.BillingAddress1 = client == undefined ? null : client.BillingAddress1;
            this.BillingAddress2 = client == undefined ? null : client.BillingAddress2;
            this.BillingCity = client == undefined ? null : client.BillingCity;
            this.BillingState = client == undefined ? null : client.BillingState;
            this.BillingZip = client == undefined ? null : client.BillingZip;
            this.StartDate = client == undefined ? null : client.StartDate;
            this.TerminationDate = client == undefined ? null : client.TerminationDate;
            this.Products = client == undefined ? null : client.Products;
            this.RecordType = client == undefined ? null : client.RecordType;
            this.HasBilling = client == undefined ? null : client.HasBilling;
            this.ShippingAddress = client == undefined ? null : client.ShippingAddress;
            this.MailingAddress = client == undefined ? null : client.MailingAddress;
            this.BillingAddress = client == undefined ? null : client.BillingAddress;
            this.CreateTmsp = client == undefined ? null : client.CreateTmsp;
            this.CreateUser = client == undefined ? null : client.CreateUser;
            this.UpdateTmsp = client == undefined ? null : client.UpdateTmsp;
            this.UpdateUser = client == undefined ? null : client.UpdateUser;
        }

        get(): JQueryDeferred<Client> {
            var $result = $.Deferred();
            CRM.APIs.Clients.getClientInfo(this.ClientID).done((clientInformation) => {
                mW.classes.propagatePropertyValues(clientInformation, this);
                $result.resolve(clientInformation);
            });
            return $result;
        }
    }

}