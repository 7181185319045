namespace CRM.APIs {

    export class ClientAddType {

        private static _routePrefix: string = '/api/CRM/ClientAddType/';

        static getLocationAddTypes(locaitonID: number, groupID: string, scheduleID?: number): JQueryDeferred<GUI.Smart.SmartObject> {
            var $result = $.Deferred();
            $.get(this._routePrefix + 'GetLocationAddTypes?LocationID=' + locaitonID + '&GroupID=' + groupID + '&ScheduleID=' + scheduleID).done((addTypes) => {
                $result.resolve(addTypes);
            });
            return $result;
        }
        
        static getClientAddTypes(clientID: number, isActive: boolean): JQueryDeferred<GUI.Smart.SmartObject> {
            var $result = $.Deferred();
            $.get(this._routePrefix + 'GetClientAddTypes?ClientID=' + clientID + '&isActive=' + isActive).done((addTypes) => {
                $result.resolve(addTypes);
            });
            return $result;
        }

        static saveClientAddType(locaitonID: number, groupID: string, scheduleID?: number): JQueryDeferred<GUI.Smart.SmartObject> {
            var $result = $.Deferred();
            $.get(this._routePrefix + 'GetLocationAddTypes?LocationID=' + locaitonID + '&GroupID=' + groupID + '&ScheduleID=' + scheduleID).done((addTypes) => {
                $result.resolve(addTypes);
            });
            return $result;
        }

        static saveClientAddTypes(clientAddTypes: CRM.Models.ClientAddType[]): JQueryDeferred<any> {
            var $result = $.Deferred();
            mW.io.post(this._routePrefix + 'SaveClientAddTypes', clientAddTypes).done((data) => {
                $result.resolve(data);
            });
            return $result;
        }
    }

}