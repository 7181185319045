namespace Que.Forms.ViewModels {

    export class edit_Questionnaire_Form {

        Editor: Que.Forms.Models.Questionnaire_Admintool_Base_Model;
        private _showActiveTriggersOnly: boolean;

        constructor() {
            this._showActiveTriggersOnly = true;
        }

        init(editor: Que.Forms.Models.Questionnaire_Admintool_Base_Model): JQueryDeferred<any> {
            var $result = $.Deferred();
            this.Editor = editor;            
            this.handlebar_helpers();
            this.bind().done(() => {                
                this.events();                              
                $result.resolve(true);
            });
            return $result;
        }

        bind = (): JQueryDeferred<any> => {
            var $result = $.Deferred();                        
            mW.handlebars.bind(CHSITemplates.AdminTools_Questionnaire_Editor_Form, { Editor: this.Editor, FilteredTriggers: this.filter_active_only_status_trigger() }, $('#form')).done(() => {                
                this.post_bind_configuration();                
               $result.resolve(true);
            });
            return $result;
        }        
        
        post_bind_configuration = (): void => {
            $('#active_only_form_status_trigger').prop('checked', this._showActiveTriggersOnly);
            $(".date").datepicker({ showOn: "both", buttonImage: "/images/icons/cal.gif", buttonImageOnly: true });
            mW.ui.build_select('#select_form_status_trigger', this.Editor.Schema, 'Status', 'Status', 'No Status Found', null);
            this.bind_selects();
            this.bind_meta_values();
            restart = true;
            sorttable.init();
        }        

        events(): void {
            var $formContainer = $('#form');

            $formContainer.on('keyup', '#formTitle', (e) => {
                this.Editor.Data.Form.Title = $(e.target).val();
            });

            $formContainer.on('keyup', '#formDisplayTitle', (e) => {
                this.update_meta(24, $(e.target).val());
            });

            $formContainer.on('keyup', '#formDescription', (e) => {
                this.Editor.Data.Form.FormDescription = $(e.target).val();
            });

            $formContainer.on('change', '#formDataStructure', (e) => {
                this.Editor.Data.Form.DataStructureID = $(e.target).val();
            });

            $formContainer.on('change', '#formActiveDate', (e) => {
                this.Editor.Data.Form.ActiveDate = $(e.target).val();
            });

            $formContainer.on('change', '#formInactiveDate', (e) => {
                this.Editor.Data.Form.InactiveDate = $(e.target).val();
            });

            $formContainer.on('change', '#formStatus', (e) => {
                this.Editor.Data.Form.Status = $(e.target).val();
            });

            $formContainer.on('change', '#formPortalVisible', (e) => {
                var isChecked = $(e.target).is(':checked');
                this.update_meta(17, isChecked);
            });

            // #region form status step editor

            $formContainer.on('click', '.edit_form_status_trigger', (e) => {
                var triggerID = +$(e.target).data('triggerid');
                $('#form_sub_editor_save').data('triggerid', triggerID);
                this.Editor.Form_Sub_Editors.toggle_editor_display(this.get_status_trigger_by_triggerid(triggerID), true, false);
            });                

            $formContainer.on('click', '.delete_form_status_trigger', (e) => {
                var triggerID = +$(e.target).data('triggerid');                
                this.delete_status_trigger(triggerID).done(() => {
                    this.bind();
                });
            });                

            $formContainer.on('click', '#add_form_status_trigger', (e) => {
                if (this.Editor.isNew) {
                    alert('Please save the form before continuing.');
                } else {
                    this.add_status_trigger();
                }                
            });                

            $formContainer.on('change', '#select_form_status_trigger', (e) => {
                if ($(e.target).val() == '-1') {
                    $('#add_form_status_trigger').prop('disabled', true);
                } else {
                    $('#add_form_status_trigger').prop('disabled', false);
                }
            });                

            $formContainer.on('change', '#active_only_form_status_trigger', (e) => {
                this.bind();                
            });

            // endregion 

            $('.formSave').on('click', (e) => {
                if ($(e.target).hasClass('formSave')) {
                    this.save();
                }                
            });

            $('#editCancel').on('click', (e) => {
                if ($(e.target).hasClass('formCancel')) {
                    if (!this.Editor.isNew) {
                        if (this.validate_form()) {
                            GUI.Windows.Popup.closeThisInline('#modal_questionnaireformeditor');
                            $('#modal_questionnaireformeditor').remove();
                        } else {
                            var c = confirm("By closing you will lose all unsaved work.");
                            if (c) {
                                GUI.Windows.Popup.closeThisInline('#modal_questionnaireformeditor');
                                $('#modal_questionnaireformeditor').remove();
                            }
                        }
                    } else {
                        GUI.Windows.Popup.closeThisInline('#modal_questionnaireformeditor');
                        $('#modal_questionnaireformeditor').remove();
                    }                    
                }
            });
        }

        bind_meta_values(): void {

            $('#formDisplayTitle').val(this.get_meta(24).MetaValue);

            if (this.get_meta(17).MetaValue == (true || '1')) {
                $('#formPortalVisible').prop('checked', true);
            } else {
                $('#formPortalVisible').prop('checked', false);
            }

        }

        get_status_trigger_by_triggerid(triggerID: number): Que.Forms.Models.FormStatusTrigger {
            var trigger: Que.Forms.Models.FormStatusTrigger;
            this.Editor.Triggers.forEach((_trigger) => {
                if (_trigger.TriggerID == triggerID) {
                    trigger = _trigger;
                }
            });
            return trigger;
        }

        get_meta(metaTypeID: number): Que.Forms.Models.MetaAttribute {
            var meta: Que.Forms.Models.MetaAttribute = new Que.Forms.Models.MetaAttribute;
            this.Editor.Data.Form.MetaAttributes.forEach((_meta) => { if (_meta.MetaType == metaTypeID) meta = _meta; });
            return meta;
        }

        add_status_trigger(): void {
            var tempTriggerID = mW.helpers.create_temp_identifier_key_by_array(this.Editor.Triggers, 'TriggerID'),
            newTrigger: Que.Forms.Models.FormStatusTrigger = new Que.Forms.Models.FormStatusTrigger({ TriggerID: tempTriggerID, FormID: this.Editor.Data.Form.FormID, StatusTrigger: $('#select_form_status_trigger').val(), StepStatus: 'active', StepIndex: 0, StepType: 1, StepCommand: null, _isSaved: false });
            this.Editor.Triggers.push(newTrigger);            
            this.bind();            
        }

        update_meta(metaTypeID: number, metaValue: any): void {

            // conver metaValue to expected data value per the metaType
            switch (metaTypeID) {
                case 24:
                    metaValue = metaValue.trim();
                    break;
                case 17:
                    metaValue = metaValue == true ? 1 : 0;
                    break;
            }

            if (!isNaN(metaTypeID)) {

                var isNewMeta: boolean = true;                

                this.Editor.Data.Form.MetaAttributes.forEach((meta) => {
                    if (meta.MetaType == metaTypeID) {
                        meta.MetaValue = metaValue;
                        this.Editor.Data.Form._metaHasChanged = true;
                        edit_Questionnaire_Form_Editor.toggle_disable_tabs([0, 1, 2]);
                        isNewMeta = false;
                    }
                });

                if (isNewMeta) {
                    this.Editor.Data.Form.MetaAttributes.push(new Que.Forms.Models.MetaAttribute({ ParentID: this.Editor.Data.Form.FormID, ParentSecondID: null, ParentThirdID: null, ObjectTypeID: 16, Description: null, MetaType: metaTypeID, MetaIndex: null, MetaValue: metaValue, Status: 'Active' }));
                    this.Editor.Data.Form._metaHasChanged = true;                    
                    edit_Questionnaire_Form_Editor.toggle_disable_tabs([0, 1, 2]);
                }                

            }

        }

        delete_status_trigger(triggerID: number): JQueryDeferred<any> {
            var $result = $.Deferred(), trigger = this.get_status_trigger_by_triggerid(triggerID), c = confirm('Are you sure you want to delete this trigger?');            
            if (c) {
                if (trigger.TriggerID != null) {
                    trigger.delete().done(() => {
                        $result.resolve(true);
                    });
                }
                edit_Questionnaire_Form_Editor.notifcation('Deleted Status Trigger.', true);
            } else {
                $result.reject();
            }            
            return $result;
        }

        filter_active_only_status_trigger(): Que.Forms.Models.FormStatusTrigger[] {
            var triggers: Que.Forms.Models.FormStatusTrigger[] = [], filterStatus = 'deleted';            

            if (!$('#active_only_form_status_trigger').is(':visible')) {
                this._showActiveTriggersOnly = true;
            } else {
                this._showActiveTriggersOnly = $('#active_only_form_status_trigger').is(':checked');
            }            

            if (!this._showActiveTriggersOnly) {                
                filterStatus = null;
            }

            this.Editor.Triggers.forEach((trigger) => {
                if (trigger.StepStatus != filterStatus) {
                    triggers.push(trigger);
                }
            });
            return triggers;
        }

        bind_selects(): void {
            if (this.Editor.Data.DataStructures.length > 0) {
                this.Editor.Data.DataStructures.forEach((category) => {
                    $('#formDataStructure').append($('<option>', {
                        value: category.StructureID,
                        text: category.StructureName
                    }));
                });
                if (this.Editor.Data.Form.DataStructureID != null) {
                    $('#formDataStructure').val(this.Editor.Data.Form.DataStructureID.toString()).prop('disabled', true);

                }                
            }            
        }

        validate_form(): boolean {
            var isValid: boolean = true;            

            markError(this.Editor.Data.Form.Title, $('#formTitle'));
            markError(this.Editor.Data.Form.DataStructureID, $('#formDataStructure'));
            markError(this.Editor.Data.Form.ActiveDate, $('#formActiveDate'));

            function markError(prop: any, $this: JQuery) {
                
                if (prop == null || String(prop).trim() == '' || prop == '-1') {
                    $this.addClass('validation-error');
                    isValid = false;
                } else {
                    $this.removeClass('validation-error');
                }
                
            }

            if (!isValid) {
                edit_Questionnaire_Form_Editor.notifcation('Please update the incomplete fields highlighted in red.', true);
            }

            return isValid;
        }

        reprocess_new_form(): void {            
        // this is the way we should do cascading events in the future
            $.when(this.Editor.Data.get_form())
                .then(() => { this.Editor.Form_View.bind() })
                .then(() => { this.Editor.Section_View.bind() })
                .then(() => { this.Editor.Element_View.bind() })
                .done(() => { this.Editor.Preview_View.bind() });
        }

        save(): JQueryDeferred<any> {
            var $result = $.Deferred(), isNew: boolean = false;
            var formID = this.Editor.Data.Form.FormID == undefined ? null : this.Editor.Data.Form.FormID;            
            if (this.validate_form()) {
                if (formID == (undefined || null)) isNew = true;                                
                edit_Questionnaire_Form_Editor.notifcation('Saving Form...');
                $('.formSave').prop('disabled', true);
                this.Editor.Data.Form.update({
                    FormID: formID,
                    ReportID: null,
                    Title: $('#formTitle').val(),
                    FormDescription: $('#formDescription').val(),
                    Status: $('#formStatus').val(),
                    ActiveDate: $('#formActiveDate').val(),
                    InactiveDate: $('#formInactiveDate').val(),
                    DataStructureID: $('#formDataStructure').val(),
                    DesignTemplateID: this.Editor.Data.Form.DesignTemplateID
                }).save().done((newFormID) => {
                    edit_Questionnaire_Form_Editor.notifcation('Updating Form...');
                    this.Editor.Data.Form.update({ FormID: newFormID });
                    if (this.Editor.isGrid) {
                        this.Editor.Data.Sections[0].update({ FormID: newFormID, Title: $('#formTitle').val() }).save().done((newSectionID) => {
                            this.Editor.Data.Sections[0].update({ SectionID: newSectionID });
                            $result.resolve(newFormID);
                        });
                    } else {
                        $result.resolve(newFormID);
                    }
                });

                $result.always(() => {                    
                    this.Editor.Form_View.bind().done(() => {
                        this.Editor.Section_View.bind().done(() => {
                            this.Editor.Element_View.bind().done(() => {
                                $('.formSave').prop('disabled', false);
                                this.bind_selects();
                                edit_Questionnaire_Form_Editor.toggleTabs(this.Editor.Data);
                                edit_Questionnaire_Form_Editor.notifcation('Form Saved.', true);
                                if (isNew) {
                                    if (this.Editor.isGrid) {
                                        //this.Editor.Element_View.Elements = this.Editor.Data.Sections[0].Elements;
                                        this.reprocess_new_form();
                                        $('#questionniare_editor_tabs').tabs('select', 2);                                        
                                    } else {
                                        this.reprocess_new_form();
                                        $('#questionniare_editor_tabs').tabs('select', 1);
                                    }                                                                    
                                }
                                this.Editor.isNew = false;
                            });
                        });
                    });
                });
                
            }
            return $result;
        }

        handlebar_helpers(): void {

            Handlebars.registerHelper('NumberOfElements', () => {
                var numOfElements = 0;

                this.Editor.Data.Sections.forEach((section) => {
                    numOfElements += section.Elements.length;
                });

                return numOfElements;
            });

            Handlebars.registerHelper('NumberOfMetaAttributes', () => {
                var numOfMeta = 0;
                numOfMeta += this.Editor.Data.Form.MetaAttributes.length;
                this.Editor.Data.Sections.forEach((section) => {
                    numOfMeta += section.MetaAttributes.length;
                    section.Elements.forEach((elements) => {
                        numOfMeta += elements.MetaAttributes.length;
                    });
                });

                return numOfMeta;
            });

            Handlebars.registerHelper('ifGridHide', () => {
                var isHidden;
                // put into class="{{ifGridHide}}"
                if (this.Editor.isGrid) {
                    isHidden = 'displayNone ';
                }
                return isHidden;
            });
        }

    }

}