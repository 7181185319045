namespace Security.Models {
    export class UserSecurity {
        public static HasPermission(permissionName: string): JQueryDeferred<boolean> {
            var $result = $.Deferred();
            //$.ajax({
            //    type: "GET",
            //    url: "/api/Security/ConnectionsUser/HasPermission?PermissionName=" + encodeURIComponent(permissionName),
            //    success: (hasPermission: boolean) => {
            //        df.resolve(hasPermission);
            //    },
            //    error: (jqXHR, textStatus, errorThrown) => {
            //        df.reject();
            //    }
            //});

            $.get('/api/Security/ConnectionsUser/HasPermission?PermissionName=' + encodeURIComponent(permissionName)).done((hasPermission) => {
                $result.resolve(hasPermission);
            }).fail((xhr, status, error) => {
                $result.resolve(xhr, status, error);
            });

            return $result;
        }
    }
}