namespace CRM.APIs {

    export class ClientAddInfo {

        private static _routePrefix: string = '/api/CRM/ClientAddInfo/';

        static saveClientAddInfo(clientAddInfo: Models.ClientAddInfo[]): JQueryDeferred<any> {
            var $result = $.Deferred();
            mW.io.post(this._routePrefix + 'SaveClientAddInfo', clientAddInfo).done(() => {
                $result.resolve(true);
            });
            return $result;
        }

    }

}