module mW {

    export class classes {
        
        // {class: "classes", method: "propagatePropertyValues", version: "1.1.2", status: "up for review"}
        static propagatePropertyValues(sourceObject: any, targetClass: any): void {

            for (var prop in sourceObject) {

                if (prop.indexOf('_') == -1) {

                    if (targetClass.hasOwnProperty(prop)) {
                        
                        if (typeof sourceObject[prop] === 'string' || typeof sourceObject[prop] === 'number') {

                            if (sourceObject[prop] != undefined) {

                                targetClass[prop] = sourceObject[prop];

                            }

                        }
                        
                    }

                }

            }

        }

        // {class: "classes", method: "getProperties", version: "1.1.1", status: "up for review"} 
        static getProperties(object: any): any {
            var newObject = {};
            for (var prop in object) {

                if (typeof object[prop] == 'function') continue;

                newObject[prop] = object[prop];

            }

            return newObject;
        }

        static applyMixins(derivedCtor: any, baseCtors: any[]) {
            baseCtors.forEach(baseCtor => {
                Object.getOwnPropertyNames(baseCtor.prototype).forEach(name => {
                    derivedCtor.prototype[name] = baseCtor.prototype[name];
                });
            });
        }

        static setPropertyValue(currentPropValue: any, value: any, defaultValue: any): any {
            var returnValue = null;
            if (value != null || value != undefined) {
                returnValue = value;
            } else {
                if (currentPropValue != null || currentPropValue != undefined) {
                    returnValue = currentPropValue;
                } else {
                    returnValue = defaultValue;
                }
            }
            return returnValue;
        }

        static set_property_value(object: any, propName: string, value: any, defaultValue: any): any {
            
            if (object[propName] == null || object[propName] == undefined) {                
                object[propName] = null;
                if (value != null || value != undefined) {
                    object[propName] = value;
                } else {
                    object[propName] = defaultValue;
                }                
            } else {

                if (value != null || value != undefined) {

                    object[propName] = value;

                }

            }

        }

    }

}