declare var hljs: any;

namespace Que.Forms.ViewModels {
    // this is really only intended for the forms tab in the form editor
    export class edit_Questionnaire_Form_Sub_Editors {

        Editor: Que.Forms.Models.Questionnaire_Admintool_Base_Model; 

        init(editor: Que.Forms.Models.Questionnaire_Admintool_Base_Model): JQueryDeferred<any> {
            var $result = $.Deferred();
            this.Editor = editor;
            this.bind();
            this.events();
            return $result.resolve(true);
        }

        bind(): void {
            $('#form_mini_editor').html(mW.handlebars.html(CHSITemplates.AdminTools_Questionnaire_Editor_Form_Mini_Editors, this.Editor.Data));
        }

        events(): void {
            $('#form_sub_editor_save').on('click', (e) => {
                var index = $(e.target).data('triggerid'), trigger = this.Editor.Form_View.get_status_trigger_by_triggerid(index);
                this.toggle_editor_display(trigger, false, true);
            });

            $('#form_sub_editor_return').on('click', (e) => {                
                this.toggle_editor_display(null, false, false);
            });

            $(document).on('click', '.cke_button__codesnippet', (e) => {
            // as a convenience to the user, we'll select the sql value from the language drop down in the form editor
                setTimeout(() => {
                    $('.cke_dialog_ui_input_select').val('sql');
                }, 500);
            });

        }

        select_editor(editorName: string): any {

            switch (editorName) {

                case 'statustrigger':
                    break;

            }

        }

        toggle_editor_display(trigger: Que.Forms.Models.FormStatusTrigger, showEditor: boolean, saveData: boolean): void {
            var $editorContainer: JQuery = $('#form_editor_container'), $parentContainer: JQuery = $('#main_questionnaire_editor_container');

            if (showEditor) {
                $parentContainer.fadeOut('fast', () => {
                    $editorContainer.fadeIn('fast', () => {
                        this.toggle_sql_editor(trigger, false);                        
                    });
                });
            } else {
                if (saveData) {
                    this.save_sql_editor_data(trigger);
                    $('#form_sub_editor_save').data('index', null);
                }
                $editorContainer.fadeOut('fast', () => {
                    $parentContainer.fadeIn('fast', () => {
                        this.toggle_sql_editor(trigger, true);                        
                    });
                });
            }            
        }

        toggle_sql_editor = (trigger: Que.Forms.Models.FormStatusTrigger, isDestory: boolean): void => {            

            if (trigger) {
                mW.ui.build_select('#status_step_edit_trigger', this.Editor.Schema, 'Status', 'Status', 'No Status Found', trigger.StatusTrigger);
                mW.ui.build_select('#status_step_edit_status', [{ StatusLower: 'active', Status: 'Active' }, { StatusLower: 'inactive', Status: 'Inactive' }], 'StatusLower', 'Status', 'No Status Found', trigger.StepStatus);
                $('#status_step_edit_index').val(String(trigger.StepIndex));
                $('#status_step_edit_type').val(String(trigger.StepType));

            }            

            var config = {
                extraPlugins: 'codesnippet',
                codeSnippet_theme: 'atelier-forest.light',
                codeSnippet_languages: {
                    sql: 'SQL'
                },
                height: 350,
                toolbar: 'CodeSnippetEditorOnly'
            },
                stepCommand = null;

            if (trigger != null) {
                if (trigger.StepCommand == null) {
                    stepCommand = '';
                } else {
                    stepCommand = trigger.StepCommand;
                }
            }            

            config.toolbar = 'CodeSnippetEditorOnly';
            (<any>config).toolbar_CodeSnippetEditorOnly = [['CodeSnippet']];

            if (isDestory) {
                var editor: CKEDITOR.editor = CKEDITOR.instances['CodeSnippetEditorOnly'];
                if (editor) {
                    editor.destroy(true);
                }                
            } else {
                CKEDITOR.replace('CodeSnippetEditorOnly', config);
                var sql = '<pre><code class="language-sql">' + stepCommand + '</code></pre><p>&nbsp;</p>'
                CKEDITOR.instances['CodeSnippetEditorOnly'].setData(sql);                      
            }            

            
        }

        parse_step_command(stepCommand: string): string {
            var $stepCommand = $(stepCommand);
            return $stepCommand.text().replace(/(\r\n|\n|\r)/gm, "");
        }

        save_sql_editor_data(trigger: Que.Forms.Models.FormStatusTrigger): void {
            var editor: CKEDITOR.editor = CKEDITOR.instances['CodeSnippetEditorOnly'];

            edit_Questionnaire_Form_Editor.notifcation('Saving Status Trigger...');

            trigger.update({
                StatusTrigger: $('#status_step_edit_trigger').val(), StepStatus: $('#status_step_edit_status').val(), StepIndex: $('#status_step_edit_index').val(), StepType: $('#status_step_edit_type').val(), StepCommand: this.parse_step_command(editor.getData())
            }).save().done(() => {
                trigger.update({ _isSaved: true });                
                edit_Questionnaire_Form_Editor.notifcation('Saved Status Trigger.', true);
                this.Editor.Form_View.bind();
            });
        }

        private toggle_status_step_editor(isHidden: boolean, saveChanges?: boolean): void {

            if (isHidden) {
                
            } else {

            }

        }

    }

}