namespace CRM.Models {

    export class ClientUsers {
        ClientUserID: number;
        UserID: string;
        ClientID: number;
        ClientName: string;
        ClientNumber: string;
        ExtUserID: number;
        ClientRole: number;
        CreateTmsp: string | Date;
        CreateUser: string;
        UpdateTmsp: string | Date;
        UpdateUser: string;

        constructor(clients?: CRM.Interfaces.IClientUsers) {
            this.ClientUserID = clients.ClientUserID == undefined ? null : clients.ClientUserID;
            this.UserID = clients.UserID == undefined ? null : clients.UserID;
            this.ClientID = clients.ClientID == undefined ? null : clients.ClientID;
            this.ClientName = clients.ClientName == undefined ? null : clients.ClientName;
            this.ClientNumber = clients.ClientNumber == undefined ? null : clients.ClientNumber;
            this.ExtUserID = clients.ExtUserID == undefined ? null : clients.ExtUserID;
            this.ClientRole = clients.ClientRole == undefined ? null : clients.ClientRole;
            this.CreateTmsp = clients.CreateTmsp == undefined ? null : clients.CreateTmsp;
            this.CreateUser = clients.CreateUser == undefined ? null : clients.CreateUser;
            this.UpdateTmsp = clients.UpdateTmsp == undefined ? null : clients.UpdateTmsp;
            this.UpdateUser = clients.UpdateUser == undefined ? null : clients.UpdateUser;
        }

        getUserClients(): JQueryDeferred<any> {
            var $results = $.Deferred();
            CRM.APIs.ClientUsers.getUserClients(this.ExtUserID).done((clients) => {
                $results.resolve(clients);
            });
            return $results;
        }

        deleteClientUser(): JQueryDeferred<any> {
            var $results = $.Deferred();
            CRM.APIs.ClientUsers.deleteClientUser(this.ClientUserID, this.UserID).done(() => {
                $results.resolve(true);
            });
            return $results;
        }

    }

}