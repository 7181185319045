namespace mW {

    export class ui {

        static build_select(idOrClass: string, arryObj: any[], valueProp: string, textProp: string, notFoundText: string, valueSelected: any, defaultFirstOptionName?: string): void {
            var $this = $(idOrClass), firstOption = '';

            if ((defaultFirstOptionName != null || defaultFirstOptionName != undefined) && defaultFirstOptionName != '') {
                firstOption = defaultFirstOptionName;
            }
            
            $this.empty();
            if (arryObj.length > 0) {
                $this.append($('<option>', { value: '-1', text: firstOption }));
                arryObj.forEach((obj) => {
                    $this.append($('<option>', {
                        value: obj[valueProp],
                        text: obj[textProp]
                    }));
                });
                $this.val(valueSelected);
            } else {
                $this.append($('<option>', { value: '-1', text: notFoundText })).prop('disabled', true);                
            }
        }

    }

}
