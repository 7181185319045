namespace Shared.Models {

    export class PBI_Element {

        AccessToken: string;
        PBIID: string;
        Type: string;
        WorkspaceCollectionName: string;
        WorkspaceID: string;

    }

}