namespace CRM.Models {

    export class ContactTypes {

        ContactTypeID: number;
        ContactTypeName: string;
        Status: string;
        CreateUser: string;
        CreateTmsp: string;
        UpdateUser: string;
        UpdateTmsp: string;

        constructor(contactType?: CRM.Interfaces.IContactTypes) {            
            if (contactType == null) contactType = {};
            this.ContactTypeID = contactType.ContactTypeID;
            this.ContactTypeName = contactType.ContactTypeName;
            this.Status = contactType.Status;
            this.CreateUser = contactType.CreateUser;
            this.CreateTmsp = contactType.CreateTmsp;
            this.UpdateUser = contactType.UpdateUser;
            this.UpdateTmsp = contactType.UpdateTmsp;
        }

        save(): JQueryDeferred<number> {
            var $result = $.Deferred();
            CRM.APIs.ContactTypes.save_contact_type(this).done((id) => {
                $result.resolve(id);
            });
            return $result;
        }        

        delete(): JQueryDeferred<any> {
            var $result = $.Deferred();
            CRM.APIs.ContactTypes.delete_contact_type(this.ContactTypeID).done((done) => {
                $result.resolve(done);
            });
            return $result;
        }

        static get_contact_types(isActive?: boolean): JQueryDeferred<any> {
            var $result = $.Deferred();
            CRM.APIs.ContactTypes.get_contact_type(isActive).done((data) => {
                $result.resolve(data);
            });
            return $result;
        }

        static get_contact_types_flat(isActive?: boolean): JQueryDeferred<any> {
            var $result = $.Deferred();
            CRM.APIs.ContactTypes.get_contact_type_flat(isActive).done((data) => {
                $result.resolve(data);
            });
            return $result;
        }

        static get_in_use_contact_type_count(contactTypeID: number): JQueryDeferred<any> {
            var $result = $.Deferred();
            CRM.APIs.ContactTypes.get_in_use_contact_type_count(contactTypeID).done((count) => {
                $result.resolve(count);
            });
            return $result;
        }
    }

}