namespace Que.APIs {

    export class DataStructureRepo {

        private static _routePrefix: string = '/api/Que/DataStructure/';

        public static getDataStructures(activeOnly: boolean): JQueryDeferred<Que.DataStructures.Models.DataStructureCategory[]> {
            var result = $.Deferred();
            $.get(this._routePrefix + 'GetDataStructures?ActiveOnly=' + activeOnly).done((data) => {
                result.resolve(data);
            });
            return result;
        }
    

    }

}