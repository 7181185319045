namespace Que.Forms.Enums {

    export enum ElementType {
        HTML = 1,
        PLAIN_TEXT = 2, // DON'T USE
        TEXT_BOX = 3,
        RADIO = 4,
        CHECKBOX_LIST = 5,
        UPLOAD_CONTROL = 6,
        SELECT_LIST = 8,
        GRID = 9,
        VERTICAL_RADIO_LIST = 10,
        VERTICAL_CHECKBOX_LIST = 11,
        CHECKBOX = 12
    }

}