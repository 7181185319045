namespace mW {

    export class validate {

        static $validate_custom_methods(): void {

            $.validator.addMethod('notZero', (value, element, params) => {
                if (value != 0) {
                    return true;
                } else {
                    return false;
                }                
            }, 'The value for this field cannot be equal to 0.');

        }

        $validate_custom_class_rules(): void {


        }

    }

}