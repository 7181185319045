namespace Que.Instances.Enums {

    export enum Visibility {

        Visible = 1,
        Hidden = 2,
        Print = 3,
        ScreenOnly = 4

    }

}