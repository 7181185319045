declare var powerbi: any;

namespace Shared.Models {

    export class Widget_Builder {

        page_name: string;
        widgets: mW.interfaces.DicMap<Shared.Models.Abstract_Widget>;
        on_widget_delete_callback: any;
        private _active_widgets: Shared.Models.Abstract_Widget[];

        constructor(widget?: Shared.Interfaces.IWidget_Builder) {
            if (widget == null) widget = {};
            this.page_name = widget.page_name;
            this.widgets = {};
            this.on_widget_delete_callback = null;
            this._active_widgets = [];
        }

        init(): JQueryDeferred<any> {
            var $result = $.Deferred();
            this.get_widgets().done(() => {

                this.bind_active_widgets(true);

                this.events();

                $result.resolve(true);

            });
            return $result;
        }

        show_drop_zones(): void{
            // we need to check if widgets already in zone and calc height
            $('.widget-region').each((i, elem) => {
                var regionID = $(elem).data('regionid');

                var hasWidgets = false;

                for (var w in this.widgets) {
                    var isVisible = this.widgets[w].Settings['Visible'];
                    if (isVisible && +this.widgets[w].Settings['RegionID'] == regionID) {
                        hasWidgets = true;
                        break;
                    }

                }

                if (hasWidgets) {
                    $(elem).show().addClass('region-outline');
                } else {
                    // scroll and show
                    $(elem).show().addClass('region-outline').css('minHeight', 0).animate({ 'min-height': '400px' }, 500, () => {
                        this.enable_drag_and_drop(); // pass in elem l8r?
                    });
                }
            });

            this.enable_edit();
                
        }

        hide_drop_zones(): void {
            // we need to check if widgets in zone and just hide drop zone css l8r
            $('.widget-region').each((i, elem) => {
                var regionID = $(elem).data('regionid');

                var hasWidgets = false;

                for (var w in this.widgets) {
                    var isVisible = this.widgets[w].Settings['Visible'];
                    if (isVisible && +this.widgets[w].Settings['RegionID'] == regionID) {
                        hasWidgets = true;
                        break;
                    }

                }

                if (hasWidgets) {
                    // dont collapse
                    $(elem).removeClass('region-outline');

                } else {
                    $(elem).animate({ 'min-height': '0px' }, 500, () => {
                        $(elem).removeClass('region-outline').hide();
                    });
                }
            });

            $('.widget-overlay-edit').remove();

        }

        bind_active_widgets(isPageInit: boolean) {

            this._active_widgets.forEach((_widget) => {

                var widget = this.widgets[_widget.ID];

                var widgetContainer = this.prepare_widget_container(widget);

                var $region = $('.widget-region[data-regionid="' + widget.Settings['RegionID'] + '"').first();

                $($region).show().append(widgetContainer);

            });

            this.bind_pbi_widgets().done(() => {
                if (isPageInit != true) {
                    this.enable_edit();
                }                

            });

        }

        get_active_widgets(widget: Shared.Models.Abstract_Widget) {
            if (+widget.Settings['Visible'] == 1 && (widget.Settings['RegionID'] != null && +widget.Settings['RegionID'] > 0)) {
                this._active_widgets.push(widget);
            } else {
                widget.update_setting('Visible', 0).update_setting('RegionID', 0);
            }
        }

        get_widgets(): JQueryDeferred<mW.interfaces.DicMap<Shared.Models.Abstract_Widget>> {
            var $result = $.Deferred();
            Shared.APIs.Widget.get_widgets(this.page_name).done((widgets) => {
                widgets.forEach((_widget, index) => {

                    _widget = this.clean_up_widget_settings(_widget);

                    this.widgets[_widget.ID] = _widget.Type == Shared.Enums.Widget_Type.PBI ? new Shared.Models.PBI_Widget(_widget) : new Shared.Models.HTML_Widget(_widget); 

                    this.get_active_widgets(this.widgets[_widget.ID]);

                });
                $result.resolve(widgets);
            });
            return $result;
        }

        clean_up_widget_settings(widget: Shared.Models.Abstract_Widget) {
        
            if (widget.Settings["Visible"] == "" || widget.Settings['Visible'] == null) {
                widget.Settings["Visible"] = '0';
            }

            if (widget.Settings["RegionID"] == "" || widget.Settings['RegionID'] == null) {
                widget.Settings["RegionID"] = '0';
            }

            if (widget.Settings['Visible'] == '1' && widget.Settings['RegionID'] == '0') {
                widget.Settings['Visible'] == '0';
            }

            return widget;
        }

        events() {

            $('.widget-region').on('click', '.widget-remove', (e) => {
                var $widget: JQuery = $(e.target).closest('.widget'), wID = $widget.data('wid');
                $widget.fadeOut('fast', () => {
                    this.on_widget_delete(this.widgets[wID], $widget);
                });
            });

        }

        private enable_drag_and_drop() {
            var _self = this, $sortableWidgetsOnly: JQuery = $('.widget-preview, .widget, .widget-drop-zone'), $sortablePreviewWidget = $('#widget-top-preview-panel');

            $sortablePreviewWidget.sortable({
                connectWith: '.widget-region'
            }).disableSelection();

            $sortableWidgetsOnly.sortable({
                connectWith: '.widget-region'
            }).disableSelection();

            $sortableWidgetsOnly.sortable({
                update: function (e, ui) {
                    // the if (this === ui.item.parent()[0]) prevents the update from firing more than once.
                    if (this === ui.item.parent()[0]) {

                        var $widgetPreview = $(ui.item[0]),
                            $region = $(e.target),
                            wID = $widgetPreview.data('wid'),
                            regionID = $region.data('regionid');

                        $widgetPreview.remove();

                        _self.on_widget_drop(wID, $region);

                        $sortableWidgetsOnly.sortable('refresh');
                                               
                    }
                    
                }
            });

        }

        enable_edit() {

            $('.widget-overlay-edit').remove();
            $('.widget').append('<div class="widget-overlay-edit"><span class="widget-remove">X</span><div>');

        }

        on_widget_drop(wID: number, $region: JQuery): void {

            var widget = this.widgets[wID];

            $region.append(this.prepare_widget_container(widget));

            widget
                .update_setting('Visible', 1)
                .update_setting('RegionID', $region.data('regionid'))
                .save_settings(this.page_name).always(() => {

                    this.bind_pbi_widgets();
                    this.enable_edit();


                });
        }

        on_widget_delete(widget: Shared.Models.Abstract_Widget, $widget: JQuery): void {
            widget
                .update_setting('Visible', 0)
                .update_setting('RegionID', 0)
                .save_settings(this.page_name).done(() => {
                    this.on_widget_delete_callback();
                    this.enable_drag_and_drop();
                    // the actual code to remove the widget
                    $widget.remove();

                });
        }

        bind_pbi_widgets(): JQueryDeferred<any> {

            var $result = $.Deferred(), $pbiWidgets: JQuery = $('.pbi-widget'), processedPBIs = [];

            powerbi.init();

            $pbiWidgets.each((i, e) => {

                var $widget = $(e), wID = $widget.data('wid'), widget = <Shared.Models.PBI_Widget>this.widgets[wID], visualization = widget.Elements[0], isPBIBound = $widget.attr('data-ispbibound') == "true" ? true : false;

                if (!isPBIBound) {

                    var embedConfiguration: Shared.Interfaces.IPowerBIEmbedConfiguration = {
                        type: visualization.Type.toLowerCase(),
                        accessToken: visualization.AccessToken,
                        id: visualization.PBIID,
                        embedUrl: "https://embedded.powerbi.com/appTokenReportEmbed?reportId=" + visualization.PBIID
                    };

                    $widget.attr('data-ispbibound', 'true');

                    var report = powerbi.embed($widget[0], embedConfiguration);

                    report.on('loaded', (event) => {
                        //this.enable_edit();
                        processedPBIs.push(true);
                    });
                        
                }
                    
            });

            var isActive = processedPBIs.every((isProcesssed) => { return isProcesssed == true });

            if (isActive) {
                $result.resolve(isActive);
            }

            return $result;
        }

        private prepare_widget_container(widget: Shared.Models.Abstract_Widget) {

            var bodyContent = '';
            var additionalClasses = '';
            var additionalDataAttr = '';

            if (widget.Type == Shared.Enums.Widget_Type.HTML) {
                bodyContent = (<Shared.Models.HTML_Widget>widget).HTML;
                additionalClasses = 'html-widget';
            }

            if (widget.Type == Shared.Enums.Widget_Type.PBI) {
                additionalClasses = 'pbi-widget';
                additionalDataAttr = 'data-ispbibound="false"';
            }

            return '<div class="widget ' + additionalClasses + '" data-wid="' + widget.ID + '" ' + additionalDataAttr + '>' + bodyContent + '</div>';
        }
	}

	export class Widget_Region {		

		constructor(public region: number, public module_ids: number[]) { }

	}

	// a region module is the inner parts of the region - we might just move this code into the widget_builder class
	export class Widget_Builder_Region_Module {

		regions: Widget_Region[];

		constructor(regions: Widget_Region[]) {
			this.regions = regions;
		}		

		divide_regions(): void {

			this.regions.forEach(region => {

				region.module_ids.forEach(module => {



				});				

			});

		}

		private insert_module_into_region(): void {

		}

	}
}