/// <reference path="../typings/knockout/knockout.d.ts" />

interface KnockoutExtenders {
    numeric: KnockoutExtenders;
}

interface KnockoutBindingHandlers {
    autoTab: KnockoutBindingHandler;
    splitValue: KnockoutBindingHandler;
    combinedInputValues: KnockoutBindingHandler;
    formatDate: KnockoutBindingHandler;
}

namespace mW {    

    export class knockout {
    
        public static ko_custom_extenders(): void {
                    
            (<any>ko.extenders).numeric = function (target, precision) {
                //create a writable computed observable to intercept writes to our observable
                var result = ko.pureComputed({
                    read: target,  //always return the original observables value
                    write: function (newValue: any) {
                        var current = target(),
                            roundingMultiplier = Math.pow(10, precision),
                            newValueAsNum = isNaN(newValue) ? 0 : parseFloat(String(newValue)),
                            valueToWrite = Math.round(newValueAsNum * roundingMultiplier) / roundingMultiplier;

                        //only write if it changed
                        if (valueToWrite !== current) {
                            target(valueToWrite);
                        } else {
                            //if the rounded value is the same, but a different value was written, force a notification for the current field
                            if (newValue !== current) {
                                target.notifySubscribers(valueToWrite);
                            }
                        }
                    }
                }).extend({ notify: 'always' });

                //initialize with current value to make sure it is rounded appropriately
                result(target());

                //return the new computed observable
                return result;
            };

        } 

        // for more info on creating/configuring a custom binder - http://knockoutjs.com/documentation/custom-bindings.html

        public static ko_custom_binders(): void {

            ko.bindingHandlers.autoTab = { 

                update: (element, valueAccessor, allBinding) => {

                    var v = allBinding().value,
                        value = ko.utils.unwrapObservable(v),
                        options = ko.utils.unwrapObservable(valueAccessor() || {}),
                        autoTab = v.autoTab || {};

                    if (value != null) {
                        if (autoTab !== value && $(element).attr('maxLength') && value.length.toString() === $(element).attr('maxLength').toString() && options.next != undefined) {

                            $('#' + options.next).focus().select();

                        }
                        v.autoTab = value;
                    }                                        
                }
            }

            ko.bindingHandlers.splitValue = {

                init: (element, valueAccessor, allBinding) => {
                    var value = ko.utils.unwrapObservable(allBinding().value),
                        options = ko.utils.unwrapObservable(valueAccessor() || {});

                    if (value != null) {
                        $(element).val(value.substring(options.rangeStart, options.rangeEnd));
                    }
                }
                //update: (element, valueAccessor, allBinding) => {
                //    var value = ko.utils.unwrapObservable(allBinding().value),
                //        options = ko.utils.unwrapObservable(valueAccessor() || {});

                //    if (value != null) {
                //        $(element).val(value.substring(options.rangeStart, options.rangeEnd));
                //    }
                //}

            }

            ko.bindingHandlers.combinedInputValues = {

                update: (element, valueAccessor, allBinding) => {
                    var value = valueAccessor(),
                        options = ko.utils.unwrapObservable(valueAccessor() || {}),
                        concatValue = '';

                    for (var i = 0; i < options.ids.length; i++) {
                        var idValue = $('#' + options.ids[i]).val();
                        if (idValue != undefined && idValue != null) {
                            concatValue += idValue;
                        }
                    }

                    var returnval = allBinding().value;
                    returnval.combindInputValue = concatValue;


                    //ko.bindingHandlers.value.update(element, valueAccessor);
                }

            }

            ko.bindingHandlers.formatDate = {
                init: function (element, valueAccessor, allBindingsAccessor, viewModel) {                    
                    ko.utils.registerEventHandler(element, "change", function () {
                        var value = valueAccessor(), actualDate = value();
                        if (actualDate != null && actualDate != '0001-01-01T00:00:00' && actualDate != 'Invalid date' && actualDate != '') {
                            if ($(element).is('input')) {
                                $(element).val(moment(actualDate).format('MM/DD/YYYY'));
                            } else {
                                $(element).html(moment(actualDate).format('MM/DD/YYYY'));
                            }                            
                        } else {
                            if ($(element).is('input')) {
                                $(element).val(null);
                            } else {
                                $(element).html(null);
                            }
                        }
                    });
                },                
                update: function (element, valueAccessor, allBindingsAccessor, viewModel) {
                    var value = valueAccessor(), actualDate = value();
                    if (actualDate != null && actualDate != '0001-01-01T00:00:00' && actualDate != 'Invalid date' && actualDate != '') {
                        if ($(element).is('input')) {
                            $(element).val(moment(actualDate).format('MM/DD/YYYY'));
                        } else {
                            $(element).html(moment(actualDate).format('MM/DD/YYYY'));
                        }
                    } else {
                        if ($(element).is('input')) {
                            $(element).val(null);
                        } else {
                            $(element).html(null);
                        }
                    }
                }
            };
        }
    }    
}