namespace CRM.APIs {

    export class Contacts {

        private static _routePrefix = '/api/CRM/Contact/';

        public static get_client_contacts_by_clientid(clientID: number): JQueryDeferred<any> {
            var $result = $.Deferred();
            $.get(this._routePrefix + 'GetClientContactByClientID?clientID=' + clientID).done((data) => { $result.resolve(data); });
            return $result;
        }

    }

}