namespace CRM.Models {

    export class ClientAddType {

        AddTypeID: number;
        GroupID: string;
        DetailName: string;
        DetailValue: string;
        isAnswered: boolean;
        ShortName: string;
        SortOrder: number;
        Required: string;
        CheckConstraints: string;
        SIGEditPermission: string;
        RoleID: number;
        Visible: string;
        FieldType: string;
        CurrentUser: string;
        Website: string;
        LocationID: number;

        constructor(clientAddType?: CRM.Interfaces.IClientAddType) {
            this.AddTypeID = clientAddType == undefined ? null : clientAddType.AddTypeID;
            this.GroupID = clientAddType == undefined ? null : clientAddType.GroupID;
            this.DetailName = clientAddType == undefined ? null : clientAddType.DetailName;
            this.DetailValue = clientAddType == undefined ? null : clientAddType.DetailValue;
            this.isAnswered = clientAddType == undefined ? null : clientAddType.isAnswered;
            this.ShortName = clientAddType == undefined ? null : clientAddType.ShortName;
            this.SortOrder = clientAddType == undefined ? null : clientAddType.SortOrder;
            this.Required = clientAddType == undefined ? null : clientAddType.Required;
            this.CheckConstraints = clientAddType == undefined ? null : clientAddType.CheckConstraints;
            this.SIGEditPermission = clientAddType == undefined ? null : clientAddType.SIGEditPermission;
            this.RoleID = clientAddType == undefined ? null : clientAddType.RoleID;
            this.Visible = clientAddType == undefined ? null : clientAddType.Visible;
            this.FieldType = clientAddType == undefined ? null : clientAddType.FieldType;
            this.CurrentUser = clientAddType == undefined ? null : clientAddType.CurrentUser;
            this.Website = clientAddType == undefined ? null : clientAddType.Website;
        }

        getLocationAddTypes(locationID: number, scheduleID?: number): JQueryDeferred<GUI.Smart.SmartObject> {
            var $result = $.Deferred();
            CRM.APIs.ClientAddType.getLocationAddTypes(locationID, this.GroupID, scheduleID).done((addTypes) => {
                $result.resolve(addTypes);
            });
            return $result;
        }

        getClientAddTypes(clientID: number, isActive: boolean): JQueryDeferred<GUI.Smart.SmartObject> {
            var $result = $.Deferred();
            CRM.APIs.ClientAddType.getClientAddTypes(clientID, isActive).done((addTypes) => {
                $result.resolve(addTypes);
            });
            return $result;
        }

        save(): void {

        }

        static saveAll(clientAddTypes: ClientAddType[]): JQueryDeferred<any> {
            var $result = $.Deferred();
            CRM.APIs.ClientAddType.saveClientAddTypes(clientAddTypes).done((data) =>{
                $result.resolve(data);
            });
            return $result;
        }

    }

}