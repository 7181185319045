namespace Que.Forms.Models {

    export class ValueList implements Interfaces.IValueList {

        ValueListID: number;
        ListName: string;
        ListSourceType: number;
        ListSourceValues: string;
        Status: string;
        ListOptions: Que.Forms.Models.ValueListOptions[];
        CreateTmsp: string | Date;
        CreateUser: string;
        UpdateTmsp: string | Date;
        UpdateUser: string;

        constructor(valueList?: Interfaces.IValueList) {
            this.ValueListID = valueList == undefined ? null : valueList.ValueListID;
            this.ListName = valueList == undefined ? null : valueList.ListName;
            this.ListSourceType = valueList == undefined ? null : valueList.ListSourceType;
            this.ListSourceValues = valueList == undefined ? null : valueList.ListSourceValues;
            this.Status = valueList == undefined ? null : valueList.Status;
            this.ListOptions = new Array<Que.Forms.Models.ValueListOptions>();
            this.CreateTmsp = valueList == undefined ? null : valueList.CreateTmsp;
            this.CreateUser = valueList == undefined ? null : valueList.CreateUser;
            this.UpdateTmsp = valueList == undefined ? null : valueList.UpdateTmsp;
            this.UpdateUser = valueList == undefined ? null : valueList.UpdateUser;
        }

    }

}