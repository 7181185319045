namespace Shared.Models {

    export class States {

        ShorName: string;
        LongName: string;

        getStates(): JQueryDeferred<States[]> {
            var $result = $.Deferred();
            Shared.APIs.States.getStates().done((states) => {
                $result.resolve(states);
            });
            return $result;
        }

    }

}