namespace IO.APIs {

    export class EmailRepo {

        private static _routePrefix = '/api/Common/Email/';

        public static getEmailTemplates(id?: number): JQueryDeferred<any> {
            var $result = $.Deferred();
            $.get(this._routePrefix + 'GetEmailTemplates?emailTemplateID=' + id).done((data) => {
                $result.resolve(data);
            });
            return $result;
        }

    }

}