namespace CRM.Models {

    export class Contacts {

        CCID: number;
        ClientID: number;
        ContactID: number;
        Status: string;
        ContactType: string;
        CreateUser: string;
        CreateTmsp: string | Date;
        UpdateUser: string;
        UpdateTmsp: string | Date;

        constructor(clientContacts?: CRM.Interfaces.IClientContacts) {
            if (clientContacts == null) clientContacts = {};
            this.CCID = clientContacts.CCID;
            this.ClientID = clientContacts.ClientID;
            this.ContactID = clientContacts.ContactID;
            this.Status = clientContacts.Status;
            this.ContactType = clientContacts.ContactType;
            this.CreateUser = clientContacts.CreateUser;
            this.CreateTmsp = clientContacts.CreateTmsp;
            this.UpdateUser = clientContacts.UpdateUser;
            this.UpdateTmsp = clientContacts.UpdateTmsp;
        }

        public static get(clientID: number): JQueryDeferred<any> {
            var $result = $.Deferred();
            CRM.APIs.ClientContacts.get_client_contacts_by_clientid(clientID).done((clientContacts) => { $result.resolve(clientContacts); });
            return $result;
        }

    }

}