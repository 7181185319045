namespace Que.Forms.ViewModels {

    export class edit_Questionnaire_Form_Sections {    

        Editor: Que.Forms.Models.Questionnaire_Admintool_Base_Model;
        isGrid: boolean;

        init(editor: Que.Forms.Models.Questionnaire_Admintool_Base_Model): JQueryDeferred<any> {
            var $result = $.Deferred();
            //this.WYSIWYG = wsyiwyg;
            this.Editor = editor;
            this.isGrid = this.Editor.Data.Form.DesignTemplateID == 2 ? true : false;
            //this.ElementController = elementController;
            this.handlebars_helpers();
            this.bind().done(() => {
                this.events();
                $result.resolve(true);
            });
            return $result;
        }        

        bind = (): JQueryDeferred<any> => {
            var $result = $.Deferred(), sections = this.Editor.Data == (undefined || null) ? [] : this.Editor.Data.Sections;
            mW.handlebars.bind(CHSITemplates.AdminTools_Questionnaire_Editor_Sections, { Sections: sections }, $('#sections')).done(() => {  
                this.post_bind_processes();                
                $result.resolve(true);
            });

            return $result;
        }

        events(): void {
            var $parentButtons = $('#buttonContainer'), $sectionContainer = $('#sections');
            
            $parentButtons.on('click', '.sectionAdd', () => {
                this.add();
            });

            $parentButtons.on('click', '.sectionSave', () => {
                this.save();
            });

            $sectionContainer.on('focus', '.sectionName', (e) => {
                if ($(e.target).val() == 'Enter Section Name') {
                    $(e.target).val('');
                }                
            });

            $sectionContainer.on('keyup', '.sectionName', (e) => {
                var sectionID = $(e.target).closest('.section').data('sectionid'), value = $(e.target).val();
                this.update(sectionID, 'Title', value);
            });

            $sectionContainer.on('change', '.sectionStatus', (e) => {
                var sectionID = $(e.target).closest('.section').data('sectionid'), value = $(e.target).val();
                this.update(sectionID, 'Status', value);
            });

            $sectionContainer.on('change', '.sectionRequired', (e) => {
                var sectionID = $(e.target).closest('.section').data('sectionid'), isRequired: any = $(e.target).is(':checked');
                isRequired = isRequired == true ? '1' : '0';                   
                this.update_meta(16, isRequired, null, sectionID);
            });

            $sectionContainer.on('change', '.sectionVisibility', (e) => {
                var sectionID = $(e.target).closest('.section').data('sectionid'), value = $(e.target).val();
                this.update(sectionID, 'DefaultVisibility', value);
            });            

            $sectionContainer.on('click', '.gotoSection', (e) => {
                var sectionID = $(e.target).closest('.section').data('sectionid');
                this.go_to_elements(sectionID);
            });

            $sectionContainer.on('click', '.sectionUndo', (e) => {
                var sectionID = $(e.target).closest('.section').data('sectionid');
                this.undo(sectionID);
            });

            $sectionContainer.on('click', '.sectionDelete', (e) => {
                var sectionID = $(e.target).closest('.section').data('sectionid'), $parent = $(e.target).closest('.section');
                this.delete(sectionID, $parent);
            });

            $sectionContainer.on('sortstart', '.sortableQuestionnaire tbody', (event, ui) => {
                ui.item.css('box-shadow', '0 0 10px #000000');
                ui.item.css('background-color', 'white');
            });

            $sectionContainer.on('sortstop', '.sortableQuestionnaire tbody', (event, ui) => {
                ui.item.css('box-shadow', '');
                this.sort_order();                
            });

            $('#editCancel').on('click', (e) => {                
                if ($(e.target).hasClass('sectionCancel')) {
                    if (this.validate_sections() && !this.unsaved_changes()) {
                        GUI.Windows.Popup.closeThisInline('#modal_questionnaireformeditor');
                        $('#modal_questionnaireformeditor').remove();
                    } else {
                        var c = confirm("By closing you will lose all unsaved work.");
                        if (c) {
                            GUI.Windows.Popup.closeThisInline('#modal_questionnaireformeditor');
                            $('#modal_questionnaireformeditor').remove();
                        }
                    }
                }                
            });
        }        

        post_bind_processes(): void {
            this.bind_selects();            
            restart = true;
            sorttable.init();
            edit_Questionnaire_Form_Editor.bindSortable();
        }

        get_section(sectionID?: number): Models.Section {
            var section: Models.Section = null;

            if (!sectionID) {
                sectionID = $('#sectionsFilter').val();
            }

            for (var i = 0; i < this.Editor.Data.Sections.length; i++) {
                if (this.Editor.Data.Sections[i].SectionID == sectionID) {
                    section = this.Editor.Data.Sections[i];
                    break;
                }
            }
            return section;
        }

        $get_section(sectionID: number): JQuery {
            var $section: JQuery = null;
            $('.section').each((i, e) => {
                if (+$(e).data('sectionid') == sectionID) {
                    $section = $(e);
                    return false;
                }
            });
            return $section;
        }

        add(): void {            
            var sortOrder = this.Editor.Data.Sections.length == 0 ? 1 : (this.Editor.Data.Sections[this.Editor.Data.Sections.length - 1].SortOrder + 1);

            var section: Models.Section = new Models.Section({
                FormID: this.Editor.Data.Form.FormID,                
                SectionID: mW.helpers.create_temp_identifier_key_by_array(this.Editor.Data.Sections, 'SectionID'),
                Status: 'Active',
                Title: 'Enter Section Name',
                MetaAttributes: [new Models.MetaAttribute({ MetaType: 16, MetaValue: '0' })],
                SortOrder: sortOrder,
                DefaultVisibility: 1                
            });
                       
            this.Editor.Data.Sections.push(section);
            this.bind().done(() => {
                this.Editor.Data.Sections.forEach((sec) => {
                    this.toggle_section_change_notifications(sec);
                });                
            });

            edit_Questionnaire_Form_Editor.toggle_disable_tabs([0, 2]);
            edit_Questionnaire_Form_Editor.notifcation('New Section Added.', true);
        }

        undo(sectionID: number): void {            
            var section = this.get_section(sectionID);
            
            for (var cloneProp in section._clone) {
                if (cloneProp != '_clone' && cloneProp != '_sortHasChanged' && cloneProp != 'SortOrder') {
                    if (cloneProp == 'MetaAttributes') {
                        section.MetaAttributes = new Array<Que.Forms.Models.MetaAttribute>();
                        section._clone.MetaAttributes.forEach((meta) => {
                            section.MetaAttributes.push($.extend({}, meta));
                        });
                    } else {
                        section[cloneProp] = section._clone[cloneProp];
                    }                    
                }                
            }

            this.bind().done(() => {
                this.Editor.Data.Sections.forEach((sec) => {
                    this.toggle_section_change_notifications(sec);
                });
            });

            var sections = this.Editor.Data.Sections;

            if (sections.some(sec => sec._propertyHasChanged == true) || sections.some(sec => sec._sortHasChanged == true) || sections.some(sec => sec._metaHasChanged == true)) {
                edit_Questionnaire_Form_Editor.toggle_disable_tabs([0, 2]);
            } else {
                edit_Questionnaire_Form_Editor.toggle_disable_tabs([]);
            }
            edit_Questionnaire_Form_Editor.notifcation('Section changes have been reverted.', true);
        }

        bind_selects(): void {
            
            mW.ui.build_select('.sectionVisibility', mW.enums.enums_helpers.convert_enum_to_array(Instances.Enums.Visibility), 'EnumValue', 'EnumName', 'No Data Found', null);

            this.Editor.Data.Sections.forEach((sec) => {
                this.$get_section(sec.SectionID).find('.sectionVisibility').val(String(sec.DefaultVisibility));
            });

        }

        clone_section(section: Que.Forms.Models.Section): void {
            if (section._clone == null) {
                section._clone = $.extend({}, section);
                section._clone.MetaAttributes = new Array<Que.Forms.Models.MetaAttribute>();
                section.MetaAttributes.forEach((meta) => {
                    section._clone.MetaAttributes.push($.extend({}, meta));
                });
            }
        }

        delete(sectionID: number, $this: JQuery): void {

            var c = confirm('Are you sure you want to delete this Section?');
            if (c) {
                edit_Questionnaire_Form_Editor.notifcation('Deleting Element...');
                $('.sectionDelete').prop('disabled', true);
                this.Editor.Data.deleteSection(sectionID).done(() => {
                    edit_Questionnaire_Form_Editor.notifcation('Updating Sections...');

                    this.bind().done(() => {
                        this.Editor.Element_View.update_section_filter();
                        $('.sectionDelete').prop('disabled', false);
                    });

                    var sections = this.Editor.Data.Sections;
                    if (sections.some(sec => sec._propertyHasChanged == true) || sections.some(sec => sec._metaHasChanged== true) || sections.some(sec => sec._sortHasChanged == true)) {
                        edit_Questionnaire_Form_Editor.toggle_disable_tabs([0, 2]);
                    } else {
                        edit_Questionnaire_Form_Editor.toggle_disable_tabs([]);
                    }

                    this.Editor.Data.Sections.forEach((sec) => {
                        this.toggle_section_change_notifications(sec);
                    });

                    edit_Questionnaire_Form_Editor.notifcation('Sections Updated.', true);    
                   
                });
            } else {
                edit_Questionnaire_Form_Editor.notifcation('Section Deletion Cancelled.', true);
            }

        }

        update(sectionID: number, propName: string, value: any): void {
            var section = this.get_section(sectionID), $section = this.$get_section(sectionID);
            this.clone_section(section);
            section[propName] = value;
            section._propertyHasChanged = true;
            this.toggle_section_change_notifications(section);
            $section.find('.sectionUndo').prop('disabled', false);
            $section.find('.gotoSection').prop('disabled', true);
            edit_Questionnaire_Form_Editor.toggle_disable_tabs([0, 2]);
            edit_Questionnaire_Form_Editor.notifcation('Section configuration updated.', true);
        }

        save(): JQueryDeferred<any> {
            if (this.validate_sections()) {
                var $result = $.Deferred();
                $('.sectionSave').prop('disabled', true);
                edit_Questionnaire_Form_Editor.notifcation('Saving Sections...');
                this.Editor.Data.saveSections().done((ids) => {
                    edit_Questionnaire_Form_Editor.notifcation('Updating Sections...');
                    this.update_section_with_sectionids(ids);
                    this.bind().done(() => {                                                                                                 
                        if (this.isGrid) {
                            $('.sectionDelete').hide();
                        }
                        this.Editor.Element_View.update_section_filter();
                        $('.sectionSave').prop('disabled', false);
                        $('.gotoSection').prop('disabled', false);
                        $('.sectionUndo').prop('disabled', true);
                        edit_Questionnaire_Form_Editor.toggleTabs(this.Editor.Data);
                        edit_Questionnaire_Form_Editor.toggle_disable_tabs([]);
                        edit_Questionnaire_Form_Editor.notifcation('Sections Updated.', true);
                        $result.resolve(true);
                    });
                });
                return $result;
            } else {
                $('.sectionSave').prop('disabled', false);
            }
        }

        validate_sections(): boolean {
            var isValid: boolean = true, $section = $('.section');

            $section.each((i, e) => {
                let sectionID = $(e).data('sectionid');
                markError(this.get_section(sectionID).Title, $(e).find('.sectionname'));
            });

            function markError(prop: any, $this: JQuery) {
                if (prop == null || String(prop).trim() == '' || String(prop).trim() == 'Enter Section Name') {
                    $this.addClass('validation-error');
                    isValid = false;
                } else {
                    $this.removeClass('validation-error');
                }
            }

            if (!isValid) {
                edit_Questionnaire_Form_Editor.notifcation('Please update the incomplete fields highlighted in red.', true);
            }

            return isValid;
        }       

        unsaved_changes(): boolean {
            var isUnsaved = false;
            isUnsaved = this.Editor.Data.Sections.some(sec => sec._metaHasChanged == true || sec._propertyHasChanged == true || sec._sortHasChanged == true);
            return isUnsaved;
        }

        update_section_with_sectionids(idList: any): void {
            for (var id in idList) {
                var sectionIDBeforeSave: number = +id, sectionIDAfterSave: number = +idList[id], section = this.get_section(sectionIDBeforeSave);
                section.SectionID = sectionIDAfterSave;
                section._clone.SectionID = sectionIDAfterSave;

            }
        }        

        go_to_elements(sectionID: number): void {

            var numOfDisalbedTabs = $('#questionniare_editor_tabs').find('.ui-state-disabled').length;

            if (numOfDisalbedTabs > 0) {                

                edit_Questionnaire_Form_Editor.notifcation('Save or undo Section changes before going to Elements.', true);

            } else {

                var elements = this.get_section(sectionID).Elements;
                if (elements != null) {
                    $('#sectionsFilter').val(sectionID.toString());
                    this.Editor.Element_View.Elements = elements;
                    this.Editor.Element_View.bind().done(() => {
                        // display any marker to show any unsaved changes
                        this.Editor.Element_View.Elements.forEach((ele) => { this.Editor.Element_View.toggle_element_change_notifications(ele); });
                        $('#questionniare_editor_tabs').tabs('select', 2);
                    });
                } else {
                    edit_Questionnaire_Form_Editor.notifcation('No Elements found.', true);
                }                

            }

        }

        sort_order(): void {
            $('.section').each((i, e) => {

                var sectionID = $(e).data('sectionid'), section = this.get_section(sectionID);

                this.clone_section(section);

                if (section.SortOrder != i) {
                    section.SortOrder = i;
                    section._sortHasChanged = true;
                    this.toggle_section_change_notifications(section);
                }                

                

                this.Editor.Data.Sections.sort((a, b) => {

                    if (a.SortOrder < b.SortOrder) {
                        return -1;
                    }
                    if (a.SortOrder > b.SortOrder) {
                        return 1;
                    }
                    return 0;

                });

            });
                        
            var hasSortOrderChanged = this.Editor.Data.Sections.some(sec => sec._sortHasChanged == true);

            if (hasSortOrderChanged) {
                edit_Questionnaire_Form_Editor.toggle_disable_tabs([0, 2]);                
            }
                        
            edit_Questionnaire_Form_Editor.notifcation('Sections Sort Order Updated.', true);

            restart = true;
            sorttable.init();
        }

        toggle_section_change_notifications = (section: Que.Forms.Models.Section): void => {

            var $section = this.$get_section(section.SectionID), $undo = $section.find('.sectionUndo'), $gotoElements = $section.find('.gotoSection'), $exclamationIcon = $section.find('.has_changed_exclamation_container'), titleTip = '', numOfChanges = 0, isNewSection = (section.SectionID < 0), isElementOrderChanged = section.Elements.some(element => element._sortHasChanged == true);

            [section._propertyHasChanged, section._metaHasChanged, section._sortHasChanged, isNewSection, isElementOrderChanged].forEach((changedValue) => { if (changedValue) { numOfChanges++; } });

            
                titleTip = titleTip + '- section has not been saved. (must be saved to edit elements) \n';
                       

            if (isElementOrderChanged) {
                titleTip = titleTip + '- elements in this section have had their order changed but not saved.';
                if (numOfChanges > 1) {
                    titleTip = titleTip + '\n';
                }
            }

            if (isNewSection) {
                titleTip = titleTip + '- section was recently added.';
                if (numOfChanges > 1) {
                    titleTip = titleTip + '\n';
                }
            }

            if (section._propertyHasChanged) {
                titleTip = titleTip + '- section has had one or more property values changed.';
                if (numOfChanges > 1) {
                    titleTip = titleTip + '\n';
                }
            }

            if (section._metaHasChanged) {
                titleTip = titleTip + '- section has had one or more meta attibute values changed.';
                if (numOfChanges > 1) {
                    titleTip = titleTip + '\n';
                }
            }

            if (section._sortHasChanged) {
                titleTip = titleTip + '- section has had it\'s sort order changed. (cannot be undone)';
            }

            if (numOfChanges > 0) {
                if (numOfChanges == 1 && (section._sortHasChanged == true || isNewSection)) {
                // we still want to keep the undo button disabled ONLY if the sort order has changed. We don't support undoing sort order atm.
                    $undo.prop('disabled', true);
                } else {
                    $undo.prop('disabled', false);
                }                
                $exclamationIcon.show();
                $exclamationIcon.find('.ui-icon-alert').attr('title', titleTip);
            } else {
                $undo.prop('disabled', true);                
                $exclamationIcon.hide();
                $exclamationIcon.find('.ui-icon-alert').attr('title', titleTip);
            }

            // specific logic to if the element sort order has changed
            if (numOfChanges > 0) {
                $gotoElements.prop('disabled', true);
                if (numOfChanges == 1) {
                    if (isElementOrderChanged) {
                        $gotoElements.prop('disabled', false);    
                        $undo.prop('disabled', true);
                    }
                } else {

                }
            } else {
                $gotoElements.prop('disabled', false);
            }

        }

        update_meta(metaTypeID: number, metaValue: any, objectTypeID: number, sectionID: number): void {

            if (!isNaN(metaTypeID)) {

                var section = this.get_section(sectionID), isNewMeta: boolean = true, $section = this.$get_section(sectionID);

                this.clone_section(section);

                for (var i = 0; i < section.MetaAttributes.length; i++) {

                    if (section.MetaAttributes[i].MetaType == metaTypeID) {

                        section.MetaAttributes[i].MetaValue = metaValue;

                        section._metaHasChanged = true;                        

                        $section.find('.sectionUndo').prop('disabled', false);
                        $section.find('.gotoSection').prop('disabled', true);

                        edit_Questionnaire_Form_Editor.toggle_disable_tabs([0, 2]);

                        isNewMeta = false;

                        break;

                    }

                }

                if (isNewMeta) {
                    section.MetaAttributes.push(new Que.Forms.Models.MetaAttribute({ ParentID: sectionID, ParentSecondID: null, ParentThirdID: null, ObjectTypeID: objectTypeID, Description: null, MetaType: metaTypeID, MetaIndex: null, MetaValue: metaValue, Status: 'Active' }));
                    section._metaHasChanged = true;
                    edit_Questionnaire_Form_Editor.toggle_disable_tabs([0, 2]);
                }                

                this.toggle_section_change_notifications(section);

            }

        }

        get_meta(section: Que.Forms.Models.Section, metaType: number): Que.Forms.Models.MetaAttribute {
            var metaAttribute: Que.Forms.Models.MetaAttribute;

            section.MetaAttributes.forEach((metaO) => {
                if (metaO.MetaType == metaType) {
                    metaAttribute = metaO;
                }
            });

            return metaAttribute;
        }

        handlebars_helpers(): void {

            Handlebars.registerHelper('HideIfGrid', () => {
                var statusClass;
                if (this.Editor.Data.Form.DesignTemplateID == 2) {
                    statusClass = 'displayNone';
                } else {
                    statusClass = '';
                }
                return statusClass;
            });

            Handlebars.registerHelper('SetSectionStatus', function (status) {
                var statusClass;
                if (status == 'active') {
                    statusClass = 'statusActiveSection';
                } else {
                    statusClass = 'statusInactiveSection';
                }
                return statusClass;
            });

            Handlebars.registerHelper('IfMetaValueEqual', function (metaAttributes: Que.Forms.Models.MetaAttribute[], metaTypeID, MetaTypeValueCompare, options) {
                
                for (var i = 0; i < metaAttributes.length; i++) {
                    if (metaAttributes[i].MetaType == metaTypeID) {
                        if (metaAttributes[i].MetaValue == MetaTypeValueCompare) {
                            return options.fn(this);                     
                        } else {
                            return options.inverse(this);

                        }                
                    }                    

                }                

            });

            Handlebars.registerHelper('IfBoolTrue', function (value: any, options) {
                if(value == '1' || value == true) {
                    return options.fn(this);
                } else {
                    return options.inverse(this);
                }  
            });

            Handlebars.registerHelper('ifHasChanged', function (trueValue, falseValue, options) {
                var value = null, section: Que.Forms.Models.Section = this, elementHasChanged = false;

                elementHasChanged = section.Elements.some(element => element._sortHasChanged == true);
                
                if (section._propertyHasChanged == true || section._metaHasChanged == true || elementHasChanged == true) {
                    value = trueValue;
                } else {
                    value = falseValue;
                }
                return value;
            });

            Handlebars.registerHelper('isValid', function (propertyName, options) {
                var isValid;
                if (this[propertyName] == '' || this[propertyName] == null || this[propertyName] == undefined) {
                    isValid = 'validation-error';
                } else {
                    isValid = '';
                }
                return isValid;
            });

            Handlebars.registerHelper('ifHasChangedDisable', function (options) {
                var show = null, section: Que.Forms.Models.Section = this;
                if (section._propertyHasChanged == true) {
                    show = '';
                } else {
                    show = 'disabled';
                }
                return show;
            });

            Handlebars.registerHelper('GetSectionMetaValue', function (metaType: number, equalTo: string, options) {
                var metaAttributes: Models.MetaAttribute[] = this.MetaAttributes;                

                for (var i = 0; i < metaAttributes.length; i++) {

                    if (metaAttributes[i].MetaType == metaType) {

                        if (metaAttributes[i].MetaValue == equalTo) {
                            return options.fn(this);
                        } else {
                            return options.inverse(this);
                        }                

                    }

                }

            });
        }

    }

}