namespace Que.DataStructures.Models {

    export class DataStructureCategory implements Interfaces.IDataStructureCategory {

        CategoryID: number;
        DataStructureID: number;
        CategoryTitle: string;
        Status: string;
        StructureType: any;
        SortOrder: number;
        Columns: Que.DataStructures.Models.DataStructureColumn[];
        CreateUser: string;
        CreateTmsp: string | Date;
        UpdateUser: string;
        UpdateTmsp: string | Date;

        constructor(dataStructureCategory?: Interfaces.IDataStructureCategory) {
            this.CategoryID = dataStructureCategory == undefined ? null : dataStructureCategory.CategoryID;
            this.DataStructureID = dataStructureCategory == undefined ? null : dataStructureCategory.DataStructureID;
            this.CategoryTitle = dataStructureCategory == undefined ? null : dataStructureCategory.CategoryTitle;
            this.Status = dataStructureCategory == undefined ? null : dataStructureCategory.Status;
            this.StructureType = dataStructureCategory == undefined ? null : dataStructureCategory.StructureType;
            this.SortOrder = dataStructureCategory == undefined ? null : dataStructureCategory.SortOrder;
            this.Columns = new Array<Que.DataStructures.Models.DataStructureColumn>();
            if (dataStructureCategory != undefined && dataStructureCategory.Columns != undefined) {
                dataStructureCategory.Columns.forEach((colO) => {
                    this.Columns.push(new Que.DataStructures.Models.DataStructureColumn(colO));
                });
            }
            this.CreateUser = dataStructureCategory == undefined ? null : dataStructureCategory.CreateUser;
            this.CreateTmsp = dataStructureCategory == undefined ? null : dataStructureCategory.CreateTmsp;
            this.UpdateUser = dataStructureCategory == undefined ? null : dataStructureCategory.UpdateUser;
            this.UpdateTmsp = dataStructureCategory == undefined ? null : dataStructureCategory.UpdateTmsp;
        }

    }

}