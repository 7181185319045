namespace Que.Forms.Models {

    export class ContentType {

        ContentTypeID: number;
        CssClass: string;
        MaxLength: string;

        constructor(contentType?: Interfaces.IContentType) {
            this.ContentTypeID = contentType == undefined ? null : contentType.ContentTypeID;
            this.CssClass = contentType == undefined ? null : contentType.CssClass;
            this.MaxLength = contentType == undefined ? null : contentType.MaxLength;
        }

        get(): JQueryDeferred<Que.Forms.Models.ContentType[]> {
            var $result = $.Deferred(), contacts: Que.Forms.Models.ContentType[] = new Array<Que.Forms.Models.ContentType>();
            Que.Forms.APIs.WYSIWYG_Repo.getContentType().done((data) => {
                data.forEach((contactType) => {
                    contacts.push(new Que.Forms.Models.ContentType(contactType));
                    $result.resolve(contacts);
                });
            });
            return $result;
        }
    }

}