namespace Security.ViewModels {

    export class admin_WebsiteUserEdit {
     
        ExtUserID: number;
        ClientGrid: GUI.Tables.DTables = new GUI.Tables.DTables;        

        init(extUserID: number): void {
            this.ExtUserID = extUserID;
            this.get().done(() => {
                this.bindGrid();
                this.events();
            });
        }

        events(): void {
            $('#clientAccessDataTableGrid').on('click', '.deleteClientAccess', (e) => {
                var r = confirm('Are you sure you wish to remove this Client Association?');
                var clientUserID = $(e.target).data('clientuserid'), userID = $(e.target).data('userid');
                if (r) {
                    this.delete(clientUserID, userID);
                }                
            });
        }

        deleteLink(): void {
            var _self = this;
            if (this.ClientGrid.data) {
                var Column = new GUI.Smart.SmartColumnObject();
                Column.title = '',
                Column.data = null;
                Column.orderable = false;
                Column.class = 'details-control';
                Column.render = function (data, type, row) {
                    return '[<a href="#" class="MiniLink deleteClientAccess" data-clientuserid="' + data[3] + '" data-userid="' + data[4] + '">Delete</a>]';
                }
                this.ClientGrid.data.Columns.push(Column);
            }
        }

        bindGrid(): JQueryDeferred<any> {
            var $result = $.Deferred();
            this.deleteLink();
            this.ClientGrid.columnSearch = false;
            this.ClientGrid.dtObject.showEntryDropDown = false;
            this.ClientGrid.dtObject.displayLength = 16;            
            this.ClientGrid.containerName = 'clientAccessDataTableContainer';
            this.ClientGrid.tableName = 'clientAccessDataTableGrid';            
            this.ClientGrid.buildTable();
            return $result.resolve(true);
        }         

        refresh(): void {            
            this.get().done(() => {
                this.deleteLink();
                this.ClientGrid.refreshTable();                
                //this.events();
            });
        }

        get(): JQueryDeferred<any> {
            var $results = $.Deferred();
            new CRM.Models.ClientUsers({ ExtUserID: this.ExtUserID }).getUserClients().done((clients) => {
                $results.resolve(this.ClientGrid.data = clients);
            });
            return $results;
        }

        delete(clientUserID: number, userID: string): void {
            new CRM.Models.ClientUsers({ ClientUserID: clientUserID, UserID: userID }).deleteClientUser().done(() => {
                console.log('deleted');
                this.refresh();
                console.log('refreshed');
            });
        }

    }

}