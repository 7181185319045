namespace Que.DataStructures.Models {

    export class DataStructures {

        SamplePropertyID: number;
        SchemaType: number;
        StructureID: number;
        StructureType: number;
        StructureTypeName: string;
        StructureName: string;
        Status: string;
        StructureDescription: string;
        Columns: Que.DataStructures.Models.DataStructureColumn[];
        Categories: Que.DataStructures.Models.DataStructureCategory[];
        CreateUser: string;
        CreateTmsp: string | Date;
        UpdateUser: string;
        UpdateTmsp: string | Date;

        constructor(dataStructure?: Interfaces.IDataStructure) {
            this.SamplePropertyID = dataStructure == undefined ? null : dataStructure.SamplePropertyID;
            this.SchemaType = dataStructure == undefined ? null : dataStructure.SchemaType;
            this.StructureID = dataStructure == undefined ? null : dataStructure.StructureID;
            this.StructureType = dataStructure == undefined ? null : dataStructure.StructureType;
            this.StructureName = dataStructure == undefined ? null : dataStructure.StructureName;
            this.StructureTypeName = dataStructure == undefined ? null : dataStructure.StructureTypeName;
            this.Status = dataStructure == undefined ? null : dataStructure.Status;
            this.StructureDescription = dataStructure == undefined ? null : dataStructure.StructureDescription;
            this.Columns = new Array<Que.DataStructures.Models.DataStructureColumn>();
            if (dataStructure != undefined && dataStructure.Columns != undefined) {
                dataStructure.Columns.forEach((colO) => {
                    this.Columns.push(new Que.DataStructures.Models.DataStructureColumn(colO));
                });
            }
            this.Categories = new Array<Que.DataStructures.Models.DataStructureCategory>();
            if (dataStructure != undefined && dataStructure.Categories != undefined) {
                dataStructure.Categories.forEach((catO) => {
                    this.Categories.push(new Que.DataStructures.Models.DataStructureCategory(catO));
                });
            }
            this.CreateUser = dataStructure == undefined ? null : dataStructure.CreateUser;
            this.CreateTmsp = dataStructure == undefined ? null : dataStructure.CreateTmsp;
            this.UpdateUser = dataStructure == undefined ? null : dataStructure.UpdateUser;
            this.UpdateTmsp = dataStructure == undefined ? null : dataStructure.UpdateTmsp;
        }

        getDataStructure(): JQueryDeferred<Que.DataStructures.Models.DataStructures[]> {
            var $result = $.Deferred();
            Que.APIs.DataStructureRepo.getDataStructures(true).done((data) => {
                $result.resolve(mW.io.reconstruct_object(data));
            });
            return $result;
        }

    }

}