namespace CRM.APIs {

    export class ClientContacts {

        private static _routePrefix = '/api/CRM/ClientContacts/';

        public static get_client_contacts_by_clientid(clientID: number): JQueryDeferred<any> {
            var $result = $.Deferred();
            $.get(this._routePrefix + 'GetClientContactsByClientID?clientID=' + clientID).done((data) => { $result.resolve(data); });
            return $result;
        }

    }

}