namespace Que.ResultSets.Models {

    export class Schema {

        SchemaID: number;
        DisplayStatus: string;
        Status: string;
        StatusIndex: number;
        PortalEditable: boolean;
        ConnectionsEditable: boolean;

        constructor(schema?: Que.ResultSets.Interfaces.ISchema) {
            this.update(schema);
        }

        update(schema?: Que.ResultSets.Interfaces.ISchema): Schema {
            if (!schema) schema = {};
            var set_prop = mW.classes.set_property_value;
            set_prop(this, 'SchemaID', schema.SchemaID, null);
            set_prop(this, 'DisplayStatus', schema.DisplayStatus, null);
            set_prop(this, 'Status', schema.Status, null);
            set_prop(this, 'StatusIndex', schema.StatusIndex, null);
            set_prop(this, 'PortalEditable', schema.PortalEditable, null);
            set_prop(this, 'ConnectionsEditable', schema.ConnectionsEditable, null);
            return this;
        }

        get(isActive?: boolean): JQueryDeferred<Schema[]> {
            var $result = $.Deferred();
            Que.ResultSets.APIs.SchemaRepo.getAllSchemaStatuses(this.SchemaID, isActive).done((data) => {
                $result.resolve(data);
            });
            return $result;            
        }

        save(): void {

        }

        delete(): void {

        }

    }

}