namespace Shared.Models {
    
    export abstract class Abstract_Widget {

        ID: number;
        Name: string;
        Description: string;
        Type: Shared.Enums.Widget_Type;
        Settings: mW.interfaces.DicMap<string>;

        constructor(widget?: Shared.Interfaces.IAbstract_Widget) {
            if (widget == null) widget = {};
            this.ID = widget.ID;
            this.Name = widget.Name;
            this.Description = widget.Description;
            this.Type = widget.Type;
            this.Settings = widget.Settings;
        }

        abstract save(): number;

        update_setting(keyName: string, val: any): Abstract_Widget {
            this.Settings[keyName] = val;
            return this;
        }

        save_settings(page_name: string): JQueryDeferred<any> {
            let df = $.Deferred();
            Shared.APIs.Widget.save_settings(page_name, this.ID, this.Settings).done(() => {
                df.resolve();
            });
            return df;
        }
    }
}