namespace Shared.APIs {

    export class Widget {

        private static _routePrefix = '/api/Shared/Widget/';

        static get_widgets(pageName: string): JQueryDeferred<Shared.Models.Abstract_Widget[]> {
            var $result = $.Deferred();
            $.get(this._routePrefix + 'GetPageWidgets?pageName=' + pageName).done((data) => {
                $result.resolve(data);
            });
            return $result;
        }

        static save_settings(pageName: string, wID: number, settings: mW.interfaces.DicMap<string>): JQueryDeferred<any> {
            var df = $.Deferred();
            mW.io.post(this._routePrefix + 'SaveSettings', { Settings: settings, WidgetID: wID, PageName: pageName }).done(() => {
                df.resolve();
            });
            return df;
        }
    }

}